import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { checkRoom } from "../../api/roomApi";

const JoinRoomDialog = ({ open, onClose, onJoin }) => {
  const [roomTag, setRoomTag] = useState("");
  const [roomPassword, setRoomPassword] = useState("");
  const [roomInfo, setRoomInfo] = useState(null);
  const [error, setError] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const resetState = () => {
    setRoomTag("");
    setRoomPassword("");
    setRoomInfo(null);
    setError("");
  };

  const handleCheckRoom = async () => {
    try {
      const response = await checkRoom(roomTag);
      if (response.success) {
        setRoomInfo(response.roomInfo);
        setError("");
      } else {
        setError(response.message);
      }
    } catch (error) {
      setError("Room is not found");
    }
  };

  const handleJoin = async () => {
    try {
      await onJoin(roomTag, roomPassword);
      window.location.reload();
      onClose();
    } catch (error) {
      setError("Failed to join room");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        resetState();
        onClose();
      }}
      maxWidth="md"
      fullWidth
      fullScreen={!isNonMobileScreens}
    >
      <DialogTitle>Join Room</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Room Tag"
          fullWidth
          value={roomTag}
          onChange={(e) => setRoomTag(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">#</InputAdornment>,
          }}
        />
        <Button onClick={handleCheckRoom}>Check Room Availability</Button>
        {roomInfo && (
          <>
            <Typography>
              Room found: {roomInfo.isPublic ? "Public" : "Private"}
            </Typography>
            {!roomInfo.isPublic && (
              <TextField
                margin="dense"
                label="Room Password"
                type="password"
                fullWidth
                value={roomPassword}
                onChange={(e) => setRoomPassword(e.target.value)}
              />
            )}
          </>
        )}
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            resetState();
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button onClick={handleJoin} disabled={!roomInfo}>
          Join
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinRoomDialog;
