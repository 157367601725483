import React from "react";
import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";

const PublicNavbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const marginStyle = isMobile ? "4px 18px 20px 18px" : "10px 190px";

  const isActive = (path) => location.pathname === path;

  const handleFeaturesClick = () => {
    if (location.pathname === "/") {
      const featuresSection = document.getElementById("features");
      if (featuresSection) {
        featuresSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/#features");
    }
  };

  return (
    <Box
      sx={{
        margin: marginStyle,
        display: "flex",
        justifyContent: "center",
        width: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
          width: "100%",
        }}
      >
        <Box
          onClick={() => navigate("/")}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          {isMobile ? (
            <img
              src="/assets/rg-new-icon.webp"
              alt="ReadersGuild's Logo"
              style={{ height: "40px", width: "40px" }}
            />
          ) : (
            <img
              src="/assets/rg-new-logo-dark.webp"
              alt="ReadersGuild's Logo"
              style={{ height: "40px", width: "185.45px" }}
            />
          )}
        </Box>

        <Box sx={{ display: "flex", gap: 1 }}>
          <Box
            sx={{
              display: "flex",
              ml: isMobile ? 0 : 4,
              mr: isMobile ? 1.5 : 3,
            }}
          >
            <Button
              onClick={() => {
                navigate("/blog");
              }}
              sx={{
                color: isActive("/blog")
                  ? theme.palette.primary.main
                  : theme.palette.neutral.main,
                fontWeight: isActive("/blog") ? 600 : 400,
                px: isMobile && "0px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main,
                },
              }}
            >
              <Typography variant="body2">Blog</Typography>
            </Button>
            <Button
              onClick={handleFeaturesClick}
              sx={{
                color: theme.palette.neutral.main,
                px: isMobile && "0px",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.palette.primary.main,
                },
              }}
            >
              <Typography variant="body2">Features</Typography>
            </Button>
          </Box>

          {isNonMobileScreens && (
            <Button
              variant="outlined"
              size="small"
              color="neutral"
              sx={{
                color: theme.palette.neutral.mediumDark,
              }}
              onClick={() => navigate("/signup")}
            >
              Log in
            </Button>
          )}
          <Button
            size="small"
            color="neutral"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.neutral.mediumDark,
              color: "white",
            }}
            onClick={() => navigate("/signup")}
          >
            {isNonMobileScreens ? "Start" : "Log in"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicNavbar;
