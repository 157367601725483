// import { useState } from "react";

import { useState, useContext, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import {
  AppBar,
  useScrollTrigger,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  InputBase,
  Tooltip,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate, useLocation } from "react-router-dom";
import DarkLightModeSwitchToggle from "../../components/DarkLightModeSwitchToggle";
import { logoutUser } from "../../api/authApi";
import { EditProfile } from "../../pages/widgets/ProfileWidget";
import { useAuth } from "../../context/AuthContext";
import { UserContext } from "../../context/UserContext";
import { useSearch } from "../../context/SearchContext";
import InviteFriendsDialog from "../../components/InviteFriendsDialog";

// const pages = ["Products", "Pricing", "Blog"];
const pages = {
  Home: "/home",
  Marketplace: "/marketplace",
  Library: "/library",
  Logs: "/log/history",
  Notes: "/notes",
  Guilds: "/rooms",
  // Guides: "/guides",
  // TBR: "/tbr",
  // Blog: "/blog"
  Community: "/community",
};

const settings = {
  Profile: "/profile",
  // Account: "/account",
  Dashboard: "/home",
  //             {/* Temporarily removed for production */}
  // Subscription: "/pricing",
  "Invite Friends": null,
  Guides: "/guides",
  Billing: null,
  Subscription: "/pricing",
  Logout: "/",
};

// onClick={() => navigate('/')}
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Navbar = ({ setShowNavbar }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isEditProfileOpen, setEditProfileOpen] = useState(false);
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const { setSearchQuery } = useSearch();
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const [isInviteFriendsOpen, setInviteFriendsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const { isAuthenticated, checkLogout } = useAuth();

  const stripeCustomerPortal =
    "https://billing.stripe.com/p/login/4gw6oJeHE8dugQU3cc" +
    "?prefilled_email" +
    userProfile.email;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  const elevationScrollBehavior = trigger ? 4 : 0;

  useEffect(() => {
    setIsLoading(false); // Set loading to false once authentication data is accepted
  }, [isAuthenticated]);

  if (isLoading) {
    return null;
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const searchQuery = encodeURIComponent(searchText.trim()).replace(
      /%20/g,
      "+"
    ); // Replace %20 with +
    setSearchQuery(searchQuery);
    const marketplaceRoute = `/marketplace?search=${searchQuery}`;
    // navigate(marketplaceRoute);
    navigate(marketplaceRoute);
    setSearchText("");
  };

  const isPageActive = (path) => location.pathname === path;

  const handleProfileUpdate = (updatedProfile) => {
    // Update the userProfile in the UserContext
    updateUserProfile(updatedProfile);
  };

  const handleMenuItemClick = (menuItem) => {
    handleCloseUserMenu();

    switch (menuItem) {
      case "Logout":
        handleLogout();
        break;
      case "Profile":
        setEditProfileOpen(true);
        break;
      case "Invite Friends":
        setInviteFriendsOpen(true);
        break;
      case "Billing":
        window.open(stripeCustomerPortal, "_blank");
        break;
      default:
        navigate(settings[menuItem]);
    }
  };

  const handleCloseEditProfile = () => {
    setEditProfileOpen(false);
  };

  let handleLogout = async (e) => {
    try {
      const result = await logoutUser();
      if (result.success) {
        setShowNavbar(false);
        checkLogout();
        navigate("/");
        // window.location.reload();
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // let handleLogout = async (e) => {
  //   const result = await logoutUser();
  //   console.log(result);
  //   if (result.success === true) {
  //     checkLogout();
  //     navigate("/login");
  //     // window.location.reload();
  //   }
  // };

  return (
    <AppBar
      elevation={elevationScrollBehavior}
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
      }}
      position="sticky"
    >
      <Container maxWidth="xl" sx={{ px: 0.5 }}>
        <Toolbar disableGutters>
          {/* Logo and Title */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              ml: "8px",
              gap: 1,
            }}
            onClick={() => navigate("/home")}
            style={{ cursor: "pointer" }}
          >
            <img
              src="/assets/rg-logo-framed.webp"
              alt="home page logo"
              style={{
                display: "flex",
                height: "40px",
                width: "40px",
                opacity: "100%",
              }}
            />
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontWeight: 700,
                textDecoration: "none",
                cursor: "pointer",
                color: (theme) =>
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral.extraDark
                    : theme.palette.neutral.mediumDark,
              }}
            >
              ReadersGuild
            </Typography>
          </Box>

          {/* Mobile Menu */}
          {isAuthenticated && isSmallScreen && (
            <Box sx={{ flexGrow: 1, display: "flex" }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon
                  sx={{
                    color: (theme) =>
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral.extraDark
                        : theme.palette.neutral.mediumDark,
                  }}
                />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {Object.keys(pages).map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate(pages[page]);
                    }}
                  >
                    <Typography
                      textAlign="center"
                      variant="body3"
                      fontWeight="600"
                      sx={{
                        color: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.neutral.extraDark
                            : theme.palette.neutral.mediumDark,
                      }}
                    >
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {/* Desktop Menu */}
          {isAuthenticated && !isSmallScreen && (
            <Box sx={{ ml: 3, flexGrow: 1, display: "flex", gap: 2 }}>
              {Object.keys(pages).map((page, index) => (
                <Button
                  key={index}
                  color="neutral"
                  onClick={() => navigate(pages[page])}
                  sx={{
                    my: 2,
                    color: (theme) =>
                      isPageActive(pages[page])
                        ? theme.palette.primary.main
                        : theme.palette.mode === "dark"
                        ? "whitesmoke"
                        : theme.palette.neutral.mediumDark,
                    display: "block",
                    fontWeight: 600,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? theme.palette.neutral.mediumMain
                          : theme.palette.neutral.mediumMain,
                    },
                  }}
                >
                  <Typography variant="body3">{page}</Typography>
                </Button>
              ))}
            </Box>
          )}

          {/* Rest of the navbar content */}
          {isAuthenticated && (
            <>
              <Box>
                <DarkLightModeSwitchToggle />
              </Box>
              {!isSmallScreen && (
                <Box sx={{ mx: 2 }}>
                  <Search
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "dark"
                          ? theme.palette.neutral.mediumMain
                          : "#e0e0e0",
                      color: (theme) =>
                        theme.palette.mode === "dark"
                          ? "whitesmoke"
                          : theme.palette.neutral.mediumDark,
                      transition: "background-color 0.3s",
                      "&:hover, &:focus": {
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.neutral.light
                            : theme.palette.neutral.mediumLight,
                      },
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "dark"
                              ? theme.palette.neutral.main
                              : theme.palette.neutral.mediumDark,
                        }}
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search Books"
                      inputProps={{ "aria-label": "search" }}
                      value={searchText}
                      onChange={(e) => handleSearchChange(e)}
                      onKeyDown={(e) =>
                        e.key === "Enter" && handleSearchSubmit(e)
                      }
                    />
                  </Search>
                </Box>
              )}

              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <Button
                    color="neutral"
                    onClick={handleOpenUserMenu}
                    sx={{ px: 2 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <Avatar alt={userProfile.name} src={userProfile.avatar} />
                      {!isSmallScreen && (
                        <Typography
                          variation="h4"
                          color="white"
                          sx={{
                            fontWeight: "500",
                            color: (theme) =>
                              theme.palette.mode === "dark"
                                ? "whitesmoke"
                                : theme.palette.neutral.mediumDark,
                            display: { xs: "none", md: "flex" },
                          }}
                        >
                          {userProfile.name}
                        </Typography>
                      )}
                    </Box>
                  </Button>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {Object.keys(settings).map((setting, index) => (
                    <MenuItem
                      size="small"
                      key={index}
                      onClick={handleCloseUserMenu}
                      sx={{ px: 2, py: 0.5 }}
                    >
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        textAlign="center"
                        onClick={() => handleMenuItemClick(setting)}
                      >
                        {setting}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
      <EditProfile
        open={isEditProfileOpen}
        onClose={handleCloseEditProfile}
        initialValues={{
          name: userProfile.name,
          email: userProfile.email,
          location: userProfile.location,
          picture: null,
          readingPreferences: userProfile.readingPreferences || [],
        }}
        onUpdateProfile={handleProfileUpdate}
      />
      <InviteFriendsDialog
        open={isInviteFriendsOpen}
        onClose={() => setInviteFriendsOpen(false)}
      />
    </AppBar>
  );
};

export default Navbar;
