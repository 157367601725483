import * as React from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

dayjs.extend(utc);
dayjs.extend(timezone);

const DateInput = ({ value, onChange }) => {
  // Convert the input value to a dayjs object
  const dayjsValue = React.useMemo(() => {
    if (dayjs.isDayjs(value)) {
      return value;
    }
    if (value instanceof Date) {
      return dayjs(value);
    }
    if (typeof value === "string") {
      return dayjs(value);
    }
    return dayjs(); // Default to current date if value is invalid
  }, [value]);

  const handleChange = (newValue) => {
    if (onChange) {
      // Convert the new value to UTC and format it as an ISO string
      const utcString = newValue.utc().format();
      onChange(utcString);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={dayjsValue}
          onChange={handleChange}
          views={["year", "month", "day"]}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default DateInput;
