import React from "react";
import { Box } from "@mui/material";
import FriendActivityWidget from "../../pages/widgets/FriendActivityWidget";

const RightFriendActivityWidget = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gridColumn: "span 1",
        gap: 2,
      }}
    >
      <Box>{/* <FriendActivityWidget /> */}</Box>
    </Box>
  );
};

export default RightFriendActivityWidget;
