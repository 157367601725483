import { Box } from "@mui/system";
import React from "react";
import GuildProfile from "./GuildProfile";
import RoomStatisticsCard from "../RoomStatisticsCard";
import MembersCard from "../MembersCard";

const GuildLeftProfile = ({ room, isRoomCreator, onRemoveMember }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <GuildProfile room={room} />
      {/* <RoomStatisticsCard currentRoom={room} /> */}
      <MembersCard
        members={room.members}
        creator={room.creator}
        memberProgress={room.memberProgress}
        isRoomCreator={isRoomCreator}
        onRemoveMember={onRemoveMember}
      />
    </Box>
  );
};

export default GuildLeftProfile;
