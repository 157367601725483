import { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const PrivateRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  useEffect(() => {
    const checkToken = () => {
      const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
      if (token) {
        // You can optionally decode the token to extract user information
        // For simplicity, let's assume if the token exists, the user is authenticated
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };

    checkToken();
  }, []);

  if (isLoading) {
    // Render loading indicator while checking authentication
    return <div>Loading...</div>;
  }

  // Redirect if not authenticated
  return isAuthenticated ? <>{children}</> : <Navigate to="/" replace />;
};

export default PrivateRoute;

// import { useState, useEffect } from "react";
// import { Navigate } from "react-router-dom";
// import { checkAuthentication } from "../api/authApi";
// import { useAuth } from "../context/AuthContext";

// const PrivateRoute = ({ children }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(false);
//   const [isLoading, setIsLoading] = useState(true); // Add loading state
//   const { userCheckResultIsNotLoggedIn } = useAuth();

//   useEffect(() => {
//     const fetchAuthentication = async () => {
//       try {
//         const result = await checkAuthentication();
//         setIsAuthenticated(result.data.success);
//         if (result.data.success === false) {
//           userCheckResultIsNotLoggedIn();
//         }
//       } catch (error) {
//         console.error("Error checking authentication:", error);
//       } finally {
//         setIsLoading(false); // Update loading state regardless of success or error
//       }
//     };

//     // const fetchAuthentication = async () => {
//     //     try {
//     //       const result = await checkAuthentication();
//     //       setIsAuthenticated(result.data.success);
//     //     } catch (error) {
//     //       console.error("Error checking authentication:", error);
//     //     } finally {
//     //       setTimeout(() => {
//     //         setIsLoading(false);
//     //       }, 2000);
//     //     }
//     //   };

//     fetchAuthentication();
//   }, []);

//   if (isLoading) {
//     // Render loading indicator while checking authentication
//     return <div>Loading...</div>;
//   }

//   // Redirect if not authenticated
//   return isAuthenticated ? <>{children}</> : <Navigate to="/login" replace />;
// };

// export default PrivateRoute;
