import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import { UserContext } from "../../context/UserContext";
import { getPublicReadingLogs } from "../../api/userApi";
import {
  removeReadingLogById,
  updateReadingLog,
  likeCommunityLog,
} from "../../api/logApi";
import CommunityCard from "./CommunityCard";

const Community = () => {
  const { userProfile } = useContext(UserContext);
  const [publicLogs, setPublicLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [cursor, setCursor] = useState(null);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const observer = useRef();
  const lastLogElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          fetchLogs();
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const fetchLogs = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await getPublicReadingLogs(cursor);
      setPublicLogs((prevLogs) => [...prevLogs, ...response.data]);
      setCursor(response.nextCursor);
      setHasMore(response.hasMore);
    } catch (error) {
      console.error("Failed on fetching publicLogs", error);
    } finally {
      setLoading(false);
    }
  }, [cursor, loading, hasMore]);

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleRemoveLog = async (logId) => {
    try {
      await removeReadingLogById(logId);
      setPublicLogs((prevLogs) => prevLogs.filter((log) => log._id !== logId));
    } catch (error) {
      console.error("Error removing reading log:", error);
    }
  };

  const handleLikeCommunityLog = useCallback(async (logId) => {
    try {
      const result = await likeCommunityLog(logId);
      if (result.success) {
        setPublicLogs((prevLogs) =>
          prevLogs.map((log) =>
            log._id === logId ? { ...log, likes: result.likes } : log
          )
        );
      }
    } catch (error) {
      console.error("Error liking/unliking community log:", error);
    }
  }, []);

  const handleLogUpdate = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);
      if (updatedPrivateSwitch) {
        setPublicLogs((prevLogs) =>
          prevLogs.filter((log) => log._id !== logId)
        );
      }
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <ContentGrid sx={{ gap: { xs: 2, md: 4 } }}>
      {!isMobileScreen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "span 1",
            gap: 2,
          }}
        >
          <LeftProfile />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridColumn: { xs: "1 / -1", lg: "span 2" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, md: 3 },
            width: "100%",
          }}
        >
          {publicLogs.map((log, index) => (
            <div
              key={log._id}
              ref={index === publicLogs.length - 1 ? lastLogElementRef : null}
            >
              <CommunityCard
                onLike={handleLikeCommunityLog}
                log={log}
                currentUserId={userProfile._id}
                onDelete={handleRemoveLog}
                onUpdateNote={handleLogUpdate}
                isMobileScreen={isMobileScreen}
                isSmallScreen={isSmallScreen}
              />
            </div>
          ))}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
              <CircularProgress />
            </Box>
          )}
          {/* {!hasMore && (
            <Typography variant="body2" textAlign="center" py={2}>
              No more posts to load
            </Typography>
          )} */}
        </Box>
      </Box>
    </ContentGrid>
  );
};

export default Community;
