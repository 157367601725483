import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { updateBookStatusInUserLibrary } from "../api/userApi";

const BookStatusSelect = ({ bookId, initialStatus, onStatusChange }) => {
  const [status, setStatus] = useState(initialStatus);
  const [isUpdating, setIsUpdating] = useState(false);
  const isExtraSmallScreen = useMediaQuery("(max-width:480px)");

  useEffect(() => {
    setStatus(initialStatus);
  }, [initialStatus]);

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    const previousStatus = status;

    // Optimistic update
    setStatus(newStatus);
    onStatusChange(newStatus);

    setIsUpdating(true);

    try {
      const result = await updateBookStatusInUserLibrary(bookId, newStatus);
      if (!result.success) {
        console.error("Failed to update book status:", result.message);
        // Revert the status if the update failed
        setStatus(previousStatus);
        onStatusChange(previousStatus);
      }
    } catch (error) {
      console.error("Error updating book status:", error);
      // Revert the status if there was an error
      setStatus(previousStatus);
      onStatusChange(previousStatus);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isExtraSmallScreen ? "column" : "row",
        alignItems: isExtraSmallScreen ? "flex-start" : "center",
        gap: isExtraSmallScreen ? 2 : 1,
      }}
    >
      <Typography variant="subtitle2">Status:</Typography>
      <Select
        value={status}
        onChange={handleStatusChange}
        size="small"
        sx={{ minWidth: 90, fontSize: "12px", p: 0 }}
        disabled={isUpdating}
      >
        <MenuItem value="tbr_unbought">Wishlist</MenuItem>
        <MenuItem value="tbr_owned">To Be Read</MenuItem>
        <MenuItem value="in_progress">Currently Reading</MenuItem>
        <MenuItem value="finished">Finished Reading</MenuItem>
      </Select>
      {isUpdating && <CircularProgress size={20} />}
    </Box>
  );
};

export default BookStatusSelect;
