export const parsePersonalLineData = (data) => {
  const filledDates = [];
  const currentDate = new Date();
  const dataDates = new Set(data.map((entry) => entry.date));

  // Iterate over the last 31 days
  for (let i = 0; i < 31; i++) {
    const day = new Date(currentDate);
    day.setDate(day.getDate() - i);
    const date = day.toDateString();
    const dateResult = day.toLocaleString("default", {
      month: "short",
      day: "2-digit",
    });

    // Check if the current date exists in the data
    if (dataDates.has(date)) {
      const entry = data.find((entry) => entry.date === date);
      const totalPagesRead =
        entry.totalPagesRead !== undefined ? entry.totalPagesRead : 0;
      filledDates.push({ x: dateResult, y: totalPagesRead });
    } else {
      filledDates.push({ x: dateResult, y: 0 });
    }
  }

  return filledDates;
};

export const parseGenreData = (genreData) => {
  let updatedGenreData = [];
  for (const genre in genreData) {
    updatedGenreData.push({ id: genre, label: genre, value: genreData[genre] });
  }
  return updatedGenreData;
};
