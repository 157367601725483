import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StackCenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default StackCenteredBox;
