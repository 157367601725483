import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import PieChart from "../../components/PieChart";
import { getGenreChartData } from "../../api/userApi";
import { parseGenreData } from "../../utility/utils";

const GenreChartWidget = () => {
  const [genreChartData, setGenreChartData] = useState([]);
  const [mostCollectedGenre, setMostCollectedGenre] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    fetchGenreChartData();
  }, []);

  const fetchGenreChartData = async () => {
    try {
      setIsLoading(true);
      const result = await getGenreChartData();
      const genreData = parseGenreData(result.data);
      if (genreData && genreData.length > 0) {
        const highestValGenreObj = genreData.reduce(
          (maxValObj, obj) => (obj.value > maxValObj.value ? obj : maxValObj),
          genreData[0]
        );
        setMostCollectedGenre(highestValGenreObj.id);
        setGenreChartData(genreData);
      }
    } catch (error) {
      console.error("Error fetching genre chart data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {genreChartData.length > 0 && (
        <Card>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box>
                <Typography variant="body1">Personal Genre Chart</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.neutral.medium }}
                >
                  Based on Books Owned
                </Typography>
              </Box>
              <Box>
                {genreChartData.length > 0 ? (
                  <PieChart pieData={genreChartData} />
                ) : (
                  <Typography variant="body2">
                    No genre data available
                  </Typography>
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
                  Insights
                </Typography>
                <Box>
                  {!mostCollectedGenre ? (
                    <Typography variant="subtitle1" sx={{ fontWeight: "400" }}>
                      Insights not available
                    </Typography>
                  ) : (
                    <Typography variant="body2" sx={{ fontWeight: "400" }}>
                      Your most collected genre is{" "}
                      <span style={{ textDecoration: "underline" }}>
                        {mostCollectedGenre}
                      </span>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default GenreChartWidget;
