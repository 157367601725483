import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { useMediaQuery } from "@mui/system";

const logoStyle = {
  width: "180px",
  height: "38.81px",
};

function Copyright() {
  return (
    <Typography variant="body3" color="text.secondary">
      {"Copyright © "}
      <Link href="/">ReadersGuild&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 8 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: { xs: "100%", sm: "60%" },
              alignItems: isNonMobileScreens && "start",
              justifyContent: isNonMobileScreens && "start",
            }}
          >
            <Box>
              <img
                src={"assets/rg-new-logo-dark.webp"}
                style={logoStyle}
                alt="logo of ReadersGuild"
              />
            </Box>
            <Typography variant="body3" fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body3" color="text.secondary" mb={2}>
              Subscribe to our newsletter for weekly updates and promotions.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                id="outlined-basic"
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                inputProps={{
                  autoComplete: "off",
                  "aria-label": "Enter your email address",
                }}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0 }}
              >
                Subscribe
              </Button>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body3" fontWeight={600}>
            Product
          </Typography>
          <Link color="text.secondary" href="#features">
            Features
          </Link>
          <Link color="text.secondary" href="#testimonials">
            Testimonials
          </Link>
          <Link color="text.secondary" href="#benefits">
            Highlights
          </Link>
          <Link color="text.secondary" href="#pricing">
            Pricing
          </Link>
          <Link color="text.secondary" href="#faqs">
            FAQs
          </Link>
        </Box>
        {/* <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body3" fontWeight={600}>
            Company
          </Typography>
          <Link color="text.secondary" href="#">
            About us
          </Link>
          <Link color="text.secondary" href="#">
            Careers
          </Link>
          <Link color="text.secondary" href="#">
            Press
          </Link>
        </Box> */}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body3" fontWeight={600}>
            Legal
          </Typography>
          <Link color="text.secondary" href="/about/terms">
            Terms
          </Link>
          <Link color="text.secondary" href="/about/privacy">
            Privacy
          </Link>
          {/* <Link color="text.secondary" href="#">
            Contact
          </Link> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        {isSuperSmallScreen ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Link color="text.secondary" href="/about/privacy">
              Privacy Policy
            </Link>
            <Link color="text.secondary" href="/about/terms">
              Terms of Service
            </Link>
            <Copyright />
          </Box>
        ) : (
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Link color="text.secondary" href="/about/privacy">
              Privacy Policy
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color="text.secondary" href="/about/terms">
              Terms of Service
            </Link>
            <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
              &nbsp;•&nbsp;
            </Typography>
            <Copyright />
          </Box>
        )}
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          <IconButton
            color="inherit"
            href="https://github.com/mui"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://x.com/MaterialUI"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/readersguild/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
