import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Avatar, Box, useTheme } from "@mui/material";
import { UserContext } from "../context/UserContext";
import parse from "html-react-parser";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function DialogNote({
  buttonText,
  bookTitle,
  dialogTitle,
  readingLogNote,
}) {
  const [open, setOpen] = React.useState(false);
  const { userProfile } = React.useContext(UserContext);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton size="small" color="neutral" onClick={handleClickOpen}>
        {buttonText}
      </IconButton>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{
            display: "flex",
            flexDirection: "column",
            minWidth: "300px",
            mr: 2,
            pt: 2,
            pb: 1,
            px: 3,
            gap: 1,
          }}
          id="customized-dialog-title"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Avatar
              alt={userProfile.firstName}
              src={userProfile.avatar}
              sx={{ width: 32, height: 32 }}
            />
            <Typography variant="body2" sx={{ fontWeight: 800 }}>
              {userProfile.name}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {" "}
            {bookTitle && (
              <Typography
                variant="body3"
                sx={{
                  color: theme.palette.neutral.mediumMain,
                  fontWeight: 600,
                }}
              >
                Title: {bookTitle}
              </Typography>
            )}
            <Typography
              variant="body2"
              sx={{ color: theme.palette.neutral.mediumLight, fontWeight: 400 }}
            >
              {dialogTitle}
            </Typography>
          </Box>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography
            gutterBottom
            variant="body3"
            sx={{
              fontFamily: '"Inter", "Helvetica", sans-serif',
              // fontSize: "16px",
              "& p": {
                marginBottom: "6px",
                marginTop: "6px",
              },
            }}
          >
            {parse(readingLogNote)}
          </Typography>
          {/* <Typography gutterBottom variant="bookText" sx={{ fontSize: "16px" }}>
            {readingLogNote}
          </Typography> */}
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </React.Fragment>
  );
}
