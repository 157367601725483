import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
  useTheme,
  Skeleton,
} from "@mui/material";
import CardContentPadding from "../../components/CardContentPadding";
import RadialProgressChart from "../../components/RadialProgressChart";
import FlexBetween from "../../components/FlexBetween";
import { postYearlyGoal } from "../../api/userApi";
import { UserContext } from "../../context/UserContext";

const EditGoalDialog = ({
  open,
  onClose,
  initialValue,
  onUpdateAnnualGoal,
}) => {
  const theme = useTheme();
  const [editedGoal, setEditedGoal] = useState(initialValue);

  const handleYearTargetChange = (e) => {
    const newValue = e.target.value;
    setEditedGoal(newValue !== "" ? parseInt(newValue, 10) : "");
  };
  const handleSaveGoal = async () => {
    const result = await postYearlyGoal(editedGoal);

    onUpdateAnnualGoal(result.data.annualGoal);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Yearly Goal</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          gutterBottom
          sx={{ color: theme.palette.neutral.main, fontWeight: 500 }}
        >
          Current goal: {initialValue} books
        </DialogContentText>
        <DialogContentText>
          Enter your new yearly book goal below:
        </DialogContentText>
        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            name="yearlyGoal"
            label="Yearly Goal"
            type="number"
            fullWidth
            variant="standard"
            value={editedGoal}
            onChange={(e) => handleYearTargetChange(e)}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral">
          Cancel
        </Button>
        <Button onClick={handleSaveGoal} color="primary" disabled={!editedGoal}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const HomeYearlyProgressWidget = () => {
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [annualGoal, setAnnualGoal] = useState(0);
  const [loadingState, setLoadingState] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setAnnualGoal(userProfile.annualGoal);
      } finally {
        setLoadingState(false);
      }
    };

    fetchData();
  }, [userProfile]); // Ensure useEffect runs whenever userProfile changes

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleAnnualGoalValue = async (newValue) => {
    const result = await postYearlyGoal(newValue); // Assuming this updates userProfile
    updateUserProfile(result.data); // Update userProfile context

    const storedUserProfile = JSON.parse(localStorage.getItem("userData"));
    localStorage.setItem(
      "userData",
      JSON.stringify({ ...storedUserProfile, annualGoal: newValue })
    );
    setAnnualGoal(newValue);
  };

  const yearlyGoalPercentage = Math.round(
    (userProfile.currentAnnualBooksFinished / userProfile.annualGoal) * 100
  );

  return (
    <>
      <Card>
        <CardContentPadding>
          {annualGoal === 0 ? (
            <>
              <Button
                fullWidth
                size="small"
                onClick={handleOpenDialog}
                variant="outlined"
              >
                {loadingState ? (
                  <Skeleton animation="wave" />
                ) : (
                  "Add Yearly Reading Goal"
                )}
              </Button>
              <EditGoalDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onUpdateAnnualGoal={handleAnnualGoalValue}
                initialValue={annualGoal}
              />
            </>
          ) : (
            <>
              <FlexBetween sx={{ alignItems: "center", mb: 1 }}>
                <Typography variant="body1" sx={{ fontWeight: "600" }}>
                  Yearly Goal Progress
                </Typography>
                <Button size="small" onClick={handleOpenDialog}>
                  Edit
                </Button>
              </FlexBetween>
              <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: 100,
                    height: 100,
                  }}
                >
                  <RadialProgressChart
                    progress={yearlyGoalPercentage / 100}
                    size={75}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 800,
                        color:
                          theme.palette.mode === "dark"
                            ? theme.palette.neutral.dark
                            : theme.palette.primary.dark,
                      }}
                    >
                      {yearlyGoalPercentage}%
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: 0.5,
                  }}
                >
                  <Typography variant="body3">
                    Reading Target:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {annualGoal} Books
                    </span>
                  </Typography>
                  <Typography variant="body3">
                    Finished:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {userProfile.currentAnnualBooksFinished}
                    </span>
                  </Typography>
                </Box>
              </Box>
              {/* Edit Goal Dialog */}
              <EditGoalDialog
                open={isDialogOpen}
                onClose={handleCloseDialog}
                onUpdateAnnualGoal={handleAnnualGoalValue}
                initialValue={annualGoal}
              />
            </>
          )}
        </CardContentPadding>
      </Card>
    </>
  );
};

export default HomeYearlyProgressWidget;
