import {
  Box,
  Typography,
  useMediaQuery,
  MenuItem,
  Select,
  Card,
} from "@mui/material";
import { useEffect, useState } from "react";
import BookCardWidget from "../../components/BookCardWidget";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import { getBooks } from "../../api/bookApi";
import { changeTBRBookStatus, getUserBooks } from "../../api/userApi";
import PageFrame from "../../components/PageFrame";
import {
  updateTBRStatus,
  deleteBookFromTBRCollection,
  getTBRCollection,
} from "../../api/tbrCollectionApi";
import TbrBook from "../../components/TbrBook";
import axios from "axios";

const TBRPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [userBooks, setUserBooks] = useState([]);
  const [tbrBooks, setTbrBooks] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [bookStatuses, setBookStatuses] = useState([]); // const [startIndex, setStartIndex] = useState(0);
  // const [totalItems, setTotalItems] = useState(0);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    const fetchData = async () => {
      await fetchUserBooksData();
      await fetchTBRCollection(selectedYear);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTBRCollection(selectedYear);
    };
    fetchData();
  }, [selectedYear]);

  useEffect(() => {
    // Fetch user books and their statuses
    const fetchUserBooksData = async () => {
      try {
        const result = await getUserBooks(); // Assuming this function fetches user books from the database
        setUserBooks(result.data);

        // Initialize book statuses array with default status for each book
        const initialBookStatuses = result.data.map((book) => ({
          id: book._id,
          status: "pre-ordered", // Default status
        }));
        setBookStatuses(initialBookStatuses);
      } catch (error) {
        console.error("Error fetching userBooks:", error);
      }
    };

    fetchUserBooksData();
  }, []);

  //TBR actions
  const fetchTBRCollection = async (year) => {
    try {
      const result = await getTBRCollection(year);
      if (result.success) {
        setTbrBooks(result.data.books);
      } else {
        setTbrBooks([]);
      }

      const initialBookStatuses = result.data.books.map((tbrBook) => ({
        id: tbrBook._id,
        status: "pre-ordered", // Default status
      }));
      setBookStatuses(initialBookStatuses);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Handle the error response from the server
        console.error("Error response:", error.response);
        // You can customize this part based on what your API returns
        setTbrBooks([]); // Clear the books if there's an error
      } else {
        // Handle other errors
        console.error("Unexpected error:", error);
      }
    }
  };

  const handleRemoveFromTBR = async ({ year, bookId }) => {
    try {
      const result = await deleteBookFromTBRCollection({ year, bookId });
      setTbrBooks(result);
    } catch (error) {
      console.error("Error fetching new tbr after removal:", error);
    }
  };

  const fetchUserBooksData = async () => {
    try {
      const result = await getUserBooks();
      setUserBooks(result.data);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching userBooks:", error);
    }
  };

  const handleUserBooksChange = (bookIdValue) => {
    const updatedUserBooks = userBooks.filter((book) => {
      return book.book._id.toString() !== bookIdValue.toString();
    }); // Compare _id instead of id
    setUserBooks(updatedUserBooks);
  };

  // const handleStatusChange = async (bookId, status) => {
  //   try {
  //     // Update the status in the local state
  //     const updatedBookStatuses = bookStatuses.map((bookStatus) =>
  //       bookStatus.id === bookId
  //         ? { ...bookStatus, status: status }
  //         : bookStatus
  //     );
  //     setBookStatuses(updatedBookStatuses);

  //     // Send the status update to the server

  //     const response = await changeTBRBookStatus({
  //       bookId: bookId,
  //       tbrStatus: status,
  //     });
  //   } catch (error) {
  //     console.error("Error updating status:", error);
  //   }
  // };

  // const arrayOfUserBooksId = userBooks.map((userBook) => userBook.book._id);
  // const arrayOfUserBooksId = userBooks.map((userBook) => userBook.book._id);

  const booksFinished = tbrBooks.filter(
    (book) => book?.bookProgress?.tbrStatus === "completed"
  );
  return (
    <>
      {isNonMobileScreens ? (
        <ContentGrid sx={{ gap: 4 }}>
          <LeftProfile />
          <Box
            sx={{
              gridColumn: "span 3",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  // renewed
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                >
                  TBR
                </Typography>
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  sx={{ ml: 2 }}
                >
                  {Array.from(
                    { length: 5 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "12px", fontWeight: "400" }}
              >
                You have {tbrBooks.length} books in your TBR
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              {tbrBooks.length > 0 ? (
                tbrBooks.map((tbrBook, index) => {
                  const isBookFinished = tbrBook?.bookProgress?.bookFinished;
                  const bookStatus = tbrBook?.bookProgress?.tbrStatus;
                  return (
                    <TbrBook
                      bookFinished={isBookFinished}
                      key={index}
                      tbrBook={tbrBook}
                      initialStatus={bookStatus}
                    />
                  );
                })
              ) : (
                <Typography>No books available</Typography>
              )}
            </Box>
          </Box>
        </ContentGrid>
      ) : (
        <Box sx={{ gap: 4, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ mb: 2 }}>
              <Box
                sx={{
                  width: "190px",
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <PageFrame
                  title={"My TBR"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "end",
                    gap: 0.5,
                  }}
                ></PageFrame>
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  sx={{
                    "& .MuiSelect-select": {
                      py: 0.5,
                      px: 2,
                    },
                  }}
                >
                  {Array.from(
                    { length: 5 },
                    (_, i) => new Date().getFullYear() - i
                  ).map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "12px", fontWeight: "400" }}
              >
                {booksFinished.length} out of {tbrBooks.length} books finished
                in your 2024 TBR
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              {tbrBooks.length > 0 ? (
                tbrBooks.map((tbrBook, index) => {
                  const isBookFinished = tbrBook?.bookProgress?.bookFinished;
                  const bookStatus = tbrBook?.bookProgress?.tbrStatus;
                  return (
                    <TbrBook
                      bookFinished={isBookFinished}
                      key={index}
                      tbrBook={tbrBook}
                      initialStatus={bookStatus}
                    />
                  );
                })
              ) : (
                <Typography>No books available</Typography>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TBRPage;
