import { Box } from "@mui/material";
import React from "react";
import LiveSessionForm from "./LiveSessionForm";

const LiveReadingSession = () => {
  return (
    <Box>
      <LiveSessionForm />
    </Box>
  );
};

export default LiveReadingSession;
