import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import LogHistoryWidget from "../widgets/LogHistoryWidget";
import ProfileCard from "../widgets/ProfileWidget";
import GenreChartWidget from "../widgets/GenreChartWidget";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import BackButton from "../../components/BackButton";
import FriendActivityWidget from "../widgets/FriendActivityWidget";
import PageFrame from "../../components/PageFrame";
import RightFriendActivityWidget from "../../layouts/rightFriendActivityWidget.jsx";
import { useParams } from "react-router-dom";
import { getUserReadingLogs } from "../../api/userApi.js";
import { updateReadingLog } from "../../api/logApi.js";

const LogHistory = () => {
  const [readingLogs, setReadingLogs] = useState([]);
  // const [book, setBook] = useState();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    fetchUserReadingLogs();
  }, []);

  const fetchUserReadingLogs = async () => {
    try {
      const result = await getUserReadingLogs();
      const resultArrayReverse = result.data.slice().reverse();
      setReadingLogs(resultArrayReverse);
    } catch (error) {
      console.error("Error fetching readingLogs: ", error);
    }
  };

  const handleLogUpdate = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      // Call your API to update the note
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);
      // Update the local state
      setReadingLogs((prevLogs) =>
        prevLogs.map((log) =>
          log._id === logId ? { ...log, readingNotes: updatedNote } : log
        )
      );
    } catch (error) {
      console.error("Error updating reading log note:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleLogRemoval = (logId) => {
    try {
      const previousReadingLog = readingLogs;
      const result = previousReadingLog.filter((log) => log._id !== logId);
      setReadingLogs(result);
    } catch (error) {
      console.error("Error removing reading log: ", error);
    }
  };

  return (
    <>
      {isNonMobileScreens ? (
        <PageFrame title="Log History">
          <ContentGrid sx={{ gap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 1.5,

                gridColumn: "span 3",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  width: "100%",
                }}
              >
                {readingLogs.map((readingLog, index) => (
                  <LogHistoryWidget
                    key={index}
                    logId={readingLog._id}
                    bookTitle={readingLog.book.title}
                    bookTotalPages={readingLog.book.pages}
                    isPicture={false}
                    sessionMode={"Regular"}
                    bookAuthor={readingLog.book.authors}
                    logDate={dayjs(readingLog.postDate).format(
                      "dddd, MMMM D, YYYY"
                    )}
                    isDiscussion={readingLog.isDiscussion}
                    roomTag={
                      readingLog.room ? `#${readingLog.room.roomTag}` : null
                    }
                    lastPageRead={readingLog.lastPageRead}
                    startPageRead={readingLog.startPageRead}
                    amountPageRead={readingLog.amountPageRead}
                    pageOrPercent={readingLog.pageNumberOrPercentage}
                    privateSwitch={readingLog.privateSwitch}
                    notes={readingLog.readingNotes}
                    onUpdateRemoveLog={handleLogRemoval}
                    onUpdateNote={handleLogUpdate}
                  />
                ))}
              </Box>
            </Box>
            {/* <RightFriendActivityWidget /> */}
          </ContentGrid>
        </PageFrame>
      ) : (
        <Box>
          <PageFrame
            title="Log History"
            sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          >
            {/* <LeftProfile /> */}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                mt: 1.5,

                // gap: 3,
                justifyContent: "space-between",
                width: "100%",
                "& > :not(:last-child)": {
                  marginBottom: 1.5, // Adjust the margin as needed
                },
              }}
            >
              {readingLogs.map((readingLog, index) => (
                <LogHistoryWidget
                  key={index}
                  logId={readingLog._id}
                  bookTitle={readingLog.book.title}
                  isPicture={false}
                  sessionMode={"Regular"}
                  bookAuthor={readingLog.book.authors}
                  logDate={dayjs(readingLog.postDate).format(
                    "dddd, MMMM D, YYYY"
                  )}
                  isDiscussion={readingLog.isDiscussion}
                  roomTag={
                    readingLog.room ? `#${readingLog.room.roomTag}` : null
                  }
                  lastPageRead={readingLog.lastPageRead}
                  startPageRead={readingLog.startPageRead}
                  amountPageRead={readingLog.amountPageRead}
                  pageOrPercent={readingLog.pageNumberOrPercentage}
                  privateSwitch={readingLog.privateSwitch}
                  notes={readingLog.readingNotes}
                  onUpdateRemoveLog={handleLogRemoval}
                  onUpdateNote={handleLogUpdate}
                />
              ))}
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gridColumn: "span 1",
                gap: 2,
                mt: 2,
              }}
            >
              <Box>
                <FriendActivityWidget />
              </Box>
            </Box> */}
          </PageFrame>
        </Box>
      )}
    </>
  );
};

export default LogHistory;
