import { useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import FlexBetween from "../../components/FlexBetween";
import CardContentPadding from "../../components/CardContentPadding";

const HomeThreeStatsWidget = () => {
  const { userProfile } = useContext(UserContext);
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  // const bookFinishedPercentage =
  //   userProfile.lastYearBooksFinished !== 0 &&
  //   userProfile.currentAnnualBooksFinished >= 1 &&
  //   userProfile.lastYearBooksFinished
  //     ? Math.round(
  //         (userProfile.currentAnnualBooksFinished /
  //           userProfile.lastYearBooksFinished) *
  //           100 *
  //           10
  //       ) / 10
  //     : 0;
  // const readingStreakPercentage =
  //   userProfile.bestReadingStreak !== 0 &&
  //   userProfile.currentReadingStreak >= 1 &&
  //   userProfile.bestReadingStreak
  //     ? Math.round(
  //         (userProfile.currentReadingStreak / userProfile.bestReadingStreak) *
  //           100 *
  //           10
  //       ) / 10
  //     : 0;
  const bookFinishedPercentage =
    userProfile.lastYearBooksFinished === 0
      ? 0 // Handle zero pages read last month (no change)
      : Math.round(
          ((userProfile.lastYearBooksFinished -
            userProfile.currentAnnualBooksFinished) /
            userProfile.lastYearBooksFinished) *
            -100 * // Multiply by -100 to ensure a negative value for decrease
            10
        ) / 10;
  const readingStreakPercentage =
    userProfile.bestReadingStreak === 0
      ? 0 // Handle zero pages read last month (no change)
      : Math.round(
          ((userProfile.bestReadingStreak - userProfile.totalPagesRead) /
            userProfile.bestReadingStreak) *
            -100 * // Multiply by -100 to ensure a negative value for decrease
            10
        ) / 10;
  const totalMonthlyPagesReadPercentage =
    userProfile.lastMonthPagesRead === 0
      ? 0 // Handle zero pages read last month (no change)
      : Math.round(
          ((userProfile.lastMonthPagesRead - userProfile.totalPagesRead) /
            userProfile.lastMonthPagesRead) *
            -100 * // Multiply by -100 to ensure a negative value for decrease
            10
        ) / 10;

  const monthlyPercentageDisplay =
    totalMonthlyPagesReadPercentage < 0
      ? `${totalMonthlyPagesReadPercentage}%`
      : `+${totalMonthlyPagesReadPercentage}%`;
  const readingStreakPercentageDisplay =
    readingStreakPercentage < 0
      ? `${readingStreakPercentage}%`
      : `+${readingStreakPercentage}%`;
  const bookFinishedPercentageDisplay =
    bookFinishedPercentage < 0
      ? `${bookFinishedPercentage}%`
      : `+${bookFinishedPercentage}%`;

  return (
    <>
      {isNonMobileScreens ? (
        <Card>
          <CardContent>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="subtitle1">Monthly Pages Read</Typography>
                <FlexBetween sx={{ justifyContent: "center", gap: 3 }}>
                  <Typography
                    //renewed
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    {userProfile.totalPagesRead}{" "}
                    {userProfile.totalPagesRead > 1 ? "pages" : "page"}
                  </Typography>
                  {totalMonthlyPagesReadPercentage > 0 && (
                    <Chip
                      label={monthlyPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "800",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
                <Typography
                  sx={{ color: theme.palette.neutral.medium }}
                  variant="subtitle2"
                >
                  Last Month: {userProfile.lastMonthPagesRead}{" "}
                  {userProfile.lastMonthPagesRead > 1 ? "pages" : "page"}
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="subtitle1">
                  Yearly Book Finished
                </Typography>
                <FlexBetween sx={{ justifyContent: "center", gap: 3 }}>
                  <Typography
                    //renewed
                    variant="h6"
                    sx={{ fontWeight: "bold" }}
                  >
                    {userProfile.currentAnnualBooksFinished}{" "}
                    {userProfile.currentAnnualBooksFinished > 1
                      ? "books"
                      : "book"}
                  </Typography>
                  {bookFinishedPercentage > 0 && (
                    <Chip
                      label={bookFinishedPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "800",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
                <Typography
                  sx={{ color: theme.palette.neutral.medium }}
                  variant="subtitle2"
                >
                  Last Year: {userProfile.lastYearBooksFinished}{" "}
                  {userProfile.lastYearBooksFinished > 1 ? "books" : "book"}
                </Typography>
              </Box>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 1,
                }}
              >
                <Typography variant="subtitle1">Reading Streak</Typography>
                <FlexBetween sx={{ justifyContent: "center", gap: 3 }}>
                  <Typography
                    //renewed
                    variant="h6"
                    sx={{ fontWeight: "800" }}
                  >
                    {userProfile.currentReadingStreak}{" "}
                    {userProfile.currentReadingStreak > 1 ? "days" : "day"}
                  </Typography>
                  {readingStreakPercentage > 0 && (
                    <Chip
                      label={readingStreakPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
                <Typography
                  sx={{ color: theme.palette.neutral.medium }}
                  variant="subtitle2"
                >
                  Best Streak: {userProfile.bestReadingStreak}{" "}
                  {userProfile.bestReadingStreak > 1 ? "days" : "day"}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card>
          <CardContentPadding>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: 0.5,
                }}
              >
                <FlexBetween sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
                    Monthly Pages Read
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.neutral.medium }}
                    variant="body2"
                  >
                    Last Month: {userProfile.lastMonthPagesRead}{" "}
                    {userProfile.lastMonthPagesRead > 1 ? "pages" : "page"}
                  </Typography>
                </FlexBetween>
                <FlexBetween sx={{ width: "100%" }}>
                  <Typography
                    //renewed
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    {userProfile.totalPagesRead}{" "}
                    {userProfile.totalPagesRead > 1 ? "pages" : "page"}
                  </Typography>
                  {totalMonthlyPagesReadPercentage > 0 && (
                    <Chip
                      label={monthlyPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "800",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
                {/* <Typography sx={{color: theme.palette.neutral.medium}} sx={{ fontSize: "12px" }}>
                  Last Month: {userProfile.lastMonthPagesRead}{" "}
                  {userProfile.lastMonthPagesRead > 1 ? "pages" : "page"}
                </Typography> */}
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "0.5",
                }}
              >
                <FlexBetween sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
                    Yearly Book Finished
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.neutral.medium }}
                    variant="body2"
                  >
                    Last Year: {userProfile.lastYearBooksFinished}{" "}
                    {userProfile.lastYearBooksFinished > 1 ? "books" : "book"}
                  </Typography>
                </FlexBetween>
                <FlexBetween sx={{ justifyContent: "center", gap: 3 }}>
                  <Typography
                    //renewed
                    variant="body1"
                    sx={{ fontWeight: "bold" }}
                  >
                    {userProfile.currentAnnualBooksFinished}{" "}
                    {userProfile.currentAnnualBooksFinished > 1
                      ? "books"
                      : "book"}
                  </Typography>
                  {bookFinishedPercentage > 0 && (
                    <Chip
                      label={bookFinishedPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "800",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
              </Box>
              <Divider orientation="horizontal" flexItem />
              <Box
                sx={{
                  display: "flex",
                  flex: "1",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "0.5",
                }}
              >
                <FlexBetween sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
                    Reading Streak
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.neutral.medium }}
                    variant="body2"
                  >
                    Best Streak: {userProfile.bestReadingStreak}{" "}
                    {userProfile.bestReadingStreak > 1 ? "days" : "day"}
                  </Typography>
                </FlexBetween>
                <FlexBetween sx={{ justifyContent: "center", gap: 3 }}>
                  <Typography
                    //renewed
                    variant="body1"
                    sx={{ fontWeight: "800" }}
                  >
                    {userProfile.currentReadingStreak}{" "}
                    {userProfile.currentReadingStreak > 1 ? "days" : "day"}
                  </Typography>
                  {readingStreakPercentage > 0 && (
                    <Chip
                      label={readingStreakPercentageDisplay}
                      size="small"
                      sx={{
                        fontWeight: "bold",
                        backgroundColor: theme.palette.primary.mediumLight,
                        color: theme.palette.primary.extraDark,
                      }}
                    />
                  )}
                </FlexBetween>
              </Box>
            </Box>
          </CardContentPadding>
        </Card>
      )}
    </>
  );
};

export default HomeThreeStatsWidget;
