import {
  TextField,
  useMediaQuery,
  Button,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dots from "../../../components/Dots";
import { useNavigate, useLocation } from "react-router-dom";

const OnBoarding1 = () => {
  const theme = useTheme();
  const [fullName, setFullName] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    if (onBoardingData.fullName) {
      setFullName(onBoardingData.fullName);
    }

    // Extract token from URL params and save to localStorage
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) {
      localStorage.setItem("registrationToken", token);
    }
  }, [location]);

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
  };

  const handleNext = () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    onBoardingData.fullName = fullName;
    localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    navigate("/signup/onboarding-step2");
    // Navigate to next step (OnBoarding2)
  };

  const handleDotsClick = () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    onBoardingData.fullName = fullName;
    localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    // Navigate to next step (OnBoarding2)
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isNonMobileScreens ? "80vh" : "70vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: theme.palette.neutral.main }}
          >
            How shall we call you?
          </Typography>
          <TextField
            label="Full Name"
            value={fullName}
            size="small"
            onChange={handleFullNameChange}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleNext}
          >
            Next
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots filled />
            <Dots
              navigateTo="/signup/onboarding-step2"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step3"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step4"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step5"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step6"
              onClick={handleDotsClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding1;
