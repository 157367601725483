import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeContextProvider } from "./context/ThemeContext";
import { UserProvider } from "./context/UserContext";
import { LoadingProvider } from "./context/LoadingContext";

// Register Service Worker (after other imports)
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) =>
        console.log("Service Worker registered: ", registration.scope)
      )
      .catch((error) =>
        console.log("Service Worker registration failed: ", error)
      );
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <ThemeContextProvider>
        <UserProvider>
          <LoadingProvider>
            <App />
          </LoadingProvider>
        </UserProvider>
      </ThemeContextProvider>
    </Router>
  </React.StrictMode>
);
