import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = Cookies.get("x-auth-cookie");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return instance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        // Here you would typically refresh the token
        // For now, we'll just clear the cookies and reject
        Cookies.remove("x-auth-cookie");
        Cookies.remove("jwt");
        localStorage.removeItem("userData");

        isRefreshing = false;
        processQueue(error, null);
        reject(error);
      }).finally(() => {
        isRefreshing = false; // Ensure isRefreshing is always set to false
      });
    }

    return Promise.reject(error);
  }
);

export const checkTokenValidity = async () => {
  try {
    const response = await instance.get("/check-token");
    return response.data.isValid;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return false;
    }
    console.error("Error checking token validity:", error);
    return false;
  }
};

export default instance;
