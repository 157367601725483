import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  useTheme,
  IconButton,
  useMediaQuery,
  FormControlLabel,
  Switch,
  Alert,
  Tooltip,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../../customQuillStyles.css";
import NumberInput from "../../../components/NumberInput";
import DateInput from "../../../components/DateInput";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import FlexBetween from "../../../components/FlexBetween";
import CustomQuillEditor from "../../../components/CustomQuillEditor";
import InfoIcon from "@mui/icons-material/Info";

const UpdateJournalDialog = ({
  open,
  onClose,
  onSubmit,
  lastPageRead,
  bookTotalPages,
  bookId,
}) => {
  const theme = useTheme();
  const [readingNotes, setReadingNotes] = useState("");
  const [newLastPageRead, setNewLastPageRead] = useState(lastPageRead);
  const [postDate, setPostDate] = useState(dayjs().toISOString());
  const [privateSwitch, setPrivateSwitch] = useState(true);
  const [error, setError] = useState("");
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  // Load saved draft from local storage when dialog opens
  useEffect(() => {
    if (open) {
      const savedDraft = localStorage.getItem(`journalDraft:${bookId}`);
      if (savedDraft) {
        const parsedDraft = JSON.parse(savedDraft);
        setReadingNotes(parsedDraft.readingNotes || "");
        // Parse the lastPageRead to make sure it's a number
        setNewLastPageRead(
          parseInt(parsedDraft.newLastPageRead) || lastPageRead
        );
        setPostDate(parsedDraft.postDate || dayjs().toISOString());
        setPrivateSwitch(
          parsedDraft.privateSwitch !== undefined
            ? parsedDraft.privateSwitch
            : true
        );
      } else {
        // Reset to initial values if no draft is found
        setNewLastPageRead(lastPageRead);
        setReadingNotes("");
        setPostDate(dayjs().toISOString());
        setPrivateSwitch(true);
      }
      setError(""); // Clear any previous errors when opening the dialog
    }
  }, [open, lastPageRead]);

  // Save draft to local storage whenever it changes
  useEffect(() => {
    if (open) {
      const draft = {
        readingNotes,
        newLastPageRead,
        postDate,
        privateSwitch,
      };
      localStorage.setItem(`journalDraft:${bookId}`, JSON.stringify(draft));
    }
  }, [open, readingNotes, newLastPageRead, postDate, privateSwitch]);

  const handleSubmit = () => {
    if (newLastPageRead > bookTotalPages) {
      setError(
        `Last page read cannot exceed the total number of pages (${bookTotalPages}).`
      );
      return;
    }

    onSubmit({
      readingNotes,
      lastPageRead: Number(newLastPageRead),
      startPageRead: lastPageRead,
      postDate,
      privateSwitch,
    });

    // Clear the draft from local storage after submission
    localStorage.removeItem(`journalDraft:${bookId}`);

    setReadingNotes("");
    setNewLastPageRead(lastPageRead);
    setPrivateSwitch(false);
    setError("");
    onClose();
  };

  const handleDateChange = (newValue) => {
    setPostDate(dayjs(newValue).toISOString());
  };

  const handleLastPageReadChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setNewLastPageRead(value);
    if (value > bookTotalPages) {
      setError(
        `Last page read cannot exceed the total number of pages (${bookTotalPages}).`
      );
    } else {
      setError("");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={!isNonMobileScreens}
    >
      <FlexBetween>
        <DialogTitle>Update Journal</DialogTitle>
        <IconButton onClick={onClose} sx={{ p: 0.5, pr: 3 }}>
          <ClearIcon />
        </IconButton>
      </FlexBetween>
      <DialogContent sx={{ pt: 0 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <DateInput value={postDate} onChange={handleDateChange} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "400" }}>
                Public
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    checked={privateSwitch}
                    onChange={(e) => setPrivateSwitch(e.target.checked)}
                    name="privateSwitch"
                  />
                }
                label="Private"
              />
              <Tooltip title="Public entries will be visible in the community section">
                <InfoIcon color="action" fontSize="small" />
              </Tooltip>
            </Box>
            <Typography variant="caption" color="text.secondary">
              {privateSwitch
                ? "This entry will be private and only visible to you."
                : "This entry will be public and posted in the community section."}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.neutral.medium, fontWeight: "500" }}
            >
              Last Page Read
            </Typography>
            <NumberInput
              value={newLastPageRead}
              onChange={handleLastPageReadChange}
              minimumPageNumber={lastPageRead}
              maximumPageNumber={bookTotalPages}
            />
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.neutral.medium, fontWeight: "500" }}
            >
              Out of {bookTotalPages}
            </Typography>
          </Box>
          {error && (
            <Alert severity="error" sx={{ mt: 1 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ display: "flex", width: "100%", mb: 2.5, mt: 1.5 }}>
            <CustomQuillEditor
              value={readingNotes}
              onChange={setReadingNotes}
              placeholder="Enter your reading notes..."
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" disabled={!!error}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateJournalDialog;
