import React, { useState, useCallback } from "react";
import {
  Avatar,
  Box,
  IconButton,
  Card,
  Typography,
  useTheme,
  Menu,
  useMediaQuery,
  MenuItem,
} from "@mui/material";
import {
  formatDistanceToNow,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import parse from "html-react-parser";
import FlexBetween from "../../components/FlexBetween";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CommentIcon from "@mui/icons-material/Comment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentSection from "../../components/CommentSection";

const formatDateConcise = (date) => {
  const now = new Date();
  const years = differenceInYears(now, date);
  const months = differenceInMonths(now, date);
  const days = differenceInDays(now, date);

  if (years > 0) return `${years}y`;
  if (months > 0) return `${months}m`;
  if (days > 0) return `${days}d`;
  return "today";
};

const DiscussionCard = ({
  discussion,
  isCurrentUser,
  onEdit,
  onRemove,
  onLike,
  currentUserId,
  roomId,
}) => {
  const theme = useTheme();
  const [showComments, setShowComments] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [commentCount, setCommentCount] = useState(
    discussion.comments?.length || 0
  );
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  const [localLikes, setLocalLikes] = useState(discussion.likes || []);
  const isLiked = localLikes.includes(currentUserId);

  const handleLike = useCallback(() => {
    // Optimistic update
    setLocalLikes((prevLikes) =>
      isLiked
        ? prevLikes.filter((id) => id !== currentUserId)
        : [...prevLikes, currentUserId]
    );

    // Call the onLike prop function
    onLike(discussion._id).catch(() => {
      // If there's an error, revert the optimistic update
      setLocalLikes((prevLikes) =>
        isLiked
          ? [...prevLikes, currentUserId]
          : prevLikes.filter((id) => id !== currentUserId)
      );
    });
  }, [discussion._id, currentUserId, isLiked, onLike]);

  const hasCommented =
    Array.isArray(discussion.comments) &&
    discussion.comments.some(
      (comment) => comment.user && comment.user._id === currentUserId
    );

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditOption = () => {
    onEdit();
    handleCloseMenu();
  };

  const handleDeleteOption = () => {
    onRemove();
    handleCloseMenu();
  };

  return (
    <Card>
      <CardContentDoublePadding>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1.5,
              alignItems: "center",
            }}
          >
            <Avatar
              src={discussion.user?.avatar || undefined}
              alt={discussion.user?.name || "User"}
              sx={{ width: 36, height: 36 }}
            >
              {!discussion.user?.avatar && discussion.user?.name
                ? discussion.user.name[0].toUpperCase()
                : "U"}
            </Avatar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography variant="body3" sx={{ fontWeight: "700" }}>
                {discussion.user?.name || "Anonymous User"}
              </Typography>
              {!isNonMobileScreens && (
                <>
                  {" "}
                  <Typography display="inline" sx={{ opacity: 0.5 }}>
                    &nbsp;•&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "400",
                      color: theme.palette.neutral.main,
                      mr: 1,
                    }}
                  >
                    {formatDateConcise(new Date(discussion.postDate), {
                      addSuffix: true,
                    })}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isNonMobileScreens && (
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "400",
                  color: theme.palette.neutral.main,
                  mr: 1,
                }}
              >
                {formatDistanceToNow(new Date(discussion.postDate), {
                  addSuffix: true,
                })}
              </Typography>
            )}
            {isCurrentUser && (
              <>
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleEditOption}>
                    <EditIcon sx={{ mr: 1 }} /> Edit
                  </MenuItem>
                  <MenuItem onClick={handleDeleteOption}>
                    <DeleteIcon sx={{ mr: 1 }} /> Delete
                  </MenuItem>
                </Menu>
              </>
            )}
          </Box>
        </Box>
        <Typography
          variant="body3"
          sx={{
            "& p": {
              marginBottom: "6px",
              marginTop: "6px",
            },
          }}
        >
          {parse(discussion.readingNotes || "")}
        </Typography>
        <FlexBetween sx={{ mt: 1, alignItems: "center" }}>
          <Typography variant="subtitle1">
            Page: {discussion.lastPageRead}
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <IconButton
                onClick={handleLike}
                color={isLiked ? "primary" : "default"}
              >
                {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
              {discussion.likes?.length > 0 && (
                <Typography variant="subtitle2" component="span">
                  {discussion.likes.length}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color={hasCommented ? "primary" : "default"}
                onClick={() => setShowComments(!showComments)}
              >
                <CommentIcon />
              </IconButton>
              <Typography variant="subtitle2" component="span">
                {commentCount}
              </Typography>
            </Box>
          </Box>
        </FlexBetween>

        {showComments && (
          <CommentSection
            discussionId={discussion._id}
            roomId={roomId}
            currentUserId={currentUserId}
          />
        )}
      </CardContentDoublePadding>
    </Card>
  );
};

export default DiscussionCard;
