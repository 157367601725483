import React from "react";
import { Box, Container, useTheme } from "@mui/material";

const LandingPageWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Box>
      <Box
        sx={{
          background: `linear-gradient(180deg, ${theme.palette.primary.light}, ${theme.palette.neutral.background})`,
          height: "500px", // Adjust this value to control the gradient height
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: -1,
        }}
      />
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

export default LandingPageWrapper;
