import React from "react";
import { Box, Typography, Select, MenuItem, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const RoomHeader = ({
  rooms,
  selectedRoom,
  onRoomChange,
  isMobileScreens,
  onCreateRoom,
  onJoinRoom,
  isRoomCreator,
  onRemoveRoom,
  canCreateOrJoinGroup,
}) => {
  const handleCreateRoom = () => {
    onCreateRoom();
  };

  const handleJoinRoom = () => {
    onJoinRoom();
  };

  return (
    <Box sx={{ width: "100%" }}>
      {isMobileScreens && (
        <Typography variant="h5" sx={{ fontWeight: "600", mb: 1 }}>
          Guild
        </Typography>
      )}
      <Box
        fullWidth={isMobileScreens ? true : false}
        sx={{
          display: "flex",
          flexDirection: isMobileScreens ? "column" : "row",
          gap: isMobileScreens ? 1 : 2,
          alignItems: "center",
          mb: isMobileScreens ? 1 : 0,
        }}
      >
        {!isMobileScreens && (
          <Typography variant="h5" sx={{ fontWeight: "600" }}>
            Guild
          </Typography>
        )}
        <Select
          fullWidth={isMobileScreens ? true : false}
          value={selectedRoom}
          onChange={onRoomChange}
          size="small"
          sx={{
            minWidth: 200,
            "& .MuiSelect-select": {
              py: 0.5,
              px: 1,
            },
          }}
          displayEmpty
        >
          {rooms.length === 0 ? (
            <MenuItem value="" disabled>
              No rooms available
            </MenuItem>
          ) : (
            rooms.map((room) => (
              <MenuItem key={room._id} value={room._id} variant="body3">
                {`${room.name} #${room.roomTag}`}
              </MenuItem>
            ))
          )}
        </Select>
        <Box
          sx={{
            width: "100%",
            ...(isMobileScreens
              ? {
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  columnGap: 1,
                }
              : {
                  display: "flex",
                  flexDirection: "row",
                  gap: 0.5,
                }),
          }}
        >
          <Button
            fullWidth={isMobileScreens ? true : false}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleCreateRoom}
            startIcon={<AddIcon />}
          >
            <Typography noWrap variant="body2">
              Create Guild
            </Typography>
          </Button>
          <Button
            fullWidth={isMobileScreens ? true : false}
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleJoinRoom}
          >
            <Typography noWrap variant="body2">
              Join Guild
            </Typography>
          </Button>
          {isRoomCreator && !isMobileScreens && (
            <Button variant="outlined" color="error" onClick={onRemoveRoom}>
              <Typography noWrap variant="body2">
                Delete Guild
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RoomHeader;
