import { Box } from "@mui/material";
import React from "react";
// import Wishlist from "./WishlistCard";

const Wishlist = () => {
  return (
    <Box>
      Wishlist
      {/* <wishcard /> */}
    </Box>
  );
};

export default Wishlist;
