import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  IconButton,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  ExitToApp as LogoutIcon,
  Dashboard as DashboardIcon,
  People as UsersIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { forceLogoutAllUsers } from "../../api/adminApi";
import { useNavigate } from "react-router-dom";

const AdminDashboard = ({ onLogout }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate("/admin/login");
  };

  const handleForceLogout = async () => {
    try {
      const response = await forceLogoutAllUsers();
      if (response.success) {
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error logging out all users:", error);
      setSnackbar({
        open: true,
        message: "An error occurred while logging out all users.",
        severity: "error",
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const AdminCard = ({ title, icon, action, actionText }) => (
    <Card elevation={3}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          {icon}
          <Typography variant="h6" ml={1}>
            {title}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          startIcon={action === handleForceLogout ? <LogoutIcon /> : null}
          onClick={action}
        >
          {actionText}
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AdminCard
            title="Maintenance Logout"
            icon={<LogoutIcon color="primary" />}
            action={handleForceLogout}
            actionText="Logout All Users"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AdminCard
            title="User Management"
            icon={<UsersIcon color="primary" />}
            action={() => navigate("/admin/user-management")}
            actionText="Manage Users"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AdminCard
            title="System Settings"
            icon={<SettingsIcon color="primary" />}
            action={() => alert("System settings feature coming soon!")}
            actionText="Configure"
          />
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Box sx={{ mt: 5 }}>
        <Button onClick={handleLogout} variant="contained" color="secondary">
          Admin Dashboard Logout
        </Button>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
