import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dots from "../../../components/Dots";
import { useNavigate } from "react-router-dom"; // Hook for navigation

const OnBoarding5 = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Hook for navigation
  const [selectedGenres, setSelectedGenres] = useState([]);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  useEffect(() => {
    const onboardingData = JSON.parse(localStorage.getItem("onboarding")) || {};
    if (onboardingData.genres) {
      setSelectedGenres(onboardingData.genres);
    }
  }, []);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedGenres((prev) =>
      prev.includes(value)
        ? prev.filter((genre) => genre !== value)
        : [...prev, value]
    );
  };

  const genres = [
    { label: "📚 Fiction", value: "fiction" },
    { label: "🧠 Non-fiction", value: "nonfiction" },
    { label: "🕵️ Mystery", value: "mystery_thriller" },
    { label: "🚀 Sci-Fi", value: "science_fiction_fantasy" },
    { label: "💖 Romance", value: "romance" },
    { label: "📖 Biography", value: "biography_memoir" },
    {
      label: "🌱 Self-help",
      value: "self_help_self_development",
    },
    {
      label: "💼 Business",
      value: "business_entrepreneurship",
    },
    { label: "🗿 History", value: "history" },
    { label: "🌍 Politics", value: "politics_geopolitics" },
    { label: "💹 Economy", value: "economy" },
    { label: "🏙️ Dystopian", value: "dystopian" },
    { label: "🏛️ Classics", value: "classics" },
    { label: "🔖 Other", value: "other" },
  ];

  const handleNext = () => {
    // Save selected genres to localStorage
    const onboardingData = JSON.parse(localStorage.getItem("onboarding")) || {};
    onboardingData.genres = selectedGenres;
    localStorage.setItem("onboarding", JSON.stringify(onboardingData));

    // Navigate to the next onboarding step
    navigate("/signup/onboarding-step6");
  };

  const handleDotsClick = () => {
    // Save selected genres to localStorage
    const onboardingData = JSON.parse(localStorage.getItem("onboarding")) || {};
    onboardingData.genres = selectedGenres;
    localStorage.setItem("onboarding", JSON.stringify(onboardingData));
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isNonMobileScreens ? "95vh" : "75vh",
        width: "92%",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            What genres do you typically enjoy?
          </Typography>
          {isNonMobileScreens ? (
            <FormGroup
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1,
              }}
            >
              {genres.map((genre) => (
                <FormControlLabel
                  key={genre.value}
                  control={
                    <Checkbox
                      value={genre.value}
                      onChange={handleCheckboxChange}
                      checked={selectedGenres.includes(genre.value)}
                    />
                  }
                  label={genre.label}
                  sx={{
                    border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                    px: 2,
                    py: 0.5,
                    borderRadius: 1,
                    width: "100%", // Ensures label takes full width
                    m: 0,
                  }}
                />
              ))}
            </FormGroup>
          ) : (
            <FormGroup
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 1,
              }}
            >
              {genres.map((genre) => (
                <FormControlLabel
                  key={genre.value}
                  control={
                    <Checkbox
                      size="small"
                      value={genre.value}
                      onChange={handleCheckboxChange}
                      checked={selectedGenres.includes(genre.value)}
                    />
                  }
                  label={genre.label}
                  sx={{
                    border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                    px: 0,
                    py: 0,
                    borderRadius: 1,
                    width: "100%", // Ensures label takes full width
                    m: 0,
                  }}
                />
              ))}
            </FormGroup>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Next
            </Button>

            <Button
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Skip
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots
              navigateTo="/signup/onboarding-step1"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step2"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step3"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step4"
              onClick={handleDotsClick}
            />
            <Dots filled onClick={handleDotsClick} />
            <Dots
              navigateTo="/signup/onboarding-step6"
              onClick={handleDotsClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding5;
