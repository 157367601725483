import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const getTBRCollection = async (year) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios.get(`${BASE_URL}/users/tbr`, {
      params: { year },
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching TBR collection:", error);
    throw error;
  }
};

export const addBookToTBRCollection = async (
  year,
  bookId,
  defaultTbrStatus
) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/users/tbr/addBook`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ year, bookId, tbrStatus: defaultTbrStatus }),
    });
    return response.data;
  } catch (error) {
    console.error("Error adding book to TBR collection:", error);
    throw error;
  }
};

export const deleteBookFromTBRCollection = async ({ year, bookId }) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/users/tbr/removeBook`,
      method: "DELETE",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ year, bookId }),
    });
    return response.data;
  } catch (error) {
    console.error("Error removing book from TBR collection:", error);
    throw error;
  }
};

export const updateTBRStatus = async ({ bookProgressId, status }) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/users/tbr/updateStatus`,
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ bookProgressId, newTbrStatus: status }),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating TBR status:", error);
    throw error;
  }
};
