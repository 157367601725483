import React from "react";
import { Card, CardContent } from "@mui/material";
import RoomStatistics from "./RoomStatistics";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";

const RoomStatisticsCard = ({ currentRoom }) => {
  return (
    <Card fullWidth>
      <CardContentDoublePadding>
        <RoomStatistics currentRoom={currentRoom} />
      </CardContentDoublePadding>
    </Card>
  );
};

export default RoomStatisticsCard;
