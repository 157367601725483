import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { UserContext } from "../../context/UserContext";
import PricingCard from "../../components/Stripe/PricingCard";
import ToggleTwoChoices from "../../components/ToggleTwoChoices";
import { useSocket } from "../../context/SocketContext";
import { getUserProfileData } from "../../api/userApi";
import { cancelUserSubscription } from "../../api/stripeApi";
//Stripe plans
export const plans = [
  {
    planName: "Everyone Starts",
    features: [
      "Track 10 books",
      "Tracking tools (Log, Journal, TBR)",
      "Visual tracking charts",
      "Create TBR",
      "Community feature (upcoming)",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_bIY4hydD6gE29ryeUU"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PFvJQIlmmGEqLN7e1ntyy8T"
        : "",
    price: "FREE",
  },
  {
    planName: "Premium",
    features: [
      "Everything in the free plan",
      "Track Unlimited Books",
      "Reading Challenge",
      "Personalized recommendation",
      "Join guild (upcoming)",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_3cs15m8iM5Zo1Z64gk"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PI3qLIlmmGEqLN7CiWon9YY"
        : "",
    price: 7,
    duration: "/year",
    billed: "$84 billed annually",
  },
  {
    planName: "Premium",
    features: [
      "Everything in the free plan",
      "Unlimited Books",
      "Reading Challenge (upcoming)",
      "Personalized book recommendation ",
      "Join guild (upcoming)",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_6oE9BScz2bjIdHO003"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PI3muIlmmGEqLN7nYBK52w9"
        : "",
    price: 10,
    duration: "/month",
    billed: "Billed Monthly",
  },
  {
    planName: "Pro",
    features: [
      "Create Guild",
      "Guild Notification",
      "Guild Forum & Chat",
      "Book Polling",
      "Create Meeting Schedule",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_28o9BS8iMevU6fm7su"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PHjqNIlmmGEqLN75DofuWhW"
        : "",
    price: 14,
    duration: "/Monthly",
    billed: "$168 billed annually",
  },
  {
    planName: "Pro",
    features: [
      "Create Guild",
      "Guild Notification",
      "Guild Forum & Chat",
      "Book Polling",
      "Create Meeting Schedule",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_5kA29q2Ys2NcgU0cMR"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PHjpQIlmmGEqLN7t0h7KFT9"
        : "",
    price: 20,
    duration: "/Monthly",
    billed: "Billed Monthly",
  },
];
const OldPricingPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const [isYearly, setIsMonthly] = useState(true);
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const socket = useSocket();

  useEffect(() => {
    if (socket) {
      socket.on("subscriptionPlanUpdated", async () => {
        try {
          await updateUserSubscriptionPlanData();
        } catch (error) {
          console.error("Failed to fetch updated user profile:", error);
        }
      });

      return () => {
        socket.off("subscriptionPlanUpdated");
      };
    }
  }, [socket]);

  const updateUserSubscriptionPlanData = async () => {
    try {
      const result = await getUserProfileData();
      updateUserProfile(result.data);
      localStorage.setItem("userData", JSON.stringify(result.data));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const { currentPlan } = userProfile || {};

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "full",
        }}
      >
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            mx: isNonMobileScreens ? 0 : 2,
            mb: 2,
            mt: isNonMobileScreens ? 0 : 3,
            fontSize: isNonMobileScreens ? "40px" : "28px",
            fontWeight: 900,
            lineHeight: 1.2,
          }}
        >
          Upgrade your reading experience
        </Typography>
        {/* <Typography
          variant="subtitle1"
          align="center"
          sx={{
            mb: isNonMobileScreens ? 4 : 2,
            mx: isNonMobileScreens ? 0 : 2,
            fontWeight: 600,
            color: theme.palette.neutral.mediumMain,
          }}
        >
          7% of earnings will be donated for education
        </Typography> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0.5,
            alignItems: "center",
            mx: isNonMobileScreens ? 0 : 2,
          }}
        >
          <Typography
            //renewed
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.neutral.darkMain }}
          >
            {isNonMobileScreens && "Billed"} Monthly
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isYearly}
                  onChange={(e) => setIsMonthly(e.target.checked)}
                  size="small"
                />
              }
              sx={{ m: 0 }}
            />
          </FormGroup>
          <Typography
            //renewed
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.neutral.darkMain }}
          >
            {isNonMobileScreens && "Billed"} Annually
          </Typography>{" "}
          <Typography variant="h6" color="primary">
            (save 30%)
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: isNonMobileScreens ? "row" : "column",
            mx: 3,
            gap: isNonMobileScreens ? 4 : 0,
          }}
        >
          <PricingCard
            planName={plans[0].planName}
            price={plans[0].price}
            features={plans[0].features}
            link={plans[0].link}
            duration={plans[0].duration}
            userEmail={userProfile.email}
            periodic={plans[0].billed}
            buttonLabel={currentPlan === "free" ? "current plan" : "subscribe"}
            disabled={currentPlan === "free"}
            onCancelSubscription={cancelUserSubscription}
          />
          {isYearly ? (
            <>
              <PricingCard
                planName={plans[1].planName}
                price={plans[1].price}
                features={plans[1].features}
                link={plans[1].link}
                duration={plans[1].duration}
                userEmail={userProfile.email}
                periodic={plans[1].billed}
                buttonLabel={
                  currentPlan === "premium" ? "current plan" : "subscribe"
                }
                bordered={true}
                disabled={currentPlan === "premium"}
              />
              <PricingCard
                planName={plans[3].planName}
                price={plans[3].price}
                features={plans[3].features}
                link={plans[3].link}
                duration={plans[3].duration}
                userEmail={userProfile.email}
                periodic={plans[3].billed}
                buttonLabel={
                  currentPlan === "pro" ? "current plan" : "upcoming"
                }
                // disabled={currentPlan === "pro"}
                disabled={true}
              />
            </>
          ) : (
            <>
              <PricingCard
                planName={plans[2].planName}
                price={plans[2].price}
                features={plans[2].features}
                link={plans[2].link}
                duration={plans[2].duration}
                userEmail={userProfile.email}
                periodic={plans[2].billed}
                buttonLabel={
                  currentPlan === "premium" ? "current plan" : "subscribe"
                }
                bordered={true}
                disabled={currentPlan === "premium"}
              />
              <PricingCard
                planName={plans[4].planName}
                price={plans[4].price}
                features={plans[4].features}
                link={plans[4].link}
                duration={plans[4].duration}
                userEmail={userProfile.email}
                periodic={plans[4].billed}
                buttonLabel={
                  currentPlan === "pro" ? "current plan" : "upcoming"
                }
                // disabled={currentPlan === "pro"}
                disabled={true}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default OldPricingPage;
