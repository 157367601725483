import axios from "axios";
import Cookies from "js-cookie";
import { logoutUser } from "./authApi";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

async function handleUnauthorized() {
  try {
    const result = await logoutUser();
    if (result.success) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }
  } catch (logoutError) {
    console.error("Error during logout:", logoutError);
  }
}

function handleUnauthorizedApiError(error) {
  if (error.response && error.response.status === 401) {
    handleUnauthorized();
    throw new Error("Unauthorized access. User logged out.");
  } else {
    throw error;
  }
}

export const editUserProfile = async (formData) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/editProfile`,
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.error("Error editing user profile:", error);
    throw error;
  }
};

export const inviteFriends = async (emails) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/invite-friends`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ emails }),
    });
    return response.data;
  } catch (error) {
    console.error("Error inviting friends:", error);
    throw error;
  }
};

export const getUserBooks = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/books`,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching book data:", error);
    throw error;
  }
};
// export const getUserBooks = async () => {
//   try {
//     const response = await axios({
//       url: `${BASE_URL}/users/books`,
//       method: "GET",
//       headers: {
//   Authorization: `Bearer ${token}`, // Include token in Authorization header
//   Accept: "application/json",
//   "Content-Type": "application/json;charset=UTF-8",
// },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching book data:", error);
//     throw error;
//   }
// };

export const getUserBookProgress = async (bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/bookProgress/` + bookId,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching book data:", error);
    throw error;
  }
};

export const getUserReadingLogsByBookId = async (bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/book/readingLogs/` + bookId,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reading logs data:", error);
    throw error;
  }
};

export const getUserPersonalLineChartData = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/lineChart`,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    console.error("Error fetching personal line chart data:", error);
    throw error;
  }
};

export const getGenreChartData = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/genreChart`,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching genre chart data:", error);
    throw error;
  }
};

export const updateBookStatusInUserLibrary = async (bookId, status) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/books/${bookId}/status`,
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ status }),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating book status:", error);
    return { success: false, message: error.message };
  }
};

export const getUserProfileData = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/`,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile data:", error);
    throw error;
  }
};

export const getUserReadingLogs = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/readingLogs/`,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching reading logs data:", error);
    throw error;
  }
};

export const getUserLastReadingLog = async () => {
  try {
    const token = Cookies.get("x-auth-cookie");
    if (!token) {
      await handleUnauthorized();
      throw new Error("No auth token. User logged out.");
    }

    const response = await axios({
      url: `${BASE_URL}/users/lastReadingLog/`,
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    handleUnauthorizedApiError(error);
    console.error("Error fetching user's last reading log:", error);
    throw error;
  }
};

// export const getUserReadingLogs = async () => {
//   try {
//     const response = await axios({
//       url: `${BASE_URL}/users/readingLogs/`,
//       method: "GET",
//       headers: {
//   Authorization: `Bearer ${token}`, // Include token in Authorization header
//   Accept: "application/json",
//   "Content-Type": "application/json;charset=UTF-8",
// },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching reading logs data:", error);
//     throw error;
//   }
// };

export const postYearlyGoal = async (editedGoal) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/annualGoal`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ editedGoal }), // Sending bookId in the request body
    });
    return response.data;
  } catch (error) {
    console.error("Error saving yearly goal:", error);
    throw error;
  }
};
export const postFinishBookGoalDate = async (editedGoal, bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/bookFinishGoal/` + bookId,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ editedGoal }), // Sending bookId in the request body
    });
    return response.data;
  } catch (error) {
    console.error("Error saving yearly goal:", error);
    throw error;
  }
};

export const postUserLocation = async (location) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/location`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ location }), // Sending bookId in the request body
    });
    return response.data;
  } catch (error) {
    console.error("Error saving yearly goal:", error);
    throw error;
  }
};

export const postGoogleBookToDBAndUser = async (book) => {
  try {
    const {
      authors,
      averageRating,
      bookProvider,
      cover,
      description,
      genre,
      isbn10,
      isbn13,
      language,
      pages,
      publication,
      ratingCount,
      title,
    } = book;
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/google/addBook`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        authors,
        averageRating,
        bookProvider,
        cover,
        description,
        genre,
        isbn10,
        isbn13,
        language,
        pages,
        publication,
        ratingCount,
        title,
      }),
    });
    return response.data;
  } catch (error) {
    console.error("Error adding book to user library:", error);
    throw error;
  }
};

export const addBookToUserLibrary = async (bookId, editedBookData = null) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/users/bookUpdate`,
      method: "PATCH",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ bookId, editedBookData }),
    });
    return response.data;
  } catch (error) {
    console.error("Error adding book to user library:", error);
    throw error;
  }
};

export const changeTBRBookStatus = async ({ bookId, status }) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/users/TBRBookStatusUpdate`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ bookId, status }), // Sending bookId in the request body
    });
    return response.data;
  } catch (error) {
    console.error("Error adding book to user library:", error);
    throw error;
  }
};

export const getPublicReadingLogs = async (cursor = null, limit = 10) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/users/public-reading-logs`,
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      params: { cursor, limit },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching public reading logs:", error);
    throw error;
  }
};
