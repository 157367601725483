import React, { useEffect, useState } from "react";
import { Box, Typography, CircularProgress, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getUserProfileData } from "../../../api/userApi";
import { useAuth } from "../../../context/AuthContext";
import { UserContext } from "../../../context/UserContext";

const AccountSetupLoading = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { checkLogin } = useAuth();
  const { updateUserProfile } = React.useContext(UserContext);
  const [loadingMessage, setLoadingMessage] = useState(
    "Setting up your account..."
  );
  const [progress, setProgress] = useState(0);

  const MINIMUM_LOADING_TIME = 5000; // 7.5 seconds minimum loading time
  const LOADING_MESSAGE_DELAY = 1500; // 2.5 seconds between messages
  const LOADING_MESSAGES = [
    "Preparing your personalized reading experience...",
  ];

  const fetchUserData = async () => {
    try {
      const result = await getUserProfileData();
      updateUserProfile(result.data);
      localStorage.setItem("userData", JSON.stringify(result.data));
      return true;
    } catch (error) {
      console.error("Failed to fetch user data:", error);
      return false;
    }
  };

  useEffect(() => {
    const setupAccount = async () => {
      const startTime = Date.now();
      await checkLogin();
      const success = await fetchUserData();

      if (success) {
        const elapsedTime = Date.now() - startTime;
        const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

        // Cycle through loading messages with 2.5-second intervals
        let messageIndex = 0;
        const messageInterval = setInterval(() => {
          setLoadingMessage(LOADING_MESSAGES[messageIndex]);
          messageIndex = (messageIndex + 1) % LOADING_MESSAGES.length;
        }, LOADING_MESSAGE_DELAY);

        // Simulate progress
        const interval = setInterval(() => {
          setProgress((oldProgress) => {
            const newProgress = Math.min(oldProgress + 1, 100);
            if (newProgress === 100) {
              clearInterval(interval);
            }
            return newProgress;
          });
        }, remainingTime / 100);

        // Ensure the messages show for at least 7.5 seconds before navigation
        setTimeout(() => {
          clearInterval(messageInterval);
          navigate("/home");
        }, MINIMUM_LOADING_TIME);
      } else {
        setLoadingMessage(
          "We're having trouble setting up your account. Please try refreshing the page."
        );
      }
    };

    setupAccount();
  }, [checkLogin, navigate, updateUserProfile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        backgroundColor: theme.palette.background.default,
      }}
    >
      <img
        src="/assets/rg-new-logo-dark.webp"
        alt="ReadersGuild's logo"
        style={{ height: "53.91px", width: "250px", marginBottom: "2rem" }}
      />
      <CircularProgress variant="indeterminate" size={60} sx={{ mb: 3 }} />
      <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
        {loadingMessage}
      </Typography>
      <Typography variant="body1" sx={{ textAlign: "center", maxWidth: "80%" }}>
        We're personalizing your ReadersGuild experience. This may take a few
        moments.
      </Typography>
    </Box>
  );
};

export default AccountSetupLoading;
