import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const BlogWrapper = ({ component: Component, requireAuth = false }) => {
  const isAuthenticated = !!Cookies.get("x-auth-cookie");

  if (requireAuth && !isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return <Component isAuthenticated={isAuthenticated} />;
};

export default BlogWrapper;
