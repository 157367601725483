import React from "react";
import { useMediaQuery } from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../customQuillStyles.css"; // Make sure to import your custom styles

const CustomQuillEditor = ({ value, onChange, placeholder }) => {
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const modules = {
    toolbar: {
      container: [
        [{ header: [3, 4, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
      ],
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "list",
    "bullet",
  ];

  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={modules}
      formats={formats}
      placeholder={placeholder}
      theme="snow"
      style={{
        height: isNonMobileScreens ? "210px" : "50vh",
        marginBottom: "20px",
        width: "100%",
        borderBottomLeftRadius: "10px",
        borderBottomRightRadius: "10px",
      }}
    />
  );
};

export default CustomQuillEditor;
