import { Box } from "@mui/material";
import React from "react";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({ barData }) => {
  const data = [
    {
      day: "Monday",
      pages: 81,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    {
      day: "Tuesday",
      pages: 20,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    {
      day: "Wednesday",
      pages: 31,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    {
      day: "Thursday",
      pages: 42,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    {
      day: "Friday",
      pages: 180,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    {
      day: "Saturday",
      pages: 10,
      pagesColor: "hsl(82, 70%, 50%)",
    },
    {
      day: "Sunday",
      pages: 100,
      pagesColor: "hsl(82, 70%, 50%)",
    },

    //     {
    //       country: "AF",
    //       "hot dog": 96,
    //       "hot dogColor": "hsl(196, 70%, 50%)",
    //       burger: 144,
    //       burgerColor: "hsl(196, 70%, 50%)",
    //       sandwich: 11,
    //       sandwichColor: "hsl(44, 70%, 50%)",
    //       kebab: 192,
    //       kebabColor: "hsl(204, 70%, 50%)",
    //       fries: 144,
    //       friesColor: "hsl(262, 70%, 50%)",
    //       donut: 172,
    //       donutColor: "hsl(172, 70%, 50%)",
    //     },
    //     {
    //       country: "AG",
    //       "hot dog": 49,
    //       "hot dogColor": "hsl(2, 70%, 50%)",
    //       burger: 192,
    //       burgerColor: "hsl(81, 70%, 50%)",
    //       sandwich: 48,
    //       sandwichColor: "hsl(29, 70%, 50%)",
    //       kebab: 89,
    //       kebabColor: "hsl(23, 70%, 50%)",
    //       fries: 17,
    //       friesColor: "hsl(146, 70%, 50%)",
    //       donut: 129,
    //       donutColor: "hsl(11, 70%, 50%)",
    //     },
    //     {
    //       country: "AI",
    //       "hot dog": 56,
    //       "hot dogColor": "hsl(193, 70%, 50%)",
    //       burger: 149,
    //       burgerColor: "hsl(193, 70%, 50%)",
    //       sandwich: 165,
    //       sandwichColor: "hsl(200, 70%, 50%)",
    //       kebab: 163,
    //       kebabColor: "hsl(125, 70%, 50%)",
    //       fries: 2,
    //       friesColor: "hsl(252, 70%, 50%)",
    //       donut: 142,
    //       donutColor: "hsl(24, 70%, 50%)",
    //     },
    //     {
    //       country: "AL",
    //       "hot dog": 126,
    //       "hot dogColor": "hsl(235, 70%, 50%)",
    //       burger: 35,
    //       burgerColor: "hsl(14, 70%, 50%)",
    //       sandwich: 3,
    //       sandwichColor: "hsl(275, 70%, 50%)",
    //       kebab: 146,
    //       kebabColor: "hsl(89, 70%, 50%)",
    //       fries: 105,
    //       friesColor: "hsl(31, 70%, 50%)",
    //       donut: 199,
    //       donutColor: "hsl(34, 70%, 50%)",
    //     },
    //     {
    //       country: "AM",
    //       "hot dog": 89,
    //       "hot dogColor": "hsl(231, 70%, 50%)",
    //       burger: 155,
    //       burgerColor: "hsl(209, 70%, 50%)",
    //       sandwich: 197,
    //       sandwichColor: "hsl(256, 70%, 50%)",
    //       kebab: 42,
    //       kebabColor: "hsl(308, 70%, 50%)",
    //       fries: 78,
    //       friesColor: "hsl(10, 70%, 50%)",
    //       donut: 184,
    //       donutColor: "hsl(277, 70%, 50%)",
    //     },
  ];
  return (
    <Box sx={{ height: "400px", width: "auto", maxWidth: "600px" }}>
      <ResponsiveBar
        data={barData}
        keys={[
          "hot dog",
          "burger",
          "sandwich",
          "kebab",
          "fries",
          "donut",
          "pages",
        ]}
        indexBy="day"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.25}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        borderColor={{
          from: "color",
          modifiers: [["darker", "1.7"]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "day",
          legendPosition: "middle",
          legendOffset: 32,
          truncateTickAt: 0,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "# of pages",
          legendPosition: "middle",
          legendOffset: -40,
          truncateTickAt: 0,
        }}
        enableLabel={false}
        labelSkipWidth={17}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[]}
        motionConfig="gentle"
        role="application"
        isFocusable={true}
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        }
      />
    </Box>
  );
};

export default BarChart;
