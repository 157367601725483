export const privacyPolicy = `
Last Updated: 08/08/2024

1. Introduction

This Privacy Policy describes how ReadersGuild ("we", "our", or "us") collects, uses, and shares your personal information when you use our service.

2. Information We Collect

2.1. Information you provide:
- Account information (e.g., name, email address)
- Profile information (e.g., reading preferences, bio)
- Content you create (e.g., book reviews, comments, book clubs, reading logs, book discussions)

2.2. Information we collect automatically:
- Usage data (e.g., pages visited, features used)
- Device information (e.g., IP address, browser type)

3. How We Use Your Information

We use your information to:
- Provide and improve our service
- Personalize your experience
- Communicate with you about our service
- Analyze usage patterns and trends

4. Cookies and Tracking Technologies

We use cookies and similar tracking technologies to enhance your experience on our platform, analyze usage, and deliver personalized content. You can control cookies through your browser settings.

5. Information Sharing and Disclosure

We do not sell your personal information. We may share your information in the following situations:
- With your consent
- To comply with legal obligations
- To protect our rights, privacy, safety, or property
- With third-party service providers who assist us in operating our service (e.g., analytics providers, email services)

6. Data Retention

We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.

7. Data Security

We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.

8. International Data Transfers

If you access our service from outside the United States, your information may be transferred to and processed in the United States or other countries where we or our third-party service providers operate.

9. Children's Privacy

Our service is not intended for children under 13 years of age. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will take steps to delete such information.

10. Your Rights and Choices

You may have the right to:
- Access, update, or delete your personal information
- Object to or restrict certain processing of your data
- Data portability

11. Changes to This Privacy Policy

We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

12. Contact Us

If you have any questions about this Privacy Policy, please contact us at fabio@readers-guild.com.
`;
