import {
  TextField,
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dots from "../../../components/Dots";
import { useNavigate } from "react-router-dom";

const OnBoarding4 = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [emails, setEmails] = useState(["", "", ""]);
  const [emailErrors, setEmailErrors] = useState([false, false, false]);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    if (onBoardingData.emails) {
      const savedEmails = onBoardingData.emails;
      const filledEmails = [...emails];
      savedEmails.forEach((email, index) => {
        if (index < emails.length) {
          filledEmails[index] = email;
        }
      });
      setEmails(filledEmails);
    }
  }, []);

  const handleEmailChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index] = event.target.value;
    setEmails(newEmails);
  };

  const validateEmails = () => {
    const errors = emails.map((email) => {
      if (email === "") return false; // Skip validation if the email is empty
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return !emailPattern.test(email);
    });
    setEmailErrors(errors);
    return !errors.includes(true);
  };

  const handleNext = () => {
    if (validateEmails()) {
      const onBoardingData =
        JSON.parse(localStorage.getItem("onBoarding")) || {};
      onBoardingData.emails = emails.filter((email) => email !== ""); // Save non-empty emails
      localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));

      // Navigate to the next onboarding step
      navigate("/signup/onboarding-step5");
    }
  };

  const handleDotsClick = () => {
    if (validateEmails()) {
      const onBoardingData =
        JSON.parse(localStorage.getItem("onBoarding")) || {};
      onBoardingData.emails = emails.filter((email) => email !== ""); // Save non-empty emails
      localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isNonMobileScreens ? "80vh" : "70vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          {isNonMobileScreens ? (
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              Invite friends to track books with you!
            </Typography>
          ) : (
            <Box
              sx={{
                width: "full",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", maxWid: "70%" }}
              >
                Invite friends to track books together!
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "260px",
            }}
          >
            <Box
              fullWidth
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {emails.map((email, index) => (
                <TextField
                  fullWidth
                  key={index}
                  label={`Email ${index + 1}`}
                  value={email}
                  onChange={(e) => handleEmailChange(index, e)}
                  error={emailErrors[index]}
                  helperText={emailErrors[index] ? "Invalid email address" : ""}
                  size="small"
                  sx={{ marginBottom: "10px" }}
                />
              ))}
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                color="primary"
                type="submit"
                onClick={handleNext}
              >
                Next
              </Button>

              <Button
                fullWidth
                size="small"
                variant="outlined"
                color="primary"
                type="submit"
                onClick={handleNext}
              >
                Skip
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots
              navigateTo="/signup/onboarding-step1"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step2"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step3"
              onClick={handleDotsClick}
            />
            <Dots filled onClick={handleDotsClick} />
            <Dots
              navigateTo="/signup/onboarding-step5"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step6"
              onClick={handleDotsClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding4;
