import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <>
      <IconButton
        variant="contained"
        sx={{ pl: 0 }}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon />
      </IconButton>
    </>
  );
};

export default BackButton;
