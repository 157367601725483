import React from "react";
import { useTheme, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Dots = ({ filled, navigateTo, onClick }) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    navigate(navigateTo);
  };

  return (
    <Box
      onClick={handleClick}
      sx={{
        width: "32px",
        height: "6px",
        borderRadius: 3,
        opacity: filled ? 1.0 : 0.4,
        backgroundColor: theme.palette.neutral.main,
        cursor: "pointer", // Show cursor on hover
        "&:hover": {
          opacity: 0.7,
        },
      }}
    ></Box>
  );
};

export default Dots;
