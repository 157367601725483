import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const postSessionGetClientSecret = async () => {
  try {
    const response = await axios({
      url: `${BASE_URL}/billing/checkout`,
      method: "POST", // Use POST method
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ priceId: "price_1PFvJQIlmmGEqLN7e1ntyy8T" }),
    });
    return response.data.clientSecret; // Assuming the server responds with the client secret
  } catch (error) {
    console.error("Error fetching client secret:", error);
    throw new Error("Failed to fetch client secret");
  }
};

export const cancelUserSubscription = async () => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/billing/cancel-subscription`,
      method: "POST", // Use POST method
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data; // Assuming the server responds with the client secret
  } catch (error) {
    console.error("Failed to cancel subscription:", error);
    throw new Error("Failed to cancel subscription");
  }
};

export async function retrieveCheckoutSession(sessionId) {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/checkout-status/${sessionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error retrieving checkout session:", error);
    throw new Error("Failed to retrieve checkout session");
  }
}
