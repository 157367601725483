import { alpha } from "@mui/material/styles";

// Brand color: "#2ba581",

export const colorTokens = {
  //flockGreen
  primary: {
    50: "#dff2ed",
    100: "#b2dfd0",
    200: "#81cbb3",
    300: "#50b596",
    400: "#2ba581",
    500: "#08956e",
    600: "#058862",
    700: "#007854",
    800: "#006847",
    900: "#004c2c",
  },

  //orange
  // primary: {
  //   50: "#e6f5ea",
  //   100: "#c2e6cb",
  //   200: "#9ad6aa",
  //   300: "#70c788",
  //   400: "#4ebb6f",
  //   500: "#25af56",
  //   600: "#1ba04c",
  //   700: "#0d8e40",
  //   800: "#007d35",
  //   900: "#005e22",
  // },
  success: {
    50: "#e6f5ea",
    100: "#c2e6cb",
    200: "#9ad6aa",
    300: "#70c788",
    400: "#4ebb6f",
    500: "#25af56",
    600: "#1ba04c",
    700: "#0d8e40",
    800: "#007d35",
    900: "#005e22",
  },
  // neutral Grey
  gray: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
  primaryGray: {
    50: "#F2F4F3",
    100: "#E5E9E7",
    200: "#D1D9D5",
    300: "#BDC9C3",
    400: "#A9B9B1",
    500: "#95A99F",
    600: "#81998D",
    700: "#6D897B",
    800: "#597969",
    900: "#456957",
    1000: "#315945",
  },

  warning: {
    50: "#FFF3E0",
    100: "#FFE0B2",
    200: "#FFCC80",
    300: "#FFB74D",
    400: "#FFA726",
    500: "#FF9800",
    600: "#FB8C00",
    700: "#F57C00",
    800: "#EF6C00",
    900: "#E65100",
  },
  // error: {
  //   50: "#ffebee",
  //   100: "#ffcdd2",
  //   200: "#ef9a9a",
  //   300: "#e57373",
  //   400: "#ef5350",
  //   500: "#f44336",
  //   600: "#e53935",
  //   700: "#d32f2f",
  //   800: "#c62828",
  //   900: "#b71c1c",
  // },
  //flockError
  error: {
    50: "#fce2e2",
    100: "#f6b6b8",
    200: "#ec898a",
    300: "#e05d5f",
    400: "#d54041",
    500: "#ca2c25",
    600: "#bc2526",
    700: "#aa1d26",
    800: "#981226",
    900: "#780024",
  },
};

export const themeSettings = (mode) => ({
  palette: {
    mode: mode,
    ...(mode === "dark"
      ? {
          primary: {
            extraDark: colorTokens.primary[100], // Adjusted for better contrast
            dark: colorTokens.primary[300], // Adjusted for better contrast
            darkMain: colorTokens.primary[500],
            main: colorTokens.primary[600], // Adjusted for better contrast
            mediumMain: colorTokens.primary[700], // Adjusted for better contrast
            mediumLight: colorTokens.primary[800], // Adjusted for better contrast
            light: colorTokens.primary[900], // Adjusted for better contrast
          },
          neutral: {
            dark: colorTokens.gray[200], // Adjusted for better contrast
            darkMain: colorTokens.gray[300], // Adjusted for better contrast
            main: colorTokens.gray[400], // Adjusted for better contrast
            mediumMain: colorTokens.gray[400], // Adjusted for better contrast
            medium: colorTokens.gray[400], // Adjusted for better contrast
            mediumLight: colorTokens.gray[400], // Adjusted for better contrast
            light: colorTokens.gray[900], // Adjusted for better contrast
            background: colorTokens.gray[800],
            contrastText: "#fff",
          },
          error: {
            extraDark: colorTokens.error[50], // Adjusted for better contrast
            dark: colorTokens.error[200], // Adjusted for better contrast
            mediumDark: colorTokens.error[300], // Adjusted for better contrast
            main: colorTokens.error[400], // Adjusted for better contrast
            mediumMain: colorTokens.error[500], // Adjusted for better contrast
            medium: colorTokens.error[400], // Adjusted for better contrast
            mediumLight: colorTokens.error[700], // Adjusted for better contrast
            light: colorTokens.error[800], // Adjusted for better contrast
          },
          background: {
            default: colorTokens.gray[800], // Adjusted for better contrast
            paper: colorTokens.gray[800],
            alt: colorTokens.gray[700], // Adjusted for better contrast
          },
          text: {
            primary: "#fff",
            secondary: colorTokens.gray[500],
          },
        }
      : // {
        //   // palette values for dark mode
        //   primary: colorTokens.danger,
        //   divider: colorTokens.danger[700],
        //   background: {
        //     default: colorTokens.danger[900],
        //     paper: colorTokens.danger[900],
        //   },
        //   text: {
        //     primary: "#fff",
        //     secondary: colorTokens.gray[500],
        //   },
        // }
        {
          primary: {
            extraDark: colorTokens.primary[800],
            lighterExtraDark: colorTokens.primary[700],
            dark: colorTokens.primary[600],
            darkMain: colorTokens.primary[500],
            main: colorTokens.primary[400],
            mediumMain: colorTokens.primary[300],
            mediumLight: colorTokens.primary[200],
            light: colorTokens.primary[100],
            extraLight: colorTokens.primary[50],
          },
          neutral: {
            extraDark: colorTokens.gray[900],
            dark: colorTokens.gray[800],
            mediumDark: colorTokens.gray[700],
            darkMain: colorTokens.gray[600],
            main: colorTokens.gray[500],
            medium: colorTokens.gray[400],
            mediumLight: colorTokens.gray[300],
            midLight: colorTokens.gray[200],
            light: colorTokens.gray[50],
            background: colorTokens.gray[10],
            contrastText: "#fff",
          },
          warning: {
            extraDark: colorTokens.warning[900],
            dark: colorTokens.warning[700],
            main: colorTokens.warning[600],
            mediumLight: colorTokens.warning[200],
            light: colorTokens.warning[100],
            extraLight: colorTokens.warning[50],
          },
          error: {
            extraDark: colorTokens.error[900],
            dark: colorTokens.error[700],
            main: colorTokens.error[600],
            mediumLight: colorTokens.error[200],
            light: colorTokens.error[100],
            extraLight: colorTokens.error[50],
          },
          success: {
            extraDark: colorTokens.success[900],
            dark: colorTokens.success[700],
            main: colorTokens.success[600],
            mediumLight: colorTokens.success[200],
            light: colorTokens.success[100],
            extraLight: colorTokens.success[50],
          },
          background: {
            default: colorTokens.gray[10],
            alt: colorTokens.gray[0],
          },
          transitions: {
            duration: {
              standard: "0.3s",
            },
            create: (properties) => ({
              transition: `${properties
                .map((property) => `${property} ${"0.3s"}`)
                .join(",")}`,
            }),
          },
        }),
  },
  shape: {
    borderRadius: 8,
  },
  divider:
    mode === "dark"
      ? alpha(colorTokens.gray[600], 0.3)
      : alpha(colorTokens.gray[300], 0.5),
  background: {
    default: "#fff",
    paper: colorTokens.gray[50],
    ...(mode === "dark" && {
      default: colorTokens.gray[900],
      paper: colorTokens.gray[800],
    }),
  },
  text: {
    primary: colorTokens.gray[800],
    secondary: colorTokens.gray[600],
    ...(mode === "dark" && {
      primary: "#fff",
      secondary: colorTokens.gray[400],
    }),
  },
  action: {
    selected: alpha(colorTokens.primary[200], 0.2),
    ...(mode === "dark" && {
      selected: alpha(colorTokens.primary[800], 0.2),
    }),
  },
  typography: {
    fontFamily: ["inter", "sans-serif"].join(","),
    fontSize: 12,
    /* Readjust new font size */
    h1: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 42,
      lineHeight: 1.2,
    },
    h4: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h5: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontFamily: ["inter", "sans-serif"].join(","),
      fontSize: 18,
    },
    // older fonts size
    // h1: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 40,
    // },
    // h2: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 32,
    // },
    // h3: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 24,
    // },
    // h4: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 20,
    // },
    // h5: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 17,
    //   fontWeight: 600,
    // },
    // h6: {
    //   fontFamily: ["inter",  "sans-serif"].join(","),
    //   fontSize: 15,
    // },
    body1: {
      fontFamily: ["inter", "sans-serif"].join("."),
      fontWeight: 400,
      fontSize: 16,
    },
    body2: {
      fontFamily: ["inter", "sans-serif"].join("."),
      fontSize: 14,
      fontWeight: 400,
    },
    body3: {
      fontFamily: ["inter", "sans-serif"].join("."),
      fontSize: 15,
      fontWeight: 400,
    },
    caption: {
      fontFamily: ["inter", "sans-serif"].join("."),
      fontSize: 12,
      fontWeight: 400,
    },
    overline: {
      fontFamily: ["inter", "sans-serif"].join("."),
    },
    bookText: {
      fontFamily: ["Times New Roman", "Times", "Georgia", "serif"].join(","),
      fontSize: 14,
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       "&.MuiButton-containedNeutral": {
    //         backgroundColor:
    //           mode === "dark" ? colorTokens.gray[400] : colorTokens.gray[600],
    //         color: "#fff",
    //         "&:hover": {
    //           backgroundColor:
    //             mode === "dark" ? colorTokens.gray[500] : colorTokens.gray[600],
    //         },
    //       },
    //     },
    //   },
    // },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
        disableGutters: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          padding: 8,
          overflow: "clip",
          backgroundColor: "#fff",
          border: "1px solid",
          borderColor: colorTokens.primaryGray[100],
          ":before": {
            backgroundColor: "transparent",
          },
          "&:first-of-type": {
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          },
          "&:last-of-type": {
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          },
          ...(theme.palette.mode === "dark" && {
            backgroundColor: colorTokens.primaryGray[900],
            borderColor: colorTokens.primaryGray[800],
          }),
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ theme }) => ({
          border: "none",
          borderRadius: 8,
          "&:hover": { backgroundColor: colorTokens.primary[100] },
          ...(theme.palette.mode === "dark" && {
            "&:hover": { backgroundColor: colorTokens.primary[800] },
          }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        root: {
          fontSize: "15px",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: { mb: 20, border: "none" },
      },
    },
    // MuiToggleButtonGroup: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       borderRadius: "10px",
    //       boxShadow: `0 4px 16px ${alpha(colorTokens.primaryGray[400], 0.2)}`,
    //       "& .Mui-selected": {
    //         color: colorTokens.primary[500],
    //       },
    //       ...(theme.palette.mode === "dark" && {
    //         "& .Mui-selected": {
    //           color: "#fff",
    //         },
    //         boxShadow: `0 4px 16px ${alpha(colorTokens.primary[700], 0.5)}`,
    //       }),
    //     }),
    //   },
    // },
    // MuiToggleButton: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       padding: "12px 16px",
    //       textTransform: "none",
    //       borderRadius: "10px",
    //       fontWeight: 500,
    //       ...(theme.palette.mode === "dark" && {
    //         color: colorTokens.primaryGray[400],
    //         boxShadow: "0 4px 16px rgba(0, 0, 0, 0.5)",
    //         "&.Mui-selected": { color: colorTokens.primary[300] },
    //       }),
    //     }),
    //   },
    // },
    // MuiButtonBase: {
    //   defaultProps: {
    //     disableTouchRipple: true,
    //     disableRipple: true,
    //   },
    //   styleOverrides: {
    //     root: {
    //       boxSizing: "border-box",
    //       transition: "all 100ms ease-in",
    //       "&:focus-visible": {
    //         outline: `3px solid ${alpha(colorTokens.primary[500], 0.5)}`,
    //         outlineOffset: "2px",
    //       },
    //     },
    //   },
    // },
    // MuiButton: {
    //   styleOverrides: {
    //     root: ({ theme, ownerState }) => ({
    //       boxSizing: "border-box",
    //       boxShadow: "none",
    //       borderRadius: "10px",
    //       textTransform: "none",
    //       "&:active": {
    //         transform: "scale(0.98)",
    //       },
    //       ...(ownerState.size === "small" && {
    //         maxHeight: "32px",
    //       }),
    //       ...(ownerState.size === "medium" && {
    //         height: "40px",
    //       }),
    //       ...(ownerState.variant === "contained" &&
    //         ownerState.color === "primary" && {
    //           color: colorTokens.primary[50],
    //           background: colorTokens.primary[500],
    //           backgroundImage: `linear-gradient(to bottom, ${colorTokens.primary[400]}, ${colorTokens.primary[600]})`,
    //           boxShadow: `inset 0 1px ${alpha(colorTokens.primary[300], 0.4)}`,
    //           outline: `1px solid ${colorTokens.primary[700]}`,
    //           "&:hover": {
    //             background: colorTokens.primary[400],
    //             backgroundImage: "none",
    //             boxShadow: `0 0 0 1px  ${alpha(colorTokens.primary[300], 0.5)}`,
    //           },
    //         }),
    //       ...(ownerState.variant === "outlined" && {
    //         backgroundColor: alpha(colorTokens.primary[300], 0.1),
    //         borderColor: colorTokens.primary[300],
    //         color: colorTokens.primary[500],
    //         "&:hover": {
    //           backgroundColor: alpha(colorTokens.primary[300], 0.3),
    //           borderColor: colorTokens.primary[200],
    //         },
    //       }),
    //       ...(ownerState.variant === "text" && {
    //         color: colorTokens.primary[500],
    //         "&:hover": {
    //           backgroundColor: alpha(colorTokens.primary[300], 0.3),
    //           borderColor: colorTokens.primary[200],
    //         },
    //       }),
    //       ...(theme.palette.mode === "dark" && {
    //         ...(ownerState.variant === "outlined" && {
    //           backgroundColor: alpha(colorTokens.primary[600], 0.1),
    //           borderColor: colorTokens.primary[700],
    //           color: colorTokens.primary[300],
    //           "&:hover": {
    //             backgroundColor: alpha(colorTokens.primary[600], 0.3),
    //             borderColor: colorTokens.primary[700],
    //           },
    //         }),
    //         ...(ownerState.variant === "text" && {
    //           color: colorTokens.primary[300],
    //           "&:hover": {
    //             backgroundColor: alpha(colorTokens.primary[600], 0.3),
    //             borderColor: colorTokens.primary[700],
    //           },
    //         }),
    //       }),
    //     }),
    //   },
    // },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0 6px 24px -8px rgba(0, 0, 0, 0.2)",
          transition: "box-shadow 0.3s ease",
          "&:hover": {
            boxShadow: "0 12px 36px -10px rgba(0, 0, 0, 0.25)",
          },
        },
      },
    },
    // MuiCard: {
    //   styleOverrides: {
    //     root: ({ theme, ownerState }) => ({
    //       backgroundColor: colorTokens.gray[50],
    //       borderRadius: 10,
    //       border: `1px solid ${alpha(colorTokens.gray[200], 0.8)}`,
    //       boxShadow: "none",
    //       transition: "background-color, border, 80ms ease",
    //       ...(ownerState.variant === "outlined" && {
    //         background: `linear-gradient(to bottom, #FFF, ${colorTokens.gray[50]})`,
    //         "&:hover": {
    //           borderColor: colorTokens.primary[300],
    //           boxShadow: `0 0 24px ${colorTokens.primary[100]}`,
    //         },
    //       }),
    //       ...(theme.palette.mode === "dark" && {
    //         backgroundColor: alpha(colorTokens.gray[800], 0.6),
    //         border: `1px solid ${alpha(colorTokens.gray[700], 0.3)}`,
    //         ...(ownerState.variant === "outlined" && {
    //           background: `linear-gradient(to bottom, ${
    //             colorTokens.gray[900]
    //           }, ${alpha(colorTokens.gray[800], 0.5)})`,
    //           "&:hover": {
    //             borderColor: colorTokens.primary[700],
    //             boxShadow: `0 0 24px ${colorTokens.primary[800]}`,
    //           },
    //         }),
    //       }),
    //     }),
    //   },
    // },
    // MuiChip: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       alignSelf: "center",
    //       py: 1.5,
    //       px: 0.5,
    //       background: `linear-gradient(to bottom right, ${colorTokens.primary[50]}, ${colorTokens.primary[100]})`,
    //       border: "1px solid",
    //       borderColor: `${alpha(colorTokens.primary[500], 0.3)}`,
    //       fontWeight: "600",
    //       "&:hover": {
    //         backgroundColor: colorTokens.primary[500],
    //       },
    //       "&:focus-visible": {
    //         borderColor: colorTokens.primary[800],
    //         backgroundColor: colorTokens.primary[200],
    //       },
    //       "& .MuiChip-label": {
    //         color: colorTokens.primary[500],
    //       },
    //       "& .MuiChip-icon": {
    //         color: colorTokens.primary[500],
    //       },
    //       ...(theme.palette.mode === "dark" && {
    //         background: `linear-gradient(to bottom right, ${colorTokens.primary[700]}, ${colorTokens.primary[900]})`,
    //         borderColor: `${alpha(colorTokens.primary[500], 0.5)}`,
    //         "&:hover": {
    //           backgroundColor: colorTokens.primary[600],
    //         },
    //         "&:focus-visible": {
    //           borderColor: colorTokens.primary[200],
    //           backgroundColor: colorTokens.primary[600],
    //         },
    //         "& .MuiChip-label": {
    //           color: colorTokens.primary[200],
    //         },
    //         "& .MuiChip-icon": {
    //           color: colorTokens.primary[200],
    //         },
    //       }),
    //     }),
    //   },
    // },
    // MuiDivider: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       borderColor: `${alpha(colorTokens.primaryGray[200], 0.8)}`,
    //       ...(theme.palette.mode === "dark" && {
    //         borderColor: `${alpha(colorTokens.primaryGray[700], 0.4)}`,
    //       }),
    //     }),
    //   },
    // },
    // MuiLink: {
    //   defaultProps: {
    //     underline: "none",
    //   },
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       color: colorTokens.primary[600],
    //       fontWeight: 500,
    //       position: "relative",
    //       textDecoration: "none",
    //       "&::before": {
    //         content: '""',
    //         position: "absolute",
    //         width: 0,
    //         height: "1px",
    //         bottom: 0,
    //         left: 0,
    //         backgroundColor: colorTokens.primary[200],
    //         opacity: 0.7,
    //         transition: "width 0.3s ease, opacity 0.3s ease",
    //       },
    //       "&:hover::before": {
    //         width: "100%",
    //         opacity: 1,
    //       },
    //       ...(theme.palette.mode === "dark" && {
    //         color: colorTokens.primary[200],
    //       }),
    //     }),
    //   },
    // },
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       borderRadius: "99px",
    //       color: colorTokens.primaryGray[500],
    //       fontWeight: 500,
    //       ...(theme.palette.mode === "dark" && {
    //         color: colorTokens.primaryGray[300],
    //       }),
    //     }),
    //   },
    // },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       backgroundImage: "none",
    //       backgroundColor: colorTokens.gray[100],
    //       ...(theme.palette.mode === "dark" && {
    //         backgroundColor: alpha(colorTokens.gray[900], 0.6),
    //       }),
    //     }),
    //   },
    // },
    // MuiSwitch: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       boxSizing: "border-box",
    //       width: 36,
    //       height: 24,
    //       padding: 0,
    //       transition: "background-color 100ms ease-in",
    //       "&:hover": {
    //         "& .MuiSwitch-track": {
    //           backgroundColor: colorTokens.primary[600],
    //         },
    //       },
    //       "& .MuiSwitch-switchBase": {
    //         "&.Mui-checked": {
    //           transform: "translateX(13px)",
    //         },
    //       },
    //       "& .MuiSwitch-track": {
    //         borderRadius: 50,
    //       },
    //       "& .MuiSwitch-thumb": {
    //         boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.2)",
    //         backgroundColor: "#FFF",
    //         width: 16,
    //         height: 16,
    //         margin: 2,
    //       },
    //       ...(theme.palette.mode === "dark" && {
    //         width: 36,
    //         height: 24,
    //         padding: 0,
    //         transition: "background-color 100ms ease-in",
    //         "&:hover": {
    //           "& .MuiSwitch-track": {
    //             backgroundColor: colorTokens.primary[600],
    //           },
    //         },
    //         "& .MuiSwitch-switchBase": {
    //           "&.Mui-checked": {
    //             transform: "translateX(13px)",
    //           },
    //         },
    //         "& .MuiSwitch-thumb": {
    //           boxShadow: "0 0 2px 2px rgba(0, 0, 0, 0.2)",
    //           backgroundColor: "#FFF",
    //           width: 16,
    //           height: 16,
    //           margin: 2,
    //         },
    //       }),
    //     }),
    //     switchBase: {
    //       height: 24,
    //       width: 24,
    //       padding: 0,
    //       color: "#fff",
    //       "&.Mui-checked + .MuiSwitch-track": {
    //         opacity: 1,
    //       },
    //     },
    //   },
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: ({ theme }) => ({
    //       "& label .Mui-focused": {
    //         color: "white",
    //       },
    //       "& .MuiInputBase-input": {
    //         boxSizing: "border-box",
    //         "&::placeholder": {
    //           opacity: 0.7,
    //         },
    //       },
    //       "& .MuiOutlinedInput-root": {
    //         boxSizing: "border-box",
    //         minWidth: 280,
    //         minHeight: 40,
    //         height: "100%",
    //         borderRadius: "10px",
    //         border: "1px solid",
    //         borderColor: colorTokens.primaryGray[200],
    //         transition: "border-color 120ms ease-in",
    //         "& fieldset": {
    //           border: "none",
    //           boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    //           background: `${alpha("#FFF", 0.3)}`,
    //         },
    //         "&:hover": {
    //           borderColor: colorTokens.primary[300],
    //         },
    //         "&.Mui-focused": {
    //           borderColor: colorTokens.primary[400],
    //           outline: "4px solid",
    //           outlineColor: colorTokens.primary[200],
    //         },
    //       },
    //       ...(theme.palette.mode === "dark" && {
    //         "& .MuiOutlinedInput-root": {
    //           boxSizing: "border-box",
    //           minWidth: 280,
    //           minHeight: 40,
    //           height: "100%",
    //           borderRadius: "10px",
    //           border: "1px solid",
    //           borderColor: colorTokens.primaryGray[600],
    //           transition: "border-color 120ms ease-in",
    //           "& fieldset": {
    //             border: "none",
    //             boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.4)",
    //             background: `${alpha(colorTokens.primaryGray[800], 0.4)}`,
    //           },
    //           "&:hover": {
    //             borderColor: colorTokens.primary[300],
    //           },
    //           "&.Mui-focused": {
    //             borderColor: colorTokens.primary[400],
    //             outline: "4px solid",
    //             outlineColor: alpha(colorTokens.primary[500], 0.5),
    //           },
    //         },
    //       }),
    //     }),
    //   },
    // },
  },
});
