import React, { createContext, useContext, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

// Snackbar Context
const SnackbarContext = createContext();

// Custom hook to use Snackbar Context
export const useSnackbar = () => useContext(SnackbarContext);

// Snackbar Provider component
export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  // const [severity, setSeverity] = useState("success");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const showSnackbar = (message, severity = "success") => {
    setMessage(message);
    // setSeverity(severity);
    setOpen(true);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      {/* {success ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          action={action}
        >
          <MuiAlert
            elevation={6}
            variant="filled"
            onClose={handleClose}
            severity={severity}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      ) : ( */}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
        message={message}
      />
      {/* )} */}
    </SnackbarContext.Provider>
  );
};
