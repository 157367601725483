import * as React from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
// import Typography from "@mui/material/Typography";
const DisplayRating = ({ ratingValue = 3, precision = 0.5 }) => {
  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating
        name="read-only"
        value={ratingValue}
        precision={precision}
        readOnly
      />
    </Box>
  );
};

export default DisplayRating;
