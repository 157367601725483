import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getAllBlogPosts } from "../../api/blogApi";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Pagination,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import LandingPageWrapper from "../LandingPage/LandingPageWrapper";

const BlogList = ({ isAuthenticated }) => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const data = await getAllBlogPosts(page);
        if (data && Array.isArray(data.posts)) {
          setPosts(data.posts);
          setTotalPages(data.totalPages || 1);
        } else {
          throw new Error("Received data is not in the expected format");
        }
      } catch (err) {
        console.error("Error fetching posts:", err);
        setError(
          err.message || "Failed to fetch blog posts. Please try again later."
        );
      } finally {
        setLoading(false);
      }
    };
    fetchPosts();
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );

  return (
    <LandingPageWrapper>
      <Container>
        <Helmet>
          <title>
            ReadersGuild Blog | Book Reviews, Reading Tips, and More
          </title>
          <meta
            name="description"
            content="Explore the latest book reviews, reading tips, and literary discussions on the ReadersGuild blog."
          />
          <link rel="canonical" href="https://www.readers-guild.com/blog" />
        </Helmet>

        <Box sx={{ my: 4 }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 900,
              color: theme.palette.neutral.dark,
              textAlign: "center",
              mb: 2,
            }}
          >
            ReadersGuild Blog
          </Typography>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              color: theme.palette.neutral.medium,
              textAlign: "center",
              mb: 4,
            }}
          >
            Discover insightful book reviews, reading tips, and literary
            discussions
          </Typography>
        </Box>

        {isAuthenticated && (
          <Box sx={{ mb: 4 }}>
            <Button
              component={Link}
              to="/blog/new"
              variant="contained"
              color="primary"
            >
              Create New Post
            </Button>
          </Box>
        )}

        <Grid container spacing={3}>
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post._id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {post.featuredImage && (
                  <Box sx={{ pt: "56.25%", position: "relative" }}>
                    <Box
                      component="img"
                      src={post.featuredImage}
                      alt={post.title}
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    sx={{ fontWeight: 700 }}
                  >
                    {post.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {post.excerpt}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    By {post.author?.name || "Unknown"} on{" "}
                    {new Date(post.publishDate).toLocaleDateString()}
                  </Typography>
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    component={Link}
                    to={`/blog/${post.slug}`}
                    variant="outlined"
                    size="small"
                    sx={{ color: theme.palette.neutral.mediumDark }}
                  >
                    Read More
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {totalPages > 1 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              size={isNonMobileScreens ? "medium" : "small"}
            />
          </Box>
        )}
      </Container>
    </LandingPageWrapper>
  );
};

export default BlogList;
