export const termsOfService = `
Last Updated: 08/08/2024

1. Acceptance of Terms

By accessing and using ReadersGuild (the "Service"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of the terms, you may not use our Service.

2. Description of Service

ReadersGuild is a book tracking and social reading platform that allows users to log their reading progress, join reading groups, and engage in book-related discussions.

3. User Accounts

3.1. You must create an account to use certain features of the Service. You are responsible for maintaining the confidentiality of your account and password.
3.2. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

4. User Content

4.1. You retain all rights to any content you submit, post or display on or through the Service.
4.2. By posting content, you grant ReadersGuild a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content for the purpose of providing and improving the Service.

5. Prohibited Activities

You agree not to engage in any of the following prohibited activities:
5.1. Violating laws or regulations
5.2. Infringing on intellectual property rights
5.3. Harassing or intimidating other users
5.4. Spreading malware or viruses
5.5. Attempting to gain unauthorized access to the Service

6. Modification of the Service

ReadersGuild reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part of it) with or without notice.

7. Termination

We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason whatsoever.

8. Disclaimer of Warranties

The Service is provided "as is" without any warranties, expressed or implied.

9. Limitation of Liability

ReadersGuild shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of the Service.

10. Indemnification

You agree to indemnify, defend, and hold harmless ReadersGuild, its officers, directors, employees, and agents, from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with your access to or use of the Service or your violation of these Terms.

11. Governing Law

These Terms shall be governed by and construed in accordance with the laws of [Your State/Country], without regard to its conflict of law provisions.

12. Dispute Resolution

Any disputes arising out of or relating to these Terms or the Service shall be resolved through binding arbitration or small claims court, as appropriate.

13. Changes to Terms

We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms periodically for changes.

14. Contact Us

If you have any questions about these Terms, please contact us at fabio@readers-guild.com.
`;
