import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { Box, useTheme, useMediaQuery } from "@mui/material";

// //green
// const customizePrimaryColors = [
//   "#25af56", // 500
//   "#0d8e40", // 700
// ];

//flockGreen
const customizePrimaryColors = [
  "#08956e", // 500
  "#007854", // 700
];

//orange
// const customizePrimaryColors = [
//   "#FF9800", // 500
//   "#F57C00", // 700
// ];
const interFont = "inter, helvetica, sans-serif";

const nivoTheme = {
  fontFamily: interFont,
  tooltip: {
    container: {
      interFont,
    },
  },
};

const LineChart = ({
  isGroup = false,
  lineData,
  isLegend = false,
  isRoom = false,
}) => {
  const theme = useTheme();
  const weekFirstDay = lineData[0]?.data[0]?.x;
  const weekLastDay = lineData[0]?.data[lineData[0]?.data.length - 1]?.x;
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  return (
    <Box
      sx={{
        width: "100%",
        height: isNonMobileScreens ? (isRoom ? "200px" : "400px") : "250px",
      }}
    >
      {" "}
      <ResponsiveLine
        data={lineData}
        tooltip={(lineData) => {
          return (
            <div>
              <p>
                {lineData.point.data.x}: <b> {lineData.point.data.y} pages</b>
              </p>
            </div>
          );
        }}
        margin={{ top: 10, right: 30, bottom: 20, left: 30 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: "0",
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        curve="linear"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 6,
          tickRotation: 0,
          fill: "white",
          tickValues: [weekFirstDay, weekLastDay],
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          legend: "# of pages",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={customizePrimaryColors}
        theme={{
          text: {
            fill:
              theme.palette.mode === "dark"
                ? theme.palette.neutral.dark
                : theme.palette.neutral.main,
          },
          tooltip: {
            container: {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral.mediumLight
                  : theme.palette.neutral.extraLight,
              fontSize: 12,
            },
          },
        }}
        enableGridX={false}
        enableGridY={false}
        pointSize={0}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabelYOffset={-12}
        enableArea={isGroup ? false : true}
        useMesh={true}
        legends={
          isLegend
            ? [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
            : []
        }
      />
    </Box>
  );
};

export default LineChart;
