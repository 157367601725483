import { useState } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
// import Typography from "@mui/material/Typography";
const AddRating = ({ onUpdateRating, initialValue }) => {
  const [value, setValue] = useState(initialValue);

  const handleRatingChange = (event, newValue) => {
    setValue(newValue);
    onUpdateRating(newValue); // Call the callback function to update the rating in the parent component
  };

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      {/* <Typography component="legend">Controlled</Typography> */}
      <Rating
        name="simple-controlled"
        value={value}
        precision={0.5}
        onChange={handleRatingChange}
      />
      {/* <Typography component="legend">Read only</Typography> */}
      {/* <Rating name="read-only" value={3} readOnly /> */}
      {/* <Typography component="legend">Disabled</Typography>
      <Rating name="disabled" value={value} disabled />
      <Typography component="legend">No rating given</Typography>
      <Rating name="no-value" value={null} /> */}
    </Box>
  );
};

export default AddRating;
