import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

// Get all published blog posts
export const getAllBlogPosts = async (page = 1, limit = 10) => {
  try {
    const response = await axios({
      url: `${BASE_URL}/blogs`,
      method: "GET",
      params: { page, limit },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching blog posts:", error);
    throw error;
  }
};

export const fetchLinkPreview = async (url) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/blogs/link-preview`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ url }),
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching link preview:", error);
    throw error;
  }
};

// Get a specific blog post by slug
export const getBlogPostBySlug = async (slug) => {
  try {
    const response = await axios({
      url: `${BASE_URL}/blogs/${slug}`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching blog post:", error);
    throw error;
  }
};
// Add this new function for image upload
export const uploadImage = async (formData) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/blogs/upload-image`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data: formData,
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
};

// Update these functions to handle content with image URLs
export const createBlogPost = async (postData) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/blogs`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(postData),
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error creating blog post:",
      error.response?.data || error.message
    );
    throw error.response?.data || error;
  }
};

export const updateBlogPost = async (postId, postData) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/blogs/${postId}`,
      method: "PUT",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: JSON.stringify(postData),
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error updating blog post:",
      error.response?.data || error.message
    );
    throw error.response?.data || error;
  }
};

// Delete a blog post
export const deleteBlogPost = async (postId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/blogs/${postId}`,
      method: "DELETE",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting blog post:",
      error.response?.data || error.message
    );
    throw error.response?.data || error;
  }
};
