import React from "react";
import { Box, Typography, Container, useTheme } from "@mui/material";
import { termsOfService } from "../../content/termsOfService";
import LandingPageWrapper from "../LandingPage/LandingPageWrapper";

const TermsOfService = () => {
  const theme = useTheme();

  return (
    <LandingPageWrapper>
      <Container maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            my: 4,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            sx={{
              fontWeight: 900,
              color: theme.palette.neutral.dark,
              textAlign: "center",
            }}
          >
            Terms of Service
          </Typography>
          <Box sx={{ maxWidth: "80%" }}>
            <Typography
              variant="body1"
              component="div"
              sx={{
                color: theme.palette.neutral.main,
                "& p": {
                  marginBottom: 2,
                },
              }}
            >
              {termsOfService.split("\n\n").map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </Typography>
          </Box>
        </Box>
      </Container>
    </LandingPageWrapper>
  );
};

export default TermsOfService;
