import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const postBookReview = async (comment, ratingValue, bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/reviews/` + bookId,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        comment,
        ratingValue,
        bookId,
      }),
    });
    return response.data;
  } catch (error) {
    console.error("Error creating new reading log:", error);
    throw error;
  }
};

export const getUserRatingOnBook = async (bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/reviews/userRating/` + bookId,
      method: "GET",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user's rating on book:", error);
    throw error;
  }
};

// export const getBookReviews = async (bookId) => {
//   try {
// const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
//     const response = await axios({
//       url: `${BASE_URL}/reviews/bookReviews/` + bookId,
//       method: "GET",
//       withCredentials: "true",
// headers: {
//         Authorization: `Bearer ${token}`,
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     console.error("Error fetching book reviews:", error);
//     throw error;
//   }
// };
