import {
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dots from "../../../components/Dots";
import { useNavigate } from "react-router-dom";

const OnBoarding3 = () => {
  const theme = useTheme();
  const [userInterests, setUserInterests] = useState([]);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:600px)");

  const interestOptions = [
    { value: "bookTracking", label: "📚 Track my reading progress" },
    { value: "bookAnalytics", label: "📊 Get insights on my reading habits" },
    { value: "bookDiscussion", label: "💬 Join book discussions and clubs" },
    {
      value: "bookCommunity",
      label: "🌍 Connect with a global reading community",
    },
    {
      value: "currentlyReading",
      label: "🎵 See what others are currently reading",
    },
    { value: "bookJournaling", label: "✍️ Keep a book journal" },
    { value: "findBuddies", label: "👥 Find new reading buddies" },
  ];

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setUserInterests((prev) =>
      prev.includes(value)
        ? prev.filter((interest) => interest !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    if (onBoardingData.userInterests) {
      setUserInterests(onBoardingData.userInterests);
    }
  }, []);

  const handleNext = () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    onBoardingData.userInterests = userInterests;
    localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    navigate("/signup/onboarding-step4");
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isExtraSmallScreen
          ? "90vh"
          : isNonMobileScreens
          ? "95vh"
          : "95vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: theme.palette.neutral.main }}
          >
            What are you most excited about?
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {interestOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                control={
                  <Checkbox
                    checked={userInterests.includes(option.value)}
                    onChange={handleCheckboxChange}
                    value={option.value}
                  />
                }
                label={option.label}
                sx={{
                  border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  m: 0,
                }}
              />
            ))}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Next
            </Button>

            <Button
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Skip
            </Button>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots navigateTo="/signup/onboarding-step1" />
            <Dots navigateTo="/signup/onboarding-step2" />
            <Dots filled />
            <Dots navigateTo="/signup/onboarding-step4" />
            <Dots navigateTo="/signup/onboarding-step5" />
            <Dots navigateTo="/signup/onboarding-step6" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding3;
