import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Helmet } from "react-helmet";
import {
  createBlogPost,
  updateBlogPost,
  getBlogPostBySlug,
  uploadImage,
  fetchLinkPreview,
} from "../../api/blogApi";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Paper,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import LandingPageWrapper from "../LandingPage/LandingPageWrapper";
import parse from "html-react-parser";
import DOMPurify from "dompurify";
import "./CustomQuill.css";

// Custom Blot for Link Preview
const LinkPreviewBlot = Quill.import("blots/block/embed");

class LinkPreview extends LinkPreviewBlot {
  static create(value) {
    let node = super.create();
    node.setAttribute("contenteditable", false);
    node.setAttribute("data-url", value.url);
    node.setAttribute("data-title", value.title);
    node.setAttribute("data-description", value.description);
    node.setAttribute("data-image", value.image || "");

    node.innerHTML = `
      <div class="link-preview-content">
        <div class="link-preview-text">
          <h4 class="link-preview-title">${value.title}</h4>
          <p class="link-preview-description">${value.description}</p>
          <span class="link-preview-domain">${
            new URL(value.url).hostname
          }</span>
        </div>
        ${
          value.image
            ? `
          <div class="link-preview-image-container">
            <img src="${value.image}" alt="${value.title}" class="link-preview-image" />
          </div>
        `
            : ""
        }
      </div>
    `;

    return node;
  }

  static value(node) {
    return {
      url: node.getAttribute("data-url"),
      title: node.getAttribute("data-title"),
      description: node.getAttribute("data-description"),
      image: node.getAttribute("data-image"),
    };
  }
}

LinkPreview.blotName = "link-preview";
LinkPreview.tagName = "div";
LinkPreview.className = "link-preview";

Quill.register(LinkPreview);

// Custom component for rendering link previews in the preview mode
const LinkPreviewComponent = ({ url, title, description, image }) => (
  <div className="link-preview">
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="link-preview-content"
    >
      <div className="link-preview-text">
        <h4 className="link-preview-title">{title}</h4>
        <p className="link-preview-description">{description}</p>
        <span className="link-preview-domain">{new URL(url).hostname}</span>
      </div>
      {image && (
        <div className="link-preview-image-container">
          <img src={image} alt={title} className="link-preview-image" />
        </div>
      )}
    </a>
  </div>
);

const BlogEditor = ({ isAuthenticated }) => {
  const [post, setPost] = useState({
    title: "",
    content: "",
    tags: "",
    excerpt: "",
  });
  const [error, setError] = useState(null);
  const [isPreview, setIsPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const quillRef = useRef();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
      return;
    }

    if (slug) {
      const fetchPost = async () => {
        try {
          setLoading(true);
          const data = await getBlogPostBySlug(slug);
          setPost({
            ...data,
            tags: data.tags.join(", "),
          });
        } catch (err) {
          setError("Failed to fetch blog post for editing. Please try again.");
        } finally {
          setLoading(false);
        }
      };
      fetchPost();
    }
  }, [slug, isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const postData = {
        ...post,
        tags: post.tags.split(",").map((tag) => tag.trim()),
      };
      if (slug) {
        const result = await updateBlogPost(post._id, postData);
      } else {
        await createBlogPost(postData);
      }
      navigate("/blog");
    } catch (err) {
      setError("Failed to save blog post. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPost((prevPost) => ({ ...prevPost, [name]: value }));
  };

  const imageHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("image", file);

      const quill = quillRef.current.getEditor();
      const range = quill.getSelection(true);

      const placeholder = `[Uploading image...]`;
      quill.insertText(range.index, placeholder, "user");

      const placeholderIndex = range.index;

      try {
        setImageUploading(true);
        const result = await uploadImage(formData);

        if (result && result.imageUrl) {
          quill.deleteText(placeholderIndex, placeholder.length);
          quill.insertEmbed(placeholderIndex, "image", result.imageUrl);
          quill.setSelection(placeholderIndex + 1);
        } else {
          throw new Error("Image upload failed: No image URL received");
        }

        setPost((prevPost) => ({
          ...prevPost,
          content: quill.root.innerHTML,
        }));
      } catch (error) {
        console.error("Error uploading image:", error);
        setError("Failed to upload image. Please try again.");
        quill.deleteText(placeholderIndex, placeholder.length);
      } finally {
        setImageUploading(false);
      }
    };
  }, [quillRef, setPost, setError]);

  const linkHandler = useCallback(() => {
    const quill = quillRef.current.getEditor();
    const range = quill.getSelection();
    if (range) {
      const url = prompt("Enter the URL:");
      if (url) {
        fetchLinkPreview(url)
          .then((preview) => {
            if (preview) {
              const delta = quill
                .getContents()
                .insert({ "link-preview": preview });
              quill.setContents(delta);
            } else {
              quill.format("link", url);
            }
          })
          .catch((error) => {
            console.error("Error fetching link preview:", error);
            quill.format("link", url);
          });
      }
    }
  }, [quillRef]);

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
      ],
      handlers: {
        image: imageHandler,
        link: linkHandler,
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "link-preview",
  ];

  const renderContent = (content) => {
    const cleanHtml = DOMPurify.sanitize(content);
    return parse(cleanHtml, {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "link-preview") {
          const {
            "data-url": url,
            "data-title": title,
            "data-description": description,
            "data-image": image,
          } = domNode.attribs;
          return (
            <LinkPreviewComponent
              url={url}
              title={title}
              description={description}
              image={image}
            />
          );
        }
      },
    });
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );

  return (
    <LandingPageWrapper>
      <Helmet>
        <title>
          {slug ? "Edit Blog Post" : "Create New Blog Post"} | ReadersGuild
        </title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ mt: 4, mb: 4, p: 4 }}>
          <Typography
            variant="h4"
            component="h3"
            gutterBottom
            sx={{ mb: 2, fontWeight: 700, color: theme.palette.neutral.dark }}
          >
            {slug ? "Edit Blog Post" : "Create New Blog Post"}
          </Typography>
          {isPreview ? (
            <Box>
              <Typography
                variant="h2"
                gutterBottom
                sx={{ fontWeight: 700, color: theme.palette.neutral.dark }}
              >
                {post.title}
              </Typography>
              <Typography variant="body1" paragraph>
                {post.excerpt}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Box
                className="preview-content"
                sx={{
                  "& > *": { marginBottom: theme.spacing(2) },
                  "& img": { maxWidth: "100%", height: "auto" },
                }}
              >
                {renderContent(post.content)}
              </Box>
              <Button
                variant="contained"
                onClick={() => setIsPreview(false)}
                sx={{
                  mt: 2,
                  backgroundColor: theme.palette.neutral.mediumDark,
                }}
              >
                Back to Editing
              </Button>
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              {error && (
                <Typography color="error" paragraph>
                  {error}
                </Typography>
              )}
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={post.title}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Excerpt (for SEO)"
                name="excerpt"
                value={post.excerpt}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
                multiline
                rows={3}
              />
              <Box sx={{ my: 2, position: "relative" }}>
                <ReactQuill
                  ref={quillRef}
                  value={post.content}
                  onChange={(content) =>
                    setPost((prevPost) => ({ ...prevPost, content }))
                  }
                  modules={modules}
                  formats={formats}
                  style={{ height: "300px", marginBottom: "50px" }}
                />
                {imageUploading && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(255, 255, 255, 0.7)",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </Box>
              <TextField
                fullWidth
                label="Tags (comma-separated)"
                name="tags"
                value={post.tags}
                onChange={handleInputChange}
                margin="normal"
                variant="outlined"
              />
              <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: theme.palette.neutral.mediumDark }}
                >
                  {slug ? "Update" : "Publish"}
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => setIsPreview(true)}
                  sx={{
                    color: theme.palette.neutral.mediumDark,
                    borderColor: theme.palette.neutral.mediumDark,
                  }}
                >
                  Preview
                </Button>
              </Box>
            </form>
          )}
        </Paper>
      </Container>
    </LandingPageWrapper>
  );
};

export default BlogEditor;
