import * as React from "react";
import { styled } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  flexGrow: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
}));

export default function CustomizedProgressBars({ percentage = 10 }) {
  const isNonSuperSmallScreen = useMediaQuery("(min-width: 450px)");
  return (
    <>
      {isNonSuperSmallScreen ? (
        <Box
          sx={{
            flexGrow: 2,
            width: "85px",
            minWidth: "50px",
          }}
        >
          <BorderLinearProgress variant="determinate" value={percentage} />
        </Box>
      ) : (
        <BorderLinearProgress variant="determinate" value={percentage} />
      )}
    </>
  );
}
