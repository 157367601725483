export const GenreData = [
  {
    id: "Fictioeeen",
    label: "Fiction",
    value: 300,
    // color: "hsl(90, 70%, 50%)",
  },
  {
    id: "Mysteewfry",
    label: "Mystery",
    value: 150,
    // color: "hsl(56, 70%, 50%)",
  },
  {
    id: "Fantasey",
    label: "Fantasy",
    value: 200,
    // color: "hsl(103, 70%, 50%)",
  },
  {
    id: "Sci-Fi",
    label: "Science Fiction",
    value: 120,
    // color: "hsl(186, 70%, 50%)",
  },
  {
    id: "Non Fiction",
    label: "Non-Fiction",
    value: 250,
    // color: "hsl(104, 70%, 50%)",
  },
];

export const personalBarData = [
  {
    day: "Monday",
    pages: 81,
    pagesColor: "hsl(82, 70%, 50%)",
  },

  {
    day: "Tuesday",
    pages: 20,
    pagesColor: "hsl(82, 70%, 50%)",
  },

  {
    day: "Wednesday",
    pages: 31,
    pagesColor: "hsl(82, 70%, 50%)",
  },

  {
    day: "Thursday",
    pages: 42,
    pagesColor: "hsl(82, 70%, 50%)",
  },

  {
    day: "Friday",
    pages: 180,
    pagesColor: "hsl(82, 70%, 50%)",
  },

  {
    day: "Saturday",
    pages: 10,
    pagesColor: "hsl(82, 70%, 50%)",
  },
  {
    day: "Sunday",
    pages: 100,
    pagesColor: "hsl(82, 70%, 50%)",
  },
];

export const groupLineDataWeek = [
  {
    id: "Fabio",
    color: "hsl(26, 70%, 50%)",
    data: [
      { x: "Monday", y: 68 },
      { x: "Tuesday", y: 45 },
      { x: "Wednesday", y: 82 },
      { x: "Thursday", y: 1 },
      { x: "Friday", y: 70 },
      { x: "Saturday", y: 55 },
      { x: "Sunday", y: 20 },
    ],
  },
  {
    id: "Vero",
    color: "hsl(180, 70%, 50%)",
    data: [
      { x: "Monday", y: 30 },
      { x: "Tuesday", y: 75 },
      { x: "Wednesday", y: 42 },
      { x: "Thursday", y: 60 },
      { x: "Friday", y: 28 },
      { x: "Saturday", y: 50 },
      { x: "Sunday", y: 65 },
    ],
  },
  {
    id: "Kevin",
    color: "hsl(300, 70%, 50%)",
    data: [
      { x: "Monday", y: 85 },
      { x: "Tuesday", y: 20 },
      { x: "Wednesday", y: 55 },
      { x: "Thursday", y: 45 },
      { x: "Friday", y: 75 },
      { x: "Saturday", y: 35 },
      { x: "Sunday", y: 90 },
    ],
  },
  {
    id: "Amel",
    color: "hsl(120, 70%, 50%)",
    data: [
      { x: "Monday", y: 50 },
      { x: "Tuesday", y: 70 },
      { x: "Wednesday", y: 38 },
      { x: "Thursday", y: 25 },
      { x: "Friday", y: 65 },
      { x: "Saturday", y: 18 },
      { x: "Sunday", y: 80 },
    ],
  },
  {
    id: "Jocelin",
    color: "hsl(240, 70%, 50%)",
    data: [
      { x: "Monday", y: 48 },
      { x: "Tuesday", y: 88 },
      { x: "Wednesday", y: 20 },
      { x: "Thursday", y: 42 },
      { x: "Friday", y: 76 },
      { x: "Saturday", y: 60 },
      { x: "Sunday", y: 32 },
    ],
  },
];

export const personalLineDataWeek = [
  {
    id: "Vero",
    data: [
      { x: "Monday", y: 30 },
      { x: "Tuesday", y: 75 },
      { x: "Wednesday", y: 42 },
      { x: "Thursday", y: 60 },
      { x: "Friday", y: 28 },
      { x: "Saturday", y: 50 },
      { x: "Sunday", y: 65 },
    ],
  },
];

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomDataArray(length) {
  const dataArray = [];
  for (let i = 0; i < length; i++) {
    dataArray.push({ x: `${i + 1}`, y: getRandomInt(20, 100) });
  }
  return dataArray;
}

export const groupLineDataMonth = [
  {
    id: "Fabio",
    color: "hsl(26, 70%, 50%)",
    data: generateRandomDataArray(30), // Assuming 30 days in a month
  },
  {
    id: "Vero",
    color: "hsl(180, 70%, 50%)",
    data: generateRandomDataArray(30),
  },
  {
    id: "Kevin",
    color: "hsl(300, 70%, 50%)",
    data: generateRandomDataArray(30),
  },
  {
    id: "Amel",
    color: "hsl(120, 70%, 50%)",
    data: generateRandomDataArray(30),
  },
  {
    id: "Jocelin",
    color: "hsl(240, 70%, 50%)",
    data: generateRandomDataArray(30),
  },
];

export const personalLineDataMonth = [
  {
    id: "Fabio",
    color: "hsl(26, 70%, 50%)",
    data: generateRandomDataArray(30), // Assuming 30 days in a month
  },
];
