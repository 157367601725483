import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Typography,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DialogSeeStats = ({
  open,
  onClose,
  logDate,
  privateSwitch,
  roomTag,
  lastPageRead,
  startPageRead,
  amountPageRead,
  bookTotalPage,
  isDiscussion,
}) => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={!isNonMobileScreens}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.alt,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.background.alt }}>
        <Typography variant="h6" color="neutral" sx={{ fontWeight: "400" }}>
          Session Stats
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.neutral.main,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.background.alt }}>
        <Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Log Date:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {logDate || "14 Nov 2023"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Private/Public:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {privateSwitch ? "Private" : "Public"}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Current Session Page:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {lastPageRead}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Current Session Page:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {startPageRead}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Total Session Page Read:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {amountPageRead < 0 ? 0 : amountPageRead}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Book Total Page:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {bookTotalPage}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: 1.5 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              Total Session Page Read:
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.neutral.medium,
                fontWeight: 500,
              }}
              gutterBottom
            >
              {amountPageRead < 0 ? 0 : amountPageRead}
            </Typography>
          </Box>
          {isDiscussion && (
            <Chip
              size="small"
              label={`Guild Discussion ${roomTag}`}
              color="primary"
            />
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogSeeStats;
