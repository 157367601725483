import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  CircularProgress,
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { verifyMagicLinkToken } from "../../../api/authApi";
import { useAuth } from "../../../context/AuthContext";
import { useLoading } from "../../../context/LoadingContext";
import { getUserProfileData } from "../../../api/userApi";
import { UserContext } from "../../../context/UserContext";

const Verify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { checkLogin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const { setIsLoading } = useLoading();
  const { updateUserProfile } = useContext(UserContext);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const verifyAccount = async () => {
      const params = new URLSearchParams(location.search);
      const verificationCode = params.get("token");

      if (!verificationCode) {
        if (isMounted) {
          setError("Verification code not found");
          setLoading(false);
        }
        return;
      }

      try {
        setIsLoading(true);
        const result = await verifyMagicLinkToken(verificationCode);

        if (isMounted) {
          if (result.data.success) {
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${result.token}`;
            await fetchUserData();
            localStorage.setItem("showMessage", "login");
            checkLogin();
            navigate("/home");
          } else {
            setError(
              "We couldn't verify your account. Please try signing up again."
            );
            setOpenDialog(true);
          }
        }
      } catch (err) {
        console.error("Verification Error:", err);
        if (isMounted) {
          setError(
            "We couldn't verify your account. The verification link may have expired."
          );
          setOpenDialog(true);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          setIsLoading(false);
        }
      }
    };

    verifyAccount();

    return () => {
      isMounted = false;
    };
  }, [location, navigate, checkLogin, setIsLoading]);

  const fetchUserData = async () => {
    try {
      const result = await getUserProfileData();
      updateUserProfile(result.data);
      localStorage.setItem("userData", JSON.stringify(result.data));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleSignUp = () => {
    navigate("/signup");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle>Account Verification Failed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {error ||
            "We couldn't verify your account. The verification link may have expired. Please try signing up again."}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleSignUp} color="primary" variant="contained">
          Sign Up Again
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Verify;
