import React from "react";
import { Typography } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import PercentIcon from "@mui/icons-material/Percent";

const ToggleTwoChoices = ({ toggle1, toggle2, value, onAlignmentChange }) => {
  const handleChange = (event, newAlignment) => {
    if (onAlignmentChange) {
      onAlignmentChange(newAlignment);
    }
  };

  const children = [
    <ToggleButton value={toggle1} key={toggle1} sx={{ height: 32 }}>
      <Typography fontWeight="bold" variant="body3">
        {toggle1}
      </Typography>
    </ToggleButton>,
    <ToggleButton value={toggle2} key={toggle2} sx={{ height: 32 }}>
      <Typography fontWeight="bold" variant="body3">
        {toggle2}
      </Typography>
    </ToggleButton>,
  ];

  const control = {
    value: value,
    onChange: handleChange,
    exclusive: true,
  };

  return (
    <ToggleButtonGroup size="small" {...control} aria-label="Small sizes">
      {children}
    </ToggleButtonGroup>
  );
};

export default ToggleTwoChoices;
