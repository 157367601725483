import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";
import BackButton from "./BackButton";

const StyledPageFrame = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const PageFrame = ({ title, children }) => {
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  return (
    <StyledPageFrame>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          alignItems: "center",
          mb: isNonMobileScreens ? 1 : 0,
        }}
      >
        <BackButton />
        <Typography variant="h5">{title}</Typography>
      </Box>
      {children}
    </StyledPageFrame>
  );
};

export default PageFrame;
