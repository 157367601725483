import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material";
import DateInput from "../../components/DateInput";
import { useParams } from "react-router-dom";
import CardContentPadding from "../../components/CardContentPadding";
import TimerIcon from "@mui/icons-material/Timer";
import { postFinishBookGoalDate } from "../../api/userApi";
import LinearProgressBar from "../../components/LinearProgressBar";
import dayjs from "dayjs";

const EditBookFinishDate = ({
  open,
  onClose,
  initialValue,
  onUpdateGoalDate,
}) => {
  const theme = useTheme();
  const [editedGoal, setEditedGoal] = useState(dayjs().toISOString());
  const { bookId } = useParams();

  const handleYearTargetChange = (e) => {
    const newDate = dayjs(e).toISOString();
    setEditedGoal(newDate);
  };
  const handleSaveGoal = async () => {
    const result = await postFinishBookGoalDate(editedGoal, bookId);
    onUpdateGoalDate(result.data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Book Finish Goal</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          gutterBottom
          sx={{ color: theme.palette.neutral.main, fontWeight: 500 }}
        >
          Current goal: {initialValue}
        </DialogContentText>
        <DialogContentText>
          When would you like to finish your book?
        </DialogContentText>
        <FormControl fullWidth>
          <DateInput
            name="date"
            value={editedGoal}
            onChange={(e) => handleYearTargetChange(e)}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            name="yearlyGoal"
            label="Yearly Goal"
            type="number"
            fullWidth
            variant="standard"
            value={editedGoal}
            onChange={(e) => handleYearTargetChange(e)}
          /> */}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveGoal} color="primary" disabled={!editedGoal}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BookFinishGoalWidget = ({ bookFinishGoalDate, bookProgressNum }) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [goalDate, setGoalDate] = useState(null);
  // const [goalDate, setGoalDate] = useState({});
  const [bookProgress, setBookProgress] = useState();
  const theme = useTheme();

  useEffect(() => {
    setBookProgress(bookProgressNum);
    calculateDays(bookFinishGoalDate);
  }, [bookFinishGoalDate, bookProgressNum]);
  // useEffect(() => {
  //   setBookProgress(bookProgressNum);
  //   calculateDays(bookFinishGoalDate);
  // }, [bookProgressNum]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleGoalUpdate = (updatedGoal) => {
    //callback to calculate finish goal
    calculateDays(updatedGoal);
    setBookProgress(bookProgressNum);
  };

  const calculateDays = (value) => {
    if (value) {
      const endDate = dayjs(value);
      const today = dayjs();
      const daysLeft = endDate.diff(today, "day");
      const formattedDate = dayjs(value).format("MMMM DD, YYYY");

      setGoalDate({ daysLeft, formattedDate });
    } else {
      setGoalDate(null);
    }
  };

  return (
    <>
      <Card>
        <CardContentPadding>
          {!goalDate ? (
            <Typography
              onClick={handleOpenDialog}
              sx={{
                cursor: "pointer",
                color: theme.palette.neutral.extraDark,
                transition: "color 0.3",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
              variant="body3"
            >
              Add reading deadline for this book
            </Typography>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              {" "}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <TimerIcon fontSize="large" color="primary" />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {bookProgress >= 100 ? (
                    <Typography
                      color="primary"
                      variant="h6"
                      sx={{ fontWeight: "600" }}
                    >
                      Finished
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="h6" sx={{ fontWeight: "600" }}>
                        Days left:{" "}
                        <span
                          style={{
                            color:
                              goalDate.daysLeft < 7
                                ? theme.palette.error.main
                                : "inherit",
                          }}
                        >
                          {goalDate.daysLeft} days
                        </span>
                      </Typography>
                    </>
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.neutral.medium,
                      fontStyle: "italic",
                    }}
                  >
                    Finish Goal: {goalDate.formattedDate}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: "600" }}>
                  Progress
                </Typography>
                <Box>
                  {!(bookProgress >= 100) ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        minWidth: "120px",
                        gap: 1,
                      }}
                    >
                      <LinearProgressBar percentage={bookProgress} />
                      <Typography>{bookProgress}%</Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        minWidth: "120px",
                      }}
                    >
                      <Typography
                        //renewed
                        variant="h6"
                        color="primary"
                        sx={{ fontWeight: 600 }}
                      >
                        FINISHED
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <EditBookFinishDate
            open={isDialogOpen}
            onClose={handleCloseDialog}
            initialValue={bookFinishGoalDate}
            onUpdateGoalDate={handleGoalUpdate}
          />
        </CardContentPadding>
      </Card>
    </>
  );
};

export default BookFinishGoalWidget;
