import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CustomQuillEditor from "../components/CustomQuillEditor";
import CloseIcon from "@mui/icons-material/Close";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../customQuillStyles.css";

const DialogEditNote = ({
  open,
  onClose,
  onSave,
  switchData,
  initialNote,
  bookTitle,
  logId,
  logDate,
  logData,
}) => {
  const [editedNote, setEditedNote] = useState(initialNote);
  const theme = useTheme();
  const [privateSwitch, setPrivateSwitch] = useState(switchData);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    if (open) {
      const savedDraft = localStorage.getItem(`editDraft:${logId}`);
      if (savedDraft) {
        const parsedDraft = JSON.parse(savedDraft);
        setEditedNote(parsedDraft.editedNote || initialNote);
        setPrivateSwitch(parsedDraft.privateSwitch || switchData);
      }
    } else {
      setEditedNote(initialNote);
      setPrivateSwitch(switchData);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      const draft = {
        editedNote,
        privateSwitch,
      };
      localStorage.setItem(`editDraft:${logId}`, JSON.stringify(draft));
    }
  }, [editedNote, privateSwitch]);

  const handleSave = () => {
    onSave(editedNote, privateSwitch, logId);
    localStorage.removeItem(`editDraft:${logId}`);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      fullScreen={!isNonMobileScreens}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.alt,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: theme.palette.background.alt }}>
        <Typography variant="h6" color="neutral" sx={{ fontWeight: "400" }}>
          Edit Note for {bookTitle}
        </Typography>
        {!isNonMobileScreens && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.neutral.main,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: theme.palette.background.alt }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "400" }}>
            Public
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={privateSwitch}
                onChange={(e) => setPrivateSwitch(e.target.checked)}
                name="privateSwitch"
              />
            }
            label="Private"
          />
        </Box>
        <Box
          sx={{
            // mt: 2,
            height: isNonMobileScreens ? "320px" : "calc(100vh - 50px)",
          }}
        >
          <CustomQuillEditor
            value={editedNote}
            onChange={setEditedNote}
            placeholder="Enter your reading notes..."
          />
          {/* <ReactQuill
            value={editedNote}
            onChange={setEditedNote}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                ["clean"],
              ],
              // clipboard: {
              //   matchVisual: false,
              // },
            }}
            style={{
              height: isNonMobileScreens ? "250px" : "calc(100% - 200px)",
              marginBottom: "20px",
            }}
          /> */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: theme.palette.background.alt }}>
        {isNonMobileScreens && (
          <Button onClick={onClose} color="neutral">
            Cancel
          </Button>
        )}
        <Button onClick={handleSave} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEditNote;
