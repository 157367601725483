import { useState, useContext } from "react";
import {
  createNewRoom,
  joinRoom,
  removeRoom,
  updateRoomBook,
  addRoomDiscussion,
  editRoomDiscussion,
  removeDiscussion,
  likeDiscussion,
  removeMemberFromRoom,
} from "../api/roomApi";
import { UserContext } from "../context/UserContext";
import { useSubscription } from "./useSubscription";

export const useRoomActions = (
  rooms,
  setRooms,
  selectedRoom,
  setSelectedRoom,
  userProfile,
  setSnackbar,
  onOpenUpgradeDialog,
  loadingRooms,
  setLoadingRooms
) => {
  const [dialogStates, setDialogStates] = useState({
    createRoom: false,
    joinRoom: false,
    removeRoom: false,
    postDiscussion: false,
    editDiscussion: false,
    removeDiscussion: false,
    removeMember: false,
    editBook: false,
    currentDiscussion: null,
    memberToRemove: null,
    selectedBook: null,
  });
  const { canCreateOrJoinGroup, getCurrentPlan, getGuildLimit } =
    useSubscription();
  const [loading, setLoading] = useState(false);
  const { updateUserProfile } = useContext(UserContext);

  const handleError = (error, customMessage) => {
    console.error(customMessage, error);
    setSnackbar({
      open: true,
      message: error.response?.data?.message || customMessage,
      severity: "error",
    });
    setLoading(false);
  };

  const handleCreateRoom = async (roomData) => {
    if (!canCreateOrJoinGroup()) {
      onOpenUpgradeDialog();
      return;
    }

    // Create a temporary room object for optimistic update
    const tempRoom = {
      _id: Date.now().toString(), // Temporary ID
      name: roomData.name,
      description: roomData.description || "",
      about: roomData.about || "",
      creator: userProfile,
      members: [userProfile], // Add current user as a member
      currentBook: roomData.currentBook
        ? {
            _id: roomData.currentBook,
            title: roomData.currentBookTitle || "Loading...",
            authors: roomData.currentBookAuthors || ["Loading..."],
            pages: roomData.currentBookPages || 0,
            genre: roomData.currentBookGenre || [],
            language: roomData.currentBookLanguage || "Unknown",
            cover: roomData.currentBookCover || {},
          }
        : null,
      roomTag: roomData.roomTag || "temporary-tag",
      isPublic: roomData.isPublic || false,
      maxMembers: roomData.maxMembers || 10,
      discussions: [],
      totalPagesRead: 0,
      totalDiscussions: 0,
      totalBooksRead: 0,
      createdAt: new Date().toISOString(),
      lastActivityDate: new Date().toISOString(),
      profileImage:
        roomData.profileImage instanceof File
          ? URL.createObjectURL(roomData.profileImage)
          : roomData.profileImage,
    };

    // Optimistically update the local state
    setRooms((prevRooms) => [...prevRooms, tempRoom]);
    setDialogStates((prev) => ({ ...prev, createRoom: false }));

    // Set the newly created room as the selected room
    setSelectedRoom(tempRoom._id);

    // Show loading state for the new room
    setLoadingRooms((prev) => ({ ...prev, [tempRoom._id]: true }));

    try {
      const result = await createNewRoom(roomData);
      if (result.success) {
        // Update the temporary room with the actual data from the server
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === tempRoom._id ? result.room : room
          )
        );
        // Update loading state for the new room
        setLoadingRooms((prev) => ({ ...prev, [result.room._id]: false }));

        // Update the user profile
        updateUserProfile({
          ...userProfile,
          rooms: [...userProfile.rooms, result.room._id],
        });

        // Ensure the selected room is set to the new room's actual ID
        setSelectedRoom(result.room._id);

        setSnackbar({
          open: true,
          message: "Room created successfully!",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to create room");
      }
    } catch (error) {
      handleError(error, "Failed to create room");
      // Remove the temporary room if there's an error
      setRooms((prevRooms) =>
        prevRooms.filter((room) => room._id !== tempRoom._id)
      );
      // Reset selected room if creation fails
      setSelectedRoom("");
    } finally {
      // Ensure loading state is cleared for the temporary room ID
      setLoadingRooms((prev) => {
        const newLoadingRooms = { ...prev };
        delete newLoadingRooms[tempRoom._id];
        return newLoadingRooms;
      });

      // Revoke the object URL if it was created
      if (tempRoom.profileImage && tempRoom.profileImage.startsWith("blob:")) {
        URL.revokeObjectURL(tempRoom.profileImage);
      }
    }
  };

  const handleJoinRoom = async (roomTag, roomPassword) => {
    if (!canCreateOrJoinGroup()) {
      onOpenUpgradeDialog();
      return;
    }
    setLoading(true);
    try {
      const result = await joinRoom(roomTag, roomPassword);
      if (result.success) {
        setRooms((prevRooms) => [...prevRooms, result.room]);

        updateUserProfile({
          ...userProfile,
          rooms: [...userProfile.rooms, result.room._id],
        });

        setDialogStates((prev) => ({ ...prev, joinRoom: false }));
        setSnackbar({
          open: true,
          message: "Joined room successfully!",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to join room");
      }
    } catch (error) {
      handleError(error, "Failed to join room");
    } finally {
      setLoading(false);
    }
  };
  const handleRemoveRoom = async () => {
    setLoading(true);
    const roomToRemove = selectedRoom;

    // Optimistically update the UI
    const updatedRooms = rooms.filter((room) => room._id !== roomToRemove);
    setRooms(updatedRooms);

    // Select a new room if available, otherwise set to empty string
    const newSelectedRoom = updatedRooms.length > 0 ? updatedRooms[0]._id : "";
    setSelectedRoom(newSelectedRoom);

    try {
      const result = await removeRoom(roomToRemove);
      if (result.success) {
        // Update the user profile
        updateUserProfile({
          ...userProfile,
          rooms: userProfile.rooms.filter((room) => room !== roomToRemove),
        });

        setDialogStates((prev) => ({ ...prev, removeRoom: false }));
        setSnackbar({
          open: true,
          message: "Room removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove room");
      }
    } catch (error) {
      // Revert the optimistic updates if there's an error
      setRooms(rooms);
      setSelectedRoom(roomToRemove);
      handleError(error, "Failed to remove room");
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchBook = async (newBook) => {
    setLoading(true);
    try {
      const result = await updateRoomBook(selectedRoom, newBook._id);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? { ...room, currentBook: newBook, discussions: [] }
              : room
          )
        );
        setDialogStates((prev) => ({ ...prev, editBook: false }));
        setSnackbar({
          open: true,
          message: "Book switched successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to switch book");
      }
    } catch (error) {
      handleError(error, "Failed to switch book");
    } finally {
      setLoading(false);
    }
  };

  const handlePostDiscussion = async (discussionData) => {
    setLoading(true);
    try {
      const result = await addRoomDiscussion(selectedRoom, discussionData);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: [result.readingLog, ...room.discussions],
                }
              : room
          )
        );

        setDialogStates((prev) => ({ ...prev, postDiscussion: false }));
        setSnackbar({
          open: true,
          message: "Discussion posted successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to post discussion");
      }
    } catch (error) {
      handleError(error, "Failed to post discussion");
    } finally {
      setLoading(false);
    }
  };

  const handleEditDiscussion = async (discussionId, updatedNote) => {
    setLoading(true);
    try {
      const result = await editRoomDiscussion(
        selectedRoom,
        discussionId,
        updatedNote
      );
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.map((d) =>
                    d._id === discussionId
                      ? { ...d, readingNotes: updatedNote }
                      : d
                  ),
                }
              : room
          )
        );
        setDialogStates((prev) => ({
          ...prev,
          editDiscussion: false,
          currentDiscussion: null,
        }));
        setSnackbar({
          open: true,
          message: "Discussion updated successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to edit discussion");
      }
    } catch (error) {
      handleError(error, "Failed to edit discussion");
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveDiscussion = async (discussion) => {
    setLoading(true);
    try {
      const result = await removeDiscussion(selectedRoom, discussion._id);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.filter(
                    (d) => d._id !== discussion._id
                  ),
                }
              : room
          )
        );
        setDialogStates((prev) => ({
          ...prev,
          removeDiscussion: false,
          currentDiscussion: null,
        }));
        setSnackbar({
          open: true,
          message: "Discussion removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove discussion");
      }
    } catch (error) {
      handleError(error, "Failed to remove discussion");
    } finally {
      setLoading(false);
    }
  };

  const handleLikeDiscussion = async (discussionId) => {
    try {
      const result = await likeDiscussion(selectedRoom, discussionId);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.map((d) =>
                    d._id === discussionId
                      ? { ...d, likes: result.updatedLikes }
                      : d
                  ),
                }
              : room
          )
        );
      } else {
        throw new Error(result.message || "Failed to like discussion");
      }
    } catch (error) {
      handleError(error, "Failed to like discussion");
    }
  };

  const handleRemoveMember = async (member) => {
    setLoading(true);
    try {
      const result = await removeMemberFromRoom(selectedRoom, member._id);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  members: room.members.filter((m) => m._id !== member._id),
                }
              : room
          )
        );
        setDialogStates((prev) => ({
          ...prev,
          removeMember: false,
          memberToRemove: null,
        }));
        setSnackbar({
          open: true,
          message: "Member removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove member");
      }
    } catch (error) {
      handleError(error, "Failed to remove member");
    } finally {
      setLoading(false);
    }
  };

  return {
    handleCreateRoom,
    handleJoinRoom,
    handleRemoveRoom,
    handleSwitchBook,
    handlePostDiscussion,
    handleEditDiscussion,
    handleRemoveDiscussion,
    handleLikeDiscussion,
    handleRemoveMember,
    dialogStates,
    setDialogStates,
    loading,
    canCreateOrJoinGroup,
  };
};
