import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const UpgradeDialog = ({
  open,
  onClose,
  title,
  message,
  currentPlan,
  limit,
  featureName,
}) => {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    onClose();
    navigate("/pricing"); // Adjust this path as needed
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="upgrade-dialog-title"
      aria-describedby="upgrade-dialog-description"
    >
      <DialogTitle id="upgrade-dialog-title">
        {title || "Upgrade Your Plan"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="upgrade-dialog-description">
          {message ||
            `You've reached the limit of ${limit} ${featureName} for your ${currentPlan} plan. 
          Upgrade to access more ${featureName}!`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleUpgrade} color="primary" variant="contained">
          Upgrade Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpgradeDialog;
