import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Autocomplete,
  Chip,
  useMediaQuery,
  Typography,
} from "@mui/material";

const EditBookDataDialog = ({ open, onClose, book, onSave }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editedBook, setEditedBook] = useState({
    title: book.title || "",
    authors: Array.isArray(book.authors)
      ? book.authors
      : book.authors
      ? [book.authors]
      : [],
    pages: book.pages || "",
    genre: Array.isArray(book.genre)
      ? book.genre
      : book.genre
      ? [book.genre]
      : [],
    description: book.description || "",
  });
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    if (book && Object.keys(book).length > 0) {
      setEditedBook({
        title: book.title || "",
        authors: Array.isArray(book.authors)
          ? book.authors
          : book.authors
          ? [book.authors]
          : [],
        pages: book.pages || "",
        genre: Array.isArray(book.genre)
          ? book.genre
          : book.genre
          ? [book.genre]
          : [],
        description: book.description || "",
      });
      setIsLoading(false);
    }
  }, [book]);

  const handleChange = (name, value) => {
    setEditedBook((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    const updatedBook = {
      ...book, // Preserve all original data
      title: editedBook.title,
      authors: editedBook.authors,
      pages: parseInt(editedBook.pages, 10) || book.pages,
      genre: editedBook.genre,
      description: editedBook.description,
    };
    onSave(updatedBook);
    onClose();
  };

  if (isLoading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>Loading...</DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={!isNonMobileScreens}
    >
      <DialogTitle>Edit Book Data</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          margin="normal"
          label="Title"
          name="title"
          value={editedBook.title}
          onChange={(e) => handleChange("title", e.target.value)}
        />
        <Autocomplete
          multiple
          freeSolo
          options={[]} // You can add predefined options here if needed
          value={editedBook.authors}
          onChange={(_, newValue) => handleChange("authors", newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Authors"
              placeholder="Add authors"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Typography variant="body2" color="error">
          Please press "enter" after adding author(s)
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Pages"
          name="pages"
          type="number"
          value={editedBook.pages}
          onChange={(e) => handleChange("pages", e.target.value)}
        />
        <Autocomplete
          multiple
          freeSolo
          options={[]} // You can add predefined genre options here if needed
          value={editedBook.genre}
          onChange={(_, newValue) => handleChange("genre", newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Genres"
              placeholder="Add genres"
              fullWidth
              margin="normal"
            />
          )}
        />
        <Typography variant="body2" color="error">
          Please press "enter" after adding genre(s)
        </Typography>
        <TextField
          fullWidth
          margin="normal"
          label="Description"
          name="description"
          multiline
          rows={4}
          value={editedBook.description}
          onChange={(e) => handleChange("description", e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBookDataDialog;
