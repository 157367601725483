import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import CurrentBookCard from "../CurrentBookCard";
import BookSearchCard from "../../../components/BookSearchCard";

const GuildRightProfile = ({
  currentRoom,
  isRoomCreator,
  loadingRooms,
  onBookSelect,
  handleBookSelect, // This is for consistency with the parent component
}) => {
  const [showBookSearch, setShowBookSearch] = useState(true); // State to show/hide the BookSearchCard

  const handleCloseBookSearch = () => {
    setShowBookSearch(false); // Close the book search card
  };

  const handleOpenBookSearch = () => {
    setShowBookSearch(true); // Open the book search card
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CurrentBookCard
        currentBook={currentRoom.currentBook}
        isLoading={loadingRooms[currentRoom._id]}
      />

      {isRoomCreator && showBookSearch && (
        <BookSearchCard
          onBookSelect={handleBookSelect || onBookSelect}
          currentBook={currentRoom.currentBook}
          onClose={handleCloseBookSearch} // Close button handler
        />
      )}

      {!showBookSearch && isRoomCreator && (
        <Button variant="outlined" onClick={handleOpenBookSearch}>
          {currentRoom.currentBook ? "Switch Book" : "Book Search"}
        </Button>
      )}
    </Box>
  );
};

export default GuildRightProfile;
