import React from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, useTheme } from "@mui/material";

//flockGreen
const customizePrimaryColors = [
  "#dff2ed", // 50
  "#b2dfd0", // 100
  "#81cbb3", // 200
  "#50b596", // 300
  "#2ba581", // 400
  "#08956e", // 500
  "#058862", // 600
  "#007854", // 700
  "#006847", // 800
  "#004c2c", // 900
];

//orange
// const customizePrimaryColors = [
//   "#FFF3E0", // 50
//   "#FFE0B2", // 100
//   "#FFCC80", // 200
//   "#FFB74D", // 300
//   "#FFA726", // 400
//   "#FF9800", // 500
//   "#FB8C00", // 600
//   "#F57C00", // 700
//   "#EF6C00", // 800
//   "#E65100", // 900
// ];

const PieChart = ({ pieData }) => {
  const theme = useTheme();

  // Process data to show top 5 and "Others"
  const processedData = (() => {
    // Sort data by value in descending order
    const sortedData = [...pieData].sort((a, b) => b.value - a.value);

    // Take top 5 items
    const top5Data = sortedData.slice(0, 5);

    // Combine the rest into an "Others" category
    const othersData = sortedData.slice(5).reduce(
      (acc, item) => {
        acc.value += item.value;
        return acc;
      },
      { id: "Others", label: "Others", value: 0 }
    );

    // Add the "Others" category if there are more than 5 items
    if (sortedData.length > 5) {
      return [...top5Data, othersData];
    }

    return top5Data;
  })();

  // Calculate the total value of all segments
  const totalValue = processedData.reduce((sum, item) => sum + item.value, 0);

  return (
    <Box
      sx={{
        height: "250px",
        width: "auto",
        maxWidth: "600px",
      }}
    >
      <ResponsivePie
        data={processedData}
        margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
        innerRadius={0.55}
        padAngle={3}
        cornerRadius={5}
        activeOuterRadiusOffset={8}
        theme={{
          tooltip: {
            container: {
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral.mediumLight
                  : theme.palette.neutral.extraLight,
              fontSize: 12,
            },
          },
        }}
        colors={customizePrimaryColors}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsTextColor={
          theme.palette.mode === "dark"
            ? theme.palette.neutral.dark
            : theme.palette.neutral.main
        }
        arcLinkLabelsOffset={-11}
        arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsStraightLength={16}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.45}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", "2"]],
        }}
        legends={[]}
        tooltip={({ datum: { id, value, color, label } }) => {
          const percentage = ((value / totalValue) * 100).toFixed(2);
          return (
            <div
              style={{
                padding: 12,
                background: theme.palette.background.paper,
                color: theme.palette.text.primary,
                border: `1px solid ${color}`,
              }}
            >
              <strong>{label}</strong>
              <br />
              Books: {value} ({percentage}%)
            </div>
          );
        }}
      />
    </Box>
  );
};

export default PieChart;
