import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { retrieveCheckoutSession } from "../../api/stripeApi";

const CheckoutReturn = () => {
  const { sessionId } = useParams();
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session = await retrieveCheckoutSession(sessionId);
        setPaymentStatus(session?.status);
      } catch (error) {
        console.error("Error fetching session:", error);
      }
    };

    fetchSession();
  }, [sessionId]);

  return (
    <Box m={2}>
      {paymentStatus === "complete" ? (
        <Typography
          //renewed
          variant="h6"
          color="primary"
        >
          Thank you for your payment!
        </Typography>
      ) : paymentStatus === "open" ? (
        <Typography
          //renewed
          variant="h6"
          color="error"
        >
          Payment did not go through. Please try again later.
        </Typography>
      ) : (
        <Typography
          //renewed
          variant="h6"
          color="textSecondary"
        >
          Processing payment...
        </Typography>
      )}
    </Box>
  );
};

export default CheckoutReturn;
