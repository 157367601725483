import { Box, Typography, Button, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const NotFound = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "60vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
      }}
    >
      <Typography
        // renewed
        variant="h2"
        sx={{ fontWeight: "bold", color: theme.palette.neutral.medium }}
      >
        Page not Found 404
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        {" "}
        <Button variant="contained" onClick={() => navigate("/home")}>
          Back to Home or Login
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
