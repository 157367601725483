import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDHKLluKWLPdXRHYhJQiVYGDazjrWTWiH8",
  authDomain: "auth.readers-guild.com",
  projectId: "readersguild-b9bda",
  storageBucket: "readersguild-b9bda.appspot.com",
  messagingSenderId: "78595183566",
  appId: "1:78595183566:web:191d54aba3fe0f1110919f",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
// const twitterProvider = new TwitterAuthProvider();

export { auth, googleProvider };
