import { createContext, useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import { logoutUser } from "../api/authApi";
import instance from "../axiosAuthInterceptor";

const AuthContext = createContext();

export const AuthProvider = ({ children, authCheck }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(authCheck);

  useEffect(() => {
    const cookieJwt = Cookies.get("x-auth-cookie");
    setIsAuthenticated(!!cookieJwt); // Set isAuthenticated to true if cookieJwt exists
  }, []);

  const checkLogin = () => {
    setIsAuthenticated(true);
  };

  // const checkToken = async () => {
  //   const cookieJwt = Cookies.get("x-auth-cookie");
  //   if (!cookieJwt) {
  //     setIsAuthenticated(false);
  //     return false;
  //   }

  //   try {
  //     // Make a request to the server to validate the token
  //     await instance.get("/api/auth/validate-token");
  //     return true;
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       // Token is invalid or expired
  //       setIsAuthenticated(false);
  //       Cookies.remove("x-auth-cookie");
  //       Cookies.remove("jwt");
  //       localStorage.removeItem("userData");
  //       return false;
  //     }
  //     // For other errors, assume the token is still valid
  //     return true;
  //   }
  // };

  const checkLogout = async () => {
    try {
      await logoutUser(); // Call the logoutUser function
      setIsAuthenticated(false);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const userCheckResultIsNotLoggedIn = () => {
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userCheckResultIsNotLoggedIn,
        // checkToken,
        checkLogin,
        checkLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
