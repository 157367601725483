import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  useTheme,
  IconButton,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../customQuillStyles.css";
import NumberInput from "../../components/NumberInput";
import DateInput from "../../components/DateInput";
import dayjs from "dayjs";
import ClearIcon from "@mui/icons-material/Clear";
import FlexBetween from "../../components/FlexBetween";
import CustomQuillEditor from "../../components/CustomQuillEditor";

const PostDiscussionDialog = ({
  open,
  onClose,
  onSubmit,
  bookId,
  lastPageRead,
  bookTotalPages,
}) => {
  const theme = useTheme();
  const [readingNotes, setReadingNotes] = useState("");
  const [newLastPageRead, setNewLastPageRead] = useState(lastPageRead);
  const [privateSwitch, setPrivateSwitch] = useState(false);
  const [postDate, setPostDate] = useState(dayjs().toISOString());
  const [error, setError] = useState(""); // New state for error handling
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  useEffect(() => {
    if (open) {
      const savedDraft = localStorage.getItem(`journalDraft:${bookId}`);
      if (savedDraft) {
        const parsedDraft = JSON.parse(savedDraft);
        setReadingNotes(parsedDraft.readingNotes || "");
        setNewLastPageRead(
          parseInt(parsedDraft.newLastPageRead) || lastPageRead
        );
        setPostDate(parsedDraft.postDate || dayjs().toISOString());
        setPrivateSwitch(
          parsedDraft.privateSwitch !== undefined
            ? parsedDraft.privateSwitch
            : true
        );
      } else {
        setNewLastPageRead(lastPageRead);
        setReadingNotes("");
        setPostDate(dayjs().toISOString());
        setPrivateSwitch(true);
      }
      setError(""); // Clear error when opening dialog
    }
  }, [open, lastPageRead]);

  useEffect(() => {
    if (open) {
      const draft = {
        readingNotes,
        newLastPageRead,
        postDate,
        privateSwitch,
      };
      localStorage.setItem(`journalDraft:${bookId}`, JSON.stringify(draft));
    }
  }, [open, readingNotes, newLastPageRead, postDate, privateSwitch]);

  useEffect(() => {
    setNewLastPageRead(lastPageRead);
  }, [lastPageRead]);

  const handleSubmit = () => {
    if (!readingNotes.trim()) {
      setError("Please enter your reading notes before posting.");
      return;
    }

    onSubmit({
      readingNotes,
      lastPageRead: Number(newLastPageRead),
      startPageRead: lastPageRead,
      privateSwitch,
      postDate,
    });
    localStorage.removeItem(`journalDraft:${bookId}`);

    setReadingNotes("");
    setPrivateSwitch(false);
    setError(""); // Clear error after successful submission
    window.location.reload();
    onClose();
  };

  const handleDateChange = (newValue) => {
    setPostDate(dayjs(newValue).toISOString());
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={!isNonMobileScreens}
    >
      <FlexBetween>
        <DialogTitle>Post Discussion</DialogTitle>
        <IconButton onClick={onClose} sx={{ p: 0.5, pr: 3 }}>
          <ClearIcon />
        </IconButton>
      </FlexBetween>
      <DialogContent sx={{ pt: 0 }}>
        <FormControl sx={{ display: "flex", width: "100%" }} error={!!error}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <DateInput value={postDate} onChange={handleDateChange} />
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.neutral.medium, fontWeight: "500" }}
              >
                Last Page Read
              </Typography>
              <NumberInput
                value={newLastPageRead}
                onChange={(e) => setNewLastPageRead(e.target.value)}
                minimumPageNumber={lastPageRead}
                maximumPageNumber={bookTotalPages}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.neutral.medium, fontWeight: "500" }}
              >
                Out of {bookTotalPages}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", width: "100%", mb: 2.5, mt: 1.5 }}>
              <CustomQuillEditor
                value={readingNotes}
                onChange={setReadingNotes}
                placeholder="Enter your reading notes..."
              />
            </Box>
            {error && <FormHelperText error>{error}</FormHelperText>}
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="neutral">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostDiscussionDialog;
