import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DiscussionCard from "./DiscussionCard";

const DiscussionSection = ({
  discussions,
  onPostDiscussion,
  onEditDiscussion,
  onRemoveDiscussion,
  onLikeDiscussion,
  currentUserId,
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5, mt: 1.5 }}>
      <Box sx={{ width: "100%" }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          size="large"
          onClick={onPostDiscussion}
          sx={{ display: "flex" }}
        >
          POST DISCUSSION
        </Button>
      </Box>
      {discussions && discussions.length > 0 && (
        <Typography variant="body1" sx={{ fontWeight: "700", my: 0.5 }}>
          Discussion
        </Typography>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        {discussions &&
          discussions.map((discussion, index) =>
            discussion && discussion.user ? (
              <DiscussionCard
                key={discussion._id || index}
                discussion={discussion}
                isCurrentUser={discussion.user._id === currentUserId}
                onEdit={() => onEditDiscussion(discussion)}
                onRemove={() => onRemoveDiscussion(discussion)}
                onLike={onLikeDiscussion}
                currentUserId={currentUserId}
              />
            ) : null
          )}
      </Box>
    </Box>
  );
};

export default DiscussionSection;
