import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
} from "@mui/material";
import { Star as StarIcon, Info as InfoIcon } from "@mui/icons-material";
import { getUsers, giveLifetimeAccess } from "../../api/adminApi";
const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await getUsers();
      if (response.success) {
        setUsers(response.data);
      } else {
        setSnackbar({
          open: true,
          message: "Failed to fetch users.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setSnackbar({
        open: true,
        message: "An error occurred while fetching users.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleGiveLifetimeAccess = async (userId) => {
    try {
      const response = await giveLifetimeAccess(userId);
      if (response.success) {
        setUsers(
          users.map((user) =>
            user._id === userId ? { ...user, lifetimeAccess: true } : user
          )
        );
        setSnackbar({
          open: true,
          message: "Lifetime access granted successfully.",
          severity: "success",
        });
      } else {
        setSnackbar({
          open: true,
          message: "Failed to grant lifetime access.",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error giving lifetime access:", error);
      setSnackbar({
        open: true,
        message: "An error occurred while granting lifetime access.",
        severity: "error",
      });
    }
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      <Typography variant="h4" gutterBottom>
        User Management
      </Typography>

      <Paper elevation={3} sx={{ p: 2, mb: 3, backgroundColor: "#f0f4f8" }}>
        <Box display="flex" alignItems="center">
          <InfoIcon color="primary" sx={{ mr: 1 }} />
          <Typography variant="body1">
            Lifetime Access Management: Use the star icon next to a user to
            grant them lifetime access. Users with lifetime access have
            unlimited premium features and are exempt from subscription fees.
          </Typography>
        </Box>
      </Paper>

      <TextField
        fullWidth
        variant="outlined"
        label="Search users"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />
      <List>
        {filteredUsers.map((user) => (
          <ListItem key={user._id}>
            <ListItemText
              primary={user.name}
              secondary={
                <React.Fragment>
                  {user.email}
                  {user.lifetimeAccess && (
                    <Typography
                      component="span"
                      sx={{ ml: 1, color: "primary.main" }}
                    >
                      (Lifetime Access)
                    </Typography>
                  )}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              {user.lifetimeAccess ? (
                <StarIcon color="primary" />
              ) : (
                <IconButton
                  edge="end"
                  onClick={() => handleGiveLifetimeAccess(user._id)}
                  title="Grant Lifetime Access"
                >
                  <StarIcon />
                </IconButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserManagement;
