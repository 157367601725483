import {
  useMemo,
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

/* BLOGS */
import BlogWrapper from "./pages/Blogs/BlogWrapper.jsx";
import BlogList from "./pages/Blogs/BlogList.jsx";
import BlogPost from "./pages/Blogs/BlogPost.jsx";
import BlogEditor from "./pages/Blogs/BlogEditor.jsx";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import HomePage from "./pages/homePage";
import Navbar from "./layouts/navbar";
import Wishlist from "./pages/Wishlist";
import ManualReadingSession from "./pages/readingSession/manualReadingSession";
import LiveReadingSession from "./pages/readingSession/liveReadingSession.jsx";
import Charts from "./pages/charts/index.jsx";
import BooksPage from "./pages/BooksPage/index.jsx";
import LogHistory from "./pages/logHistory.jsx/index.jsx";
import { themeSettings } from "./theme.js";
import { useTheme } from "./context/ThemeContext.js";
import { CssBaseline } from "@mui/material";
import ContentMargin from "./components/ContentMargin.jsx";
import UserNotes from "./pages/NotePage/UserNotes.jsx";
import LogNotes from "./pages/NotePage/LogNotes.jsx";
import Community from "./pages/community/index.jsx";
import PrivateRoute from "./utility/PrivateRoute.jsx";
import NotFound from "./pages/404/index.jsx";
import { AuthProvider, useAuth } from "./context/AuthContext.js";
import { UserProvider } from "./context/UserContext.js";
import Cookies from "js-cookie";
import { getUserProfileData } from "./api/userApi.js";
import { SnackbarProvider } from "./context/SnackbarContext.js";
import { UserContext } from "./context/UserContext.js";
import { SearchProvider } from "./context/SearchContext.js";
import LoadingBar from "react-top-loading-bar";
import { useLoading } from "./context/LoadingContext.js";
import CheckoutReturn from "./components/Stripe/CheckoutReturn.jsx";
import PricingPage from "./pages/PricingPage/index.jsx";
import { SocketProvider } from "./context/SocketContext.js";
import AnnouncementBar from "./layouts/AnnouncementBar/index.jsx";
import TBRPage from "./pages/TBRPage/index.jsx";
import LandingPage from "./pages/LandingPage/index.jsx";
import Footer from "./layouts/footer/index.jsx";
import Marketplace from "./pages/Marketplace/index.jsx";
import SignUp from "./pages/authorization/signUp/index.jsx";
import OnBoarding1 from "./pages/authorization/onBoarding/OnBoarding1.jsx";
import OnBoarding2 from "./pages/authorization/onBoarding/OnBoarding2.jsx";
import OnBoarding3 from "./pages/authorization/onBoarding/OnBoarding3.jsx";
import OnBoarding4 from "./pages/authorization/onBoarding/OnBoarding4.jsx";
import OnBoarding5 from "./pages/authorization/onBoarding/OnBoarding5.jsx";
import OnBoarding6 from "./pages/authorization/onBoarding/OnBoarding6.jsx";
import Verify from "./pages/authorization/signUp/Verify.jsx";
import Rooms from "./pages/Rooms/index.jsx";
import TermsOfService from "./pages/about/TermsOfService.js";
import PrivacyPolicy from "./pages/about/PrivacyPolicy.js";
import Guides from "./pages/Guides/index.jsx";
import Pricing from "./pages/LandingPage/Pricing.jsx";
import OldPricingPage from "./pages/PricingPage/index.jsx";
import PublicNavbar from "./layouts/publicNavbar/index.jsx";
import GuildLanding from "./pages/LandingPage/GuildLanding.jsx";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import RegistrationVerify from "./pages/authorization/signUp/RegistrationVerify.jsx";
import AccountSetupLoading from "./pages/authorization/onBoarding/AccountSetupLoading.jsx";

// import AdminDashboard from "./pages/Admin/AdminDashboard.jsx";
import { checkTokenValidity } from "./axiosAuthInterceptor.js";
import { logoutUser } from "./api/authApi.js";
import UserManagement from "./pages/Admin/UserManagement.jsx";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode } = useTheme();
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const { updateUserProfile } = useContext(UserContext);
  const { isLoading, setIsLoading } = useLoading();
  const progressRef = useRef(null);
  const [showNavbar, setShowNavbar] = useState(true);
  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const [adminAuthTimer, setAdminAuthTimer] = useState(null);

  const ADMIN_AUTH_DURATION = 30 * 60 * 1000; // 30 minutes in milliseconds

  const handleAdminLogin = useCallback(() => {
    setIsAdminAuthenticated(true);

    // Clear any existing timer
    if (adminAuthTimer) {
      clearTimeout(adminAuthTimer);
    }

    // Set a new timer
    const timer = setTimeout(() => {
      setIsAdminAuthenticated(false);
    }, ADMIN_AUTH_DURATION);

    setAdminAuthTimer(timer);
  }, [adminAuthTimer]);

  const handleAdminLogout = useCallback(() => {
    setIsAdminAuthenticated(false);
    if (adminAuthTimer) {
      clearTimeout(adminAuthTimer);
    }
  }, [adminAuthTimer]);

  // Clear the timer when the component unmounts
  useEffect(() => {
    return () => {
      if (adminAuthTimer) {
        clearTimeout(adminAuthTimer);
      }
    };
  }, [adminAuthTimer]);

  useEffect(() => {
    if (isLoading === true) {
      progressRef.current.continuousStart();
    }

    if (isLoading === false) {
      progressRef.current.complete();
    }

    return () => {
      if (isLoading === false && progressRef.current) {
        progressRef.current.complete();
      }
    };
  }, [isLoading]);

  const handleCloseAnnouncement = () => {
    setShowAnnouncement(false);
  };

  const noNavbarRoutes = [
    "/",
    "/club-landing",
    "/signup",
    "/verify",
    "/verify-registration",
    "/blog",
    "/blog/new",
    "/blog/:slug",
    "/signup/onboarding-step1",
    "/signup/onboarding-step2",
    "/signup/onboarding-step3",
    "/signup/onboarding-step4",
    "/signup/onboarding-step5",
    "/signup/onboarding-step6",
    "/account-setup",
    // "/pricing",
    "/about/terms",
    "/about/privacy",
  ];

  // useEffect(() => {
  //   const verifyToken = async () => {
  //     try {
  //       const isValid = await checkTokenValidity();
  //       console.log("check token", isValid);
  //       setIsTokenValid(isValid);

  //       if (
  //         !isValid &&
  //         !isPublicRoute(location.pathname) &&
  //         !onboardingRoutes.includes(location.pathname)
  //       ) {
  //         const result = await logoutUser();
  //         if (result.success) {
  //           setShowNavbar(false);
  //           navigate("/", { replace: true });
  //         }
  //       }
  //     } catch (error) {
  //       console.error("Error verifying token:", error);
  //       // Optionally handle the error, e.g., by setting isTokenValid to false
  //       setIsTokenValid(false);
  //     }
  //   };

  //   const token = Cookies.get("x-auth-cookie");
  //   if (token) {
  //     verifyToken();
  //   } else {
  //     setIsTokenValid(false);
  //     if (
  //       !isPublicRoute(location.pathname) &&
  //       !onboardingRoutes.includes(location.pathname)
  //     ) {
  //       navigate("/", { replace: true });
  //     }
  //   }
  // }, [location.pathname, navigate]);

  useEffect(() => {
    const currentPath = location.pathname;
    const isAuthenticated = !!Cookies.get("x-auth-cookie");

    const shouldShowNavbar = () => {
      if (noNavbarRoutes.includes(currentPath)) return false;
      if (currentPath.startsWith("/blog")) {
        // This will cover /blog, /blog/new, /blog/:slug, and any other blog-related routes
        return false;
      }
      return isAuthenticated;
    };

    setShowNavbar(shouldShowNavbar());

    if (isAuthenticated) {
      if (currentPath === "/" || currentPath === "/signup") {
        navigate("/home");
      }
    } else {
      if (
        !publicRoutes.includes(currentPath) &&
        !onboardingRoutes.includes(currentPath) &&
        !currentPath.startsWith("/blog")
      ) {
        navigate("/");
      }
    }

    localStorage.setItem("lastVisitedRoute", currentPath);
  }, [location, navigate]);

  useEffect(() => {
    const cookieJwt = Cookies.get("x-auth-cookie");
    if (cookieJwt && location.pathname === "/") {
      navigate("/home");
    }
  }, [navigate, location]);

  useEffect(() => {
    const currentPath = location.pathname;
    const hideNavbarRoutes = [
      "/",
      "club-landing",
      "/signup",
      "/verify",
      "/verify-registration",
      "/blog", // Include /blog explicitly for the list page
      "/blog/new",
      "/signup/onboarding-step1",
      "/signup/onboarding-step2",
      "/signup/onboarding-step3",
      "/signup/onboarding-step4",
      "/signup/onboarding-step5",
      "/signup/onboarding-step6",
      "/account-setup",

      "/about/terms",
      "/about/privacy",
    ];

    // Add condition to hide navbar for any /blog routes, including dynamic ones
    const shouldHideNavbar =
      hideNavbarRoutes.includes(currentPath) ||
      currentPath.startsWith("/blog/");

    setShowNavbar(!shouldHideNavbar);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("lastVisitedRoute", location.pathname);
  }, [location.pathname]);

  const publicRoutes = [
    "/",
    "/club-landing",
    "/signup",
    "/verify",
    "/verify-registration",
    "/about/terms",
    "/about/privacy",
    "/blog",
    "/blog/:slug",
  ];

  const isPublicRoute = () => {
    return (
      location.pathname === "/" ||
      location.pathname === "/club-landing" ||
      location.pathname === "/signup" ||
      location.pathname.startsWith("/blog") ||
      location.pathname === "/about/terms" ||
      location.pathname === "/about/privacy"
    );
  };

  const onboardingRoutes = [
    "/signup/onboarding-step1",
    "/signup/onboarding-step2",
    "/signup/onboarding-step3",
    "/signup/onboarding-step4",
    "/signup/onboarding-step5",
    "/signup/onboarding-step6",
    "/account-setup",
  ];

  const fetchUserDataAndNavigate = async () => {
    const cookieJwt = Cookies.get("x-auth-cookie");
    const currentPath = location.pathname;

    if (cookieJwt) {
      await fetchUserData();

      if (currentPath === "/" || currentPath === "/signup") {
        navigate("/home");
      }
    } else {
      if (
        !publicRoutes.includes(currentPath) &&
        !onboardingRoutes.includes(currentPath) &&
        !currentPath.startsWith("/blog")
      ) {
        const result = await logoutUser();
        if (result.success) {
          setShowNavbar(false);
          navigate("/", { replace: true });
        }
      }
    }

    setShowNavbar(
      !publicRoutes.includes(currentPath) &&
        !onboardingRoutes.includes(currentPath)
    );
  };

  useEffect(() => {
    fetchUserDataAndNavigate();
  }, [location.pathname]);

  const fetchUserData = async () => {
    try {
      const result = await getUserProfileData();
      const userData = result.data;

      if (userData.currentPlan === "free" && !userData.isAnnouncementShowed) {
        userData.isAnnouncementShowed = true;
        setShowAnnouncement(true);
      }

      if (!userData.uniqueTag) {
        const logoutResult = await logoutUser();
        if (logoutResult.success) {
          navigate("/", { replace: true });
          return;
        }
      }

      userData.userAmtOfBooks = userData.books?.length || 0;
      updateUserProfile(userData);
    } catch (error) {
      console.error("Error fetching user data:", error);
      // Handle error appropriately (e.g., show error message to user)
    }
  };

  //older fetch grab data from localstorage first
  // const fetchUserData = async () => {
  //   const storedUserProfile = localStorage.getItem("userData");

  //   if (storedUserProfile) {
  //     try {
  //       const parsedData = JSON.parse(storedUserProfile);
  //       if (
  //         parsedData.currentPlan === "free" &&
  //         parsedData.isAnnouncementShowed === false
  //       ) {
  //         parsedData.isAnnouncementShowed = true;
  //         setShowAnnouncement(true);
  //       }

  //       // calculate userAmtOfBooks based on books property
  //       parsedData.userAmtOfBooks = parsedData.books?.length || 0;
  //       updateUserProfile(parsedData);
  //     } catch (error) {
  //       console.error("Error parsing stored user data:", error);
  //     }
  //   } else {
  //     try {
  //       const result = await getUserProfileData();

  //       if (
  //         result.data.currentPlan === "free" &&
  //         !result.data.isAnnouncementShowed === false
  //       ) {
  //         result.data.isAnnouncementShowed = true;
  //         setShowAnnouncement(true);
  //       }
  //       if (!result.data.uniqueTag) {
  //         const result = await logoutUser();
  //         if (result.success) {
  //           //replace:true = The user can't go back to the previous page using the browser's back button.
  //           navigate("/", { replace: true });
  //         }
  //       }
  //       result.data.userAmtOfBooks = result.data.books?.length || 0;
  //       updateUserProfile(result.data);
  //     } catch (error) {
  //       console.error("Error fetching user data:", error);
  //     }
  //   }
  // };

  useEffect(() => {
    localStorage.setItem("lastVisitedRoute", location.pathname);
  }, [location.pathname]);

  // ... (other useEffects and functions remain the same)

  const PublicRoute = ({ children }) => {
    const cookieJwt = Cookies.get("x-auth-cookie");

    if (cookieJwt) {
      return <Navigate to="/home" />;
    }

    return children;
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadingBar color="#f11946" ref={progressRef} shadow={true} />
      <div className="app">
        <SnackbarProvider>
          <AuthProvider authCheck={Cookies.get("x-auth-cookie") ? true : false}>
            <SearchProvider>
              {/* 
              //this is to show banner bar on top for aggresive upgrade strategy
              {showAnnouncement && (
                <AnnouncementBar
                  message="Upgrade to Premium to track unlimited books and join exclusive book clubs!"
                  ctaText="Upgrade Now"
                  ctaLink="/pricing"
                  onClose={handleCloseAnnouncement}
                />
              )} */}
              <SocketProvider>
                {/* {showNavbar && <Navbar setShowNavbar={setShowNavbar} />} */}
                {isPublicRoute() ? (
                  <PublicNavbar />
                ) : (
                  <Navbar setShowNavbar={setShowNavbar} />
                )}
                <ContentMargin>
                  <Routes>
                    {/* Public routes */}
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/club-landing" element={<GuildLanding />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/about/terms" element={<TermsOfService />} />
                    <Route path="/about/privacy" element={<PrivacyPolicy />} />
                    <Route
                      path="/verify-registration"
                      element={<RegistrationVerify />}
                    />
                    <Route path="/verify" element={<Verify />} />
                    <Route
                      path="/signup/onboarding-step1"
                      element={<OnBoarding1 />}
                    />
                    <Route
                      path="/signup/onboarding-step2"
                      element={<OnBoarding2 />}
                    />
                    <Route
                      path="/signup/onboarding-step3"
                      element={<OnBoarding3 />}
                    />
                    <Route
                      path="/signup/onboarding-step4"
                      element={<OnBoarding4 />}
                    />
                    <Route
                      path="/signup/onboarding-step5"
                      element={<OnBoarding5 />}
                    />
                    <Route
                      path="/signup/onboarding-step6"
                      element={<OnBoarding6 />}
                    />
                    <Route
                      path="/account-setup"
                      element={<AccountSetupLoading />}
                    />
                    {/* Blog routes */}
                    <Route
                      path="/blog"
                      element={
                        <BlogWrapper component={BlogList} requireAuth={false} />
                      }
                    />
                    <Route
                      path="/blog/:slug"
                      element={
                        <BlogWrapper component={BlogPost} requireAuth={false} />
                      }
                    />
                    <Route
                      path="/blog/new"
                      element={
                        <BlogWrapper
                          component={BlogEditor}
                          requireAuth={true}
                        />
                      }
                    />
                    <Route
                      path="/blog/edit/:slug"
                      element={
                        <BlogWrapper
                          component={BlogEditor}
                          requireAuth={true}
                        />
                      }
                    />
                    {/* ADMIN ROUTES */}
                    <Route
                      path="/admin/login"
                      element={
                        <AdminLoginPage onLoginSuccess={handleAdminLogin} />
                      }
                    />
                    <Route
                      path="/admin/dashboard"
                      element={
                        <PrivateRoute>
                          {isAdminAuthenticated ? (
                            <AdminDashboard onLogout={handleAdminLogout} />
                          ) : (
                            <Navigate to="/admin/login" replace />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/admin/user-management"
                      element={
                        <PrivateRoute>
                          {isAdminAuthenticated ? (
                            <UserManagement onLogout={handleAdminLogout} />
                          ) : (
                            <Navigate to="/admin/login" replace />
                          )}
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/home"
                      element={
                        <PrivateRoute>
                          <HomePage setShowNavbar={setShowNavbar} />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/guides"
                      element={
                        <PrivateRoute>
                          <Guides />
                        </PrivateRoute>
                      }
                    />
                    {/* <Route
                      path="/admin"
                      element={
                        <PrivateRoute>
                          <AdminDashboard />
                        </PrivateRoute>
                      }
                    /> */}
                    <Route
                      path="/library/session/:bookId"
                      element={<ManualReadingSession />}
                    />
                    <Route
                      path="/session/live"
                      element={
                        <PrivateRoute>
                          <LiveReadingSession />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/log/history"
                      element={
                        <PrivateRoute>
                          <LogHistory />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/log/notes"
                      element={
                        <PrivateRoute>
                          <LogNotes />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/notes"
                      element={
                        <PrivateRoute>
                          <UserNotes />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/library"
                      element={
                        <PrivateRoute>
                          <BooksPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/rooms"
                      element={
                        <PrivateRoute>
                          <Rooms />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/tbr"
                      element={
                        <PrivateRoute>
                          <TBRPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/pricing"
                      element={
                        <PrivateRoute>
                          <Pricing />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/oldpricing"
                      element={
                        <PrivateRoute>
                          <OldPricingPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/marketplace/:bookId"
                      element={
                        <PrivateRoute>
                          <Marketplace />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/community"
                      element={
                        <PrivateRoute>
                          <Community />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/marketplace"
                      element={
                        <PrivateRoute>
                          <BooksPage isMarketplace="true" />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/return"
                      element={
                        <PrivateRoute>
                          <CheckoutReturn />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/marketplace/wishlist"
                      element={
                        <PrivateRoute>
                          <Wishlist />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/stats"
                      element={
                        <PrivateRoute>
                          <Wishlist />
                        </PrivateRoute>
                      }
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </ContentMargin>
              </SocketProvider>
              {/* {location.pathname === "/" && <Footer />} */}
            </SearchProvider>
          </AuthProvider>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
