import { Box, IconButton, Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);
  const [editForm, setEditForm] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    let timer;
    if (running) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [running]);

  let timeFormat = (time) => {
    let hours = Math.floor(time / 60 / 60);
    let minutes = Math.floor((time / 60) % 60);
    let seconds = Math.floor(time % 60);

    // Ensure each part has two digits with leading zeros
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  const handlePlayPause = () => {
    setRunning(!running);
  };

  const handleReset = () => {
    setRunning(false);
    setTime(0);
  };

  const handleChange = (e) => {
    setEditForm((prevData) => ({
      ...prevData,

      [e.target.name]: e.target.value,
    }));
  };

  const handleEdit = async (e) => {
    const { hours, minutes, seconds } = editForm;
    const hoursInSecond = hours * 60 * 60;
    const minutesInSecond = minutes * 60;
    const secondsParsed = seconds * 1;

    let edittedTimeValue = hoursInSecond + minutesInSecond + secondsParsed;
    //set time changed to this after editted
    return setTime(edittedTimeValue);
  };

  return (
    <Box>
      {timeFormat(time)}
      <Box sx={{ display: "flex" }}>
        <Box>
          {running === false ? (
            <IconButton onClick={handlePlayPause}>
              <PlayCircleFilledIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handlePlayPause}>
              <PauseCircleFilledIcon />
            </IconButton>
          )}
        </Box>
        <Box>
          {time === 0 ? (
            <Button variant="contained" onClick={handleReset} disabled>
              Reset
            </Button>
          ) : (
            <Button variant="contained" onClick={handleReset}>
              Reset
            </Button>
          )}
        </Box>
      </Box>
      <Box>
        <Box sx={{ display: "flex" }}>
          <TextField
            name="hours"
            label="hours"
            value={editForm.hours}
            onChange={(e) => handleChange(e)}
          />

          <TextField
            name="minutes"
            label="minutes"
            value={editForm.minutes}
            onChange={(e) => handleChange(e)}
          />

          <TextField
            name="seconds"
            label="seconds"
            value={editForm.seconds}
            onChange={(e) => handleChange(e)}
          />
        </Box>
        <Button variant="contained" onClick={(e) => handleEdit(e)}>
          Edit
        </Button>
      </Box>
    </Box>
  );
};

export default Stopwatch;
