import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Rating,
  Typography,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Divider,
  FormControl,
  TextField,
  useTheme,
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import BookCommentDisplayWidget from "../widgets/BookCommentDisplayWidget";
import { useParams } from "react-router-dom";
import { getBook } from "../../api/bookApi";
import { postBookReview } from "../../api/reviewApi";
import AddRating from "../../components/AddRating";
import { useSnackbar } from "../../context/SnackbarContext";
import languageCodeMap from "../../utility/languageCodeMap";
import BookTag from "../../components/BookTag";
import { UserContext } from "../../context/UserContext";

const RatingDialogMarketplace = ({
  open,
  onClose,
  bookId,
  initialRatingValue = 0,
  onUpdateReview,
}) => {
  const theme = useTheme();
  const [comment, setComment] = useState("");
  const [ratingValue, setRatingValue] = useState(initialRatingValue);
  const { updateUserProfile } = useContext(UserContext);

  const handleRatingChange = (newValue) => {
    setRatingValue(newValue);
  };
  const handleCommentChange = (e) => {
    const newValue = e.target.value;
    setComment(newValue);
  };
  const handleSaveReview = async () => {
    const result = await postBookReview(comment, ratingValue, bookId);
    if (result.success === true) {
      onUpdateReview(result.review);
      updateUserProfile(result.user);
      localStorage.setItem("userData", JSON.stringify(result.user));
    }
    onClose();
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Rate This Book</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          gutterBottom
          sx={{ color: theme.palette.neutral.main, fontWeight: 500 }}
        >
          Change Rating :
        </DialogContentText>
        <AddRating
          onUpdateRating={handleRatingChange}
          initialValue={initialRatingValue}
        />
        {/* <DialogContentText>
          Enter your new yearly book goal below:
        </DialogContentText> */}
        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            name="comment"
            label="What do you think about the book?"
            fullWidth
            value={comment}
            onChange={(e) => handleCommentChange(e)}
            multiline
            variant="filled"
            rows={6}
            sx={{ display: "flex" }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveReview} color="primary">
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Marketplace = () => {
  const [book, setBook] = useState({});
  const [bookReviews, setBookReviews] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [numberOfUserOwnBook, setNumberOfUserOwnBook] = useState(3);
  const { bookId } = useParams();
  const snackbar = useSnackbar();

  useEffect(() => {
    fetchBookData();
    // fetchReviews();
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const fetchBookData = async () => {
    try {
      const result = await getBook(bookId);

      const bookReviewsResult = result.data.bookReviews.slice().reverse();
      setBookReviews(bookReviewsResult);
      setBook(result.data);
      setNumberOfUserOwnBook(Object.keys(result.data.inUserCollection).length);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const handleReviewUpdate = (updateReviewData) => {
    setBookReviews([updateReviewData, ...bookReviews]);
    snackbar("Successfully create a review!");
  };

  const copyUrlToClipboard = () => {
    // Get the current URL
    const url = window.location.href;

    navigator.clipboard
      .writeText(url)
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy URL to clipboard:", error);
      });
    snackbar("Successfully copy book link!");
  };

  const defaultCover = "/assets/book-sample.jpg";

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "grid",
          mt: 2,
          gridTemplateColumns: "repeat(5,1fr)",
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "inline-block", height: 340, boxShadow: 3 }}>
            <img
              src={
                book?.cover?.large ||
                book?.cover?.medium ||
                book?.cover?.small ||
                defaultCover
              }
              alt={`Book Cover for ${book?.title || "Book Title"}`}
              height={340}
              width="auto"
              sx={{ boxShadow: 3 }}
            />
          </Box>{" "}
          {/* <Box sx={{ mt: 2, display: "flex", flexDirection: "row" }}>
            <Button
              variant="contained"
              color="warning"
              size="small"
              sx={{ mr: 1 }}
            >
              Buy
            </Button>
            <Button variant="contained" size="small">
              Move to Library
            </Button>
          </Box> */}
        </Box>
        <Box sx={{ gridColumn: "span 4", pl: "16px" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body2" component="div">
                {book.title || `The Psychology of Money`}
              </Typography>
              <Typography variant="h6" sx={{ fontStyle: "italic" }}>
                {book.authors || `By Morgan Housel`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Rating
                name="read-only"
                value={book.averageRating || 1}
                precision={0.1}
                readOnly
              />
              {book.ratingCount && (
                <Typography variant="body2">
                  {`(${book.ratingCount})`}
                </Typography>
              )}
            </Box>
            {book.description && (
              <Box>
                <Typography gutterBottom variant="body2" color="text.secondary">
                  {book.description}
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {book.genre &&
                book.genre.map((genreTag, index) => (
                  <Chip
                    label={genreTag}
                    size="small"
                    color="default"
                    sx={{ height: 20, fontSize: "0.7rem" }}
                  />
                ))}
            </Box>
            <Typography variant="body2">
              <span style={{ fontWeight: "bold" }}>ISBN-10: </span>
              {book.isbn10}
            </Typography>
            <Typography variant="body2">
              <span style={{ fontWeight: "bold" }}>ISBN-13: </span>
              {book.isbn13}
            </Typography>
            {book.language && (
              <Typography variant="body2">
                <span style={{ fontWeight: "bold" }}>Language: </span>
                {languageCodeMap[book.language]}
              </Typography>
            )}
            {book.publication && (
              <>
                <Typography variant="body2">
                  <span style={{ fontWeight: "bold" }}>Publisher: </span>
                  {book.publication.publisher}
                </Typography>
                {book.publication.publishDate ||
                  (book.publication.publishPlaces && (
                    <Typography variant="body2">
                      <span style={{ fontWeight: "bold" }}>
                        Publication information:{" "}
                      </span>
                      {book.publication.publishDate}
                      {book.publication.publishPlaces && (
                        <span>| {book.publication.publishPlaces}</span>
                      )}
                    </Typography>
                  ))}
              </>
            )}
            <Typography variant="caption">{book.pages || 1} PAGES</Typography>
            <Box>
              <IconButton onClick={copyUrlToClipboard}>
                <ShareIcon />
              </IconButton>

              {/* <Button size="small" color="primary" variant="outlined">
                Reading Stats
              </Button> */}
            </Box>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography variant="caption">
              {numberOfUserOwnBook || 1600} people added to library{" "}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          {/* COMMENT & REVIEW POST */}
          <Box sx={{ mt: 4 }}>
            <Button fullWidth onClick={handleOpenDialog} variant="outlined">
              <Typography
                //renewed
                variant="body1"
                sx={{ fontWeight: "bold" }}
              >
                Write a Review
              </Typography>
            </Button>
            {/* <PostCommentReviewWidget /> */}
          </Box>
          {/* COMMENT DISPLAY */}

          {book.bookReviews &&
            bookReviews.map((review, index) => (
              <Box key={index} sx={{ mt: 4 }}>
                <BookCommentDisplayWidget
                  key={index}
                  name={review.author.name}
                  rating={review.rating}
                  comment={review.body}
                  postDate={review.createdAt}
                  bookReviewData={review}
                />
              </Box>
            ))}
        </Box>
        {/* <Box sx={{ pl: "16px" }}>
          <Typography>black</Typography>
        </Box>
        <Box sx={{ pl: "16px" }}>
          <Typography>black</Typography>
        </Box> */}
        <RatingDialogMarketplace
          open={isDialogOpen}
          onClose={handleCloseDialog}
          bookId={bookId}
          initialRatingValue={0}
          onUpdateReview={handleReviewUpdate}
        />
      </Box>{" "}
    </Box>
  );
};

export default Marketplace;
