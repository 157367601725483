import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme, alpha, useMediaQuery } from "@mui/system";

const userTestimonials = [
  {
    avatar: (
      <Avatar
        alt="Emma T."
        src="https://randomuser.me/api/portraits/women/65.jpg"
      />
    ),
    name: "Emma T.",
    occupation: "Book Blogger",
    testimonial:
      "ReadersGuild has a beautiful and modern UI that makes tracking my books a pleasure. The features are comprehensive, covering everything from detailed reading stats to a versatile journaling system. Love the app!",
  },
  {
    avatar: (
      <Avatar
        alt="Michael C."
        src="https://randomuser.me/api/portraits/men/32.jpg"
      />
    ),
    name: "Michael C.",
    occupation: "English Teacher",
    testimonial:
      "Organizing our book guild on ReadersGuild has never been easier. The app simplifies managing group reading lists and scheduling discussions, making it effortless to keep everyone on the same page. It's an essential tool for anyone looking to create or join a book club.",
  },

  // {
  //   avatar: (
  //     <Avatar
  //       alt="Michael C."
  //       src="https://randomuser.me/api/portraits/men/32.jpg"
  //     />
  //   ),
  //   name: "Michael C.",
  //   occupation: "English Teacher",
  //   testimonial:
  //     "My students love using ReadersGuild for our class reading assignments. It's so much easier to track their progress and engage them in discussions. The genre breakdown helped one of my reluctant readers discover a love for sci-fi. This app is a must-have for any teacher!",
  // },
  {
    avatar: (
      <Avatar
        alt="Sofia R."
        src="https://randomuser.me/api/portraits/women/42.jpg"
      />
    ),
    name: "Sofia R.",
    occupation: "Aspiring Writer",
    testimonial:
      "The personal book log and journaling features on ReadersGuild are a game-changer! I can easily track my thoughts and insights on each book I read, which has been invaluable for my writing. It feels like having a personalized library of my reading journey.",
  },

  // {
  //   avatar: (
  //     <Avatar
  //       alt="Sofia R."
  //       src="https://randomuser.me/api/portraits/women/42.jpg"
  //     />
  //   ),
  //   name: "Sofia R.",
  //   occupation: "Aspiring Writer",
  //   testimonial:
  //     "The community on ReadersGuild is amazing! I've gotten so much inspiration for my own writing from the book discussions. It's like having a writer's group and book club rolled into one. Plus, the reading challenge feature keeps me accountable to my goals.",
  // },
  {
    avatar: (
      <Avatar
        alt="David K."
        src="https://randomuser.me/api/portraits/men/22.jpg"
      />
    ),
    name: "David K.",
    occupation: "Software Developer",
    testimonial:
      "As someone who spends all day coding, I was struggling to make time for reading. ReadersGuild changed that. The 'pages per day' tracker motivates me to read a bit every day, even if it's just a few pages. It's surprisingly addictive!",
  },
  {
    avatar: (
      <Avatar
        alt="Laura M."
        src="https://randomuser.me/api/portraits/women/89.jpg"
      />
    ),
    name: "Laura M.",
    occupation: "Librarian",
    testimonial:
      "ReadersGuild has been a fantastic tool for our library's book clubs. The shared reading progress and discussion features have really increased engagement. I've even started using it for my personal reading - the TBR list feature is a lifesaver for someone always surrounded by books!",
  },
  {
    avatar: (
      <Avatar
        alt="James F."
        src="https://randomuser.me/api/portraits/men/55.jpg"
      />
    ),
    name: "James F.",
    occupation: "University Student",
    testimonial:
      "Between textbooks and novels, I'm always juggling multiple books. ReadersGuild helps me keep everything organized. The best part? Comparing notes with classmates right in the app. It's made studying for lit classes way more fun!",
  },
];

const logoStyle = {
  width: "64px",
  opacity: 0.3,
};

export default function Testimonials() {
  const theme = useTheme();
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");
  const variant = "outlined";

  return (
    <Container
      id="testimonials"
      sx={{
        pt: { xs: 2, sm: 6 },
        pb: { xs: 4, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 2, sm: 4 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          // width: { sm: "100%", md: "60%" },
          textAlign: { md: "center" },
          alignItems: { md: "center" },
        }}
      >
        <Typography
          variant={isSuperSmallScreen ? "h6" : "h4"}
          color="text.primary"
        >
          What Our Readers Say
        </Typography>
        <Typography
          variant="h6"
          color="text.secondary"
          sx={{
            fontWeight: "400",
            width: { sm: "100%", md: "60%" },
          }}
        >
          From casual readers to professional educators, see how ReadersGuild is
          making a difference in the world of books.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {userTestimonials.map((testimonial, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: "flex" }}>
            <Card
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                flexGrow: 1,
                p: 1,
                backgroundColor: theme.palette.neutral.light,
                // borderRadius: 10,
                border: `1px solid ${alpha(
                  theme.palette.neutral.midLight,
                  0.8
                )}`,
                boxShadow: "none",
                transition: "background-color, border, 80ms ease",
                ...(variant === "outlined" && {
                  //   backgroundColor: alpha(theme.palette.neutral.light, 0.2),
                  //   background: `linear-gradient(to bottom, #FFF, ${theme.palette.primary.light})`,
                  background: `linear-gradient(to bottom, #FFF, ${theme.palette.neutral.light})`,
                  borderColor: theme.palette.primary.mediumMain,
                  // "&:hover": {
                  //   boxShadow: `0 0 24px ${theme.palette.primary.light}`,
                  // },
                }),
                ...(theme.palette.mode === "dark" && {
                  backgroundColor: alpha(theme.palette.neutral.dark, 0.6),
                  border: `1px solid ${alpha(
                    theme.palette.neutral.mediumDark,
                    0.3
                  )}`,
                  ...(variant === "outlined" && {
                    background: `linear-gradient(to bottom, ${
                      theme.palette.neutral.extraDark
                    }, ${alpha(theme.palette.neutral.dark, 0.5)})`,
                    "&:hover": {
                      borderColor: theme.palette.primary.lighterExtraDark,
                      boxShadow: `0 0 24px ${theme.palette.primary.extraDark}`,
                    },
                  }),
                }),
              }}
            >
              <CardContent sx={{ pb: 1 }}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ fontSize: "14px", lineHeight: 1.5 }}
                >
                  {testimonial.testimonial}
                </Typography>
              </CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  pr: 2,
                }}
              >
                <CardHeader
                  avatar={testimonial.avatar}
                  title={testimonial.name}
                  subheader={testimonial.occupation}
                  sx={{ pt: 1 }}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
