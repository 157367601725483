import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

export default function ReadOnlyRating({ ratingValue }) {
  const [value, setValue] = useState(2);

  useEffect(() => {
    setValue(ratingValue);
  }, []);

  return (
    <Box
      sx={{
        "& > legend": { mt: 2 },
      }}
    >
      <Rating name="read-only" value={value} precision={0.5} readOnly />
    </Box>
  );
}
