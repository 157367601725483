import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Button,
  Radio,
  Typography,
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import Dots from "../../../components/Dots";
import { useNavigate } from "react-router-dom";
const OnBoarding2 = () => {
  const theme = useTheme();
  const [readingPreferences, setReadingPreferences] = useState([]);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setReadingPreferences((prev) =>
      prev.includes(value)
        ? prev.filter((preference) => preference !== value)
        : [...prev, value]
    );
  };

  useEffect(() => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    if (onBoardingData.readingPreferences) {
      setReadingPreferences(onBoardingData.readingPreferences);
    }
  }, []);

  const handleNext = () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    onBoardingData.readingPreferences = readingPreferences;
    localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    navigate("/signup/onboarding-step3");

    // Navigate to the next step (OnBoarding3)
  };

  const handleDotsClick = () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    onBoardingData.readingPreferences = readingPreferences;
    localStorage.setItem("onBoarding", JSON.stringify(onBoardingData));
    // Navigate to next step (OnBoarding2)
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isNonMobileScreens ? "80vh" : "70vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          <Typography
            variant="h6"
            sx={{ textAlign: "center", color: theme.palette.neutral.main }}
          >
            Where do you read?
          </Typography>

          <FormGroup sx={{ gap: 1.5 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value="physicalBook"
                  onChange={handleCheckboxChange}
                  checked={readingPreferences.includes("physicalBook")}
                />
              }
              label="Physical Book"
              sx={{
                border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                px: 2,
                py: 0,
                borderRadius: 1,
                m: 0,
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="kindle"
                  onChange={handleCheckboxChange}
                  checked={readingPreferences.includes("kindle")}
                />
              }
              label="Kindle"
              sx={{
                border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                px: 2,
                py: 0,
                borderRadius: 1,
                m: 0,
              }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  value="e-book"
                  onChange={handleCheckboxChange}
                  checked={readingPreferences.includes("e-book")}
                />
              }
              label="E-book"
              sx={{
                border: `solid 2px ${theme.palette.neutral.mediumLight}`,
                px: 2,
                py: 0,
                borderRadius: 1,
                m: 0,
              }}
            />
          </FormGroup>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Button
              fullWidth
              variant="contained"
              size="large"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Next
            </Button>

            <Button
              fullWidth
              size="small"
              variant="outlined"
              color="primary"
              type="submit"
              onClick={handleNext}
            >
              Skip
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots
              navigateTo="/signup/onboarding-step1"
              onClick={handleDotsClick}
            />
            <Dots filled onClick={handleDotsClick} />
            <Dots
              navigateTo="/signup/onboarding-step3"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step4"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step5"
              onClick={handleDotsClick}
            />
            <Dots
              navigateTo="/signup/onboarding-step6"
              onClick={handleDotsClick}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding2;
