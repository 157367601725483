import { Box } from "@mui/material";
import { styled } from "@mui/system";
const CardContentDoublePadding = styled(Box)({
  paddingLeft: "32px",
  paddingRight: "32px",
  paddingTop: "16px",
  paddingBottom: "16px",
});

export default CardContentDoublePadding;
