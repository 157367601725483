import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";

const ContentMargin = ({ children }) => {
  const theme = useTheme();
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const marginStyle = isLandingPage
    ? 0
    : isMobile
    ? "4px 18px 20px 18px"
    : "10px 32px";

  return (
    <Box
      sx={{
        margin: marginStyle,
        display: "flex",
        justifyContent: "center",
        width: isLandingPage ? "100%" : "auto",
      }}
    >
      {children}
    </Box>
  );
};

export default ContentMargin;
