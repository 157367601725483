import React from "react";
import { Button, Box, useMediaQuery, IconButton } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const BookCategories = ({ category, onCategoryChange }) => {
  const categories = [
    { key: "all", label: "All Books" },
    { key: "tbr_unbought", label: "Wishlist" },
    { key: "tbr_owned", label: "To Be Read" },
    { key: "in_progress", label: "Currently Reading" },
    { key: "finished", label: "Finished Reading" },
  ];

  const isNonExtraSmallScreen = useMediaQuery("(max-width:480px)");

  if (isNonExtraSmallScreen) {
    return (
      <Carousel
        animation="slide"
        navButtonsAlwaysVisible={true}
        indicators={false}
        onChange={(index) => onCategoryChange(categories[index].key)}
        index={categories.findIndex((cat) => cat.key === category)}
        autoPlay={false}
        NavButton={({ onClick, className, style, next, prev }) => {
          return (
            <IconButton
              onClick={onClick}
              className={className}
              style={{
                ...style,
                borderRadius: "50%", // Keeps the button round
                backgroundColor: "transparent", // Ensures the background is transparent
                color: "inherit", // Inherits color from parent
                border: "none", // Removes any border
                padding: "10px", // Adds some padding for better touch area
              }}
            >
              {next && <ArrowForwardIosIcon />}
              {prev && <ArrowBackIosNewIcon />}
            </IconButton>
          );
        }}
      >
        {categories.map((cat) => (
          <Button
            key={cat.key}
            variant={category === cat.key ? "contained" : "outlined"}
            onClick={() => onCategoryChange(cat.key)}
            sx={{
              minWidth: 120,
              py: 1,
              textAlign: "center",
              width: "70%", // Adjust as needed
              margin: "0 auto",
              display: "block",
            }}
          >
            {cat.label}
          </Button>
        ))}
      </Carousel>
    );
  }

  // Render default buttons for screens above 420px
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {categories.map((cat) => (
        <Button
          key={cat.key}
          variant={category === cat.key ? "contained" : "outlined"}
          onClick={() => onCategoryChange(cat.key)}
        >
          {cat.label}
        </Button>
      ))}
    </Box>
  );
};

export default BookCategories;
