import React, { useState, useEffect } from "react";
import { Box, TextField, CircularProgress, Button } from "@mui/material";
import { getBooksByGoogleAPI } from "../api/bookApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearch } from "../context/SearchContext";

const BookSearchBar = ({
  bookSearchResult,
  triggerLoading,
  smallLabel,
  onSearch,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { searchQuery, setSearchQuery } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchQuery && searchQuery.trim() !== "") {
      handleSearch(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (searchTerm) {
      queryParams.set("search", searchTerm.trim());
      navigate(`?${queryParams.toString()}`);
    }
  }, [searchTerm, location.search, navigate]);

  const handleSearch = async (query) => {
    if (!query || query.trim() === "") return;

    setIsLoading(true);
    triggerLoading(true);
    setErrorMessage("");

    try {
      const response = await getBooksByGoogleAPI(query);
      if (response && response.books) {
        bookSearchResult(response.books, response.nextCursor, response.hasMore);
        if (onSearch) {
          onSearch(query);
        }
        setSearchQuery(query);
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching books:", error);
      setErrorMessage("An error occurred while fetching books");
      bookSearchResult([], null, false);
    } finally {
      setIsLoading(false);
      triggerLoading(false);
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchTerm.trim() !== "") {
      handleSearch(searchTerm);
    }
  };

  return (
    <Box sx={{ p: "0px 0px 8px 0px" }}>
      <Box
        sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 2 }}
      >
        <Box sx={{ display: "flex", width: "100%", position: "relative" }}>
          <TextField
            fullWidth
            variant="outlined"
            label={
              smallLabel
                ? "By Title or Author"
                : "Search by Book Title, Author, or ISBN"
            }
            className="without-padding"
            size="small"
            value={searchTerm}
            onChange={handleSearchQueryChange}
            onKeyPress={handleKeyPress}
          />
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "50%",
                right: "8px",
                marginTop: "-12px",
              }}
            />
          )}
        </Box>
        <Button
          size="small"
          variant="contained"
          onClick={() => searchTerm.trim() !== "" && handleSearch(searchTerm)}
          disabled={isLoading || searchTerm.trim() === ""}
        >
          Search
        </Button>
      </Box>
      {errorMessage && (
        <Box sx={{ color: "error.main", mt: 1 }}>{errorMessage}</Box>
      )}
    </Box>
  );
};

export default BookSearchBar;
