import { useEffect, useState } from "react";
import { Card, Box, Select, MenuItem, useMediaQuery } from "@mui/material";
import BookCardWidget from "./BookCardWidget";
import { updateTBRStatus } from "../api/tbrCollectionApi";

const TbrBook = ({
  tbrBook,
  initialStatus,
  bookFinished,
  handleUserBooksChange,
}) => {
  const [bookStatus, setBookStatus] = useState(initialStatus);

  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  const handleStatusChange = async (status) => {
    try {
      // Update the status in the local state
      setBookStatus(status);
      // Send the status update to the server
      const result = await updateTBRStatus({
        bookProgressId: tbrBook.bookProgress._id,
        status,
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  useEffect(() => {
    if (bookFinished && initialStatus !== "completed") {
      setBookStatus("completed");
    }
  }, []);

  return (
    <>
      {isNonMobileScreens ? (
        <Card>
          <BookCardWidget
            bookId={tbrBook.book._id}
            userBookData={tbrBook.book}
            onUpdateUserBooksParent={handleUserBooksChange}
            isTbr
            noShadow
            noBorderCurve
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              p: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              value={bookStatus || "pre-ordered"}
              onChange={(e) => handleStatusChange(e.target.value)}
              disabled={bookFinished}
              sx={{
                "& .MuiSelect-select": {
                  py: 0.5,
                  px: 1,
                  ...(bookFinished && {
                    backgroundColor: "green",
                    color: "white",
                  }),
                },
              }}
            >
              <MenuItem value="pre-ordered">Pre-ordered</MenuItem>
              <MenuItem value="owned">Owned</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="unfinished">Unfinished</MenuItem>
            </Select>
          </Box>
        </Card>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <BookCardWidget
            bookId={tbrBook.book._id}
            userBookData={tbrBook.book}
            onUpdateUserBooksParent={handleUserBooksChange}
            isTbr
            noShadow
            noBorderCurve
          />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              p: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Select
              value={bookStatus || "pre-ordered"}
              onChange={(e) => handleStatusChange(e.target.value)}
              disabled={bookFinished}
              sx={{
                "& .MuiSelect-select": {
                  py: 0.5,
                  px: 1,
                  ...(bookFinished && {
                    backgroundColor: "green",
                    color: "white",
                  }),
                },
              }}
            >
              <MenuItem value="pre-ordered">Pre-ordered</MenuItem>
              <MenuItem value="owned">Owned</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="in-progress">In Progress</MenuItem>
              <MenuItem value="unfinished">Unfinished</MenuItem>
            </Select>
          </Box>
        </Box>
      )}
    </>
  );
};

export default TbrBook;
