import React from "react";
import { Box, useTheme, Card, useMediaQuery, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CardContentPadding from "../../../components/CardContentPadding";
import SignUpForm from "./SignUpForm";

const SignUp = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        height: "80vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box
          component="a"
          onClick={() => navigate("/")}
          sx={{
            cursor: "pointer",
            "&:hover": {
              opacity: 0.8,
            },
          }}
        >
          <img
            src="/assets/rg-new-icon.webp"
            alt="ReadersGuild's logo"
            style={{ display: "flex", height: "69.8px", width: "70px" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontWeight: "500", color: theme.palette.neutral.main }}
          >
            Welcome to ReadersGuild
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "300",
              color: theme.palette.neutral.main,
              fontSize: "14px",
            }}
          >
            Log in or register with your email
          </Typography>
        </Box>
        <Card
          elevation={6}
          sx={{
            width: "full",
            maxWidth: "full",
            backgroundColor: "whitesmoke",
            borderStyle: "solid",
            borderWidth: "0.5px",
            borderColor: "lightGray",
          }}
        >
          <CardContentPadding sx={{ p: "36px" }}>
            <SignUpForm />
          </CardContentPadding>
        </Card>
      </Box>
    </Box>
  );
};

export default SignUp;
