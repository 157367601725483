import {
  Box,
  Card,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  useScrollTrigger,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import FeaturedOn from "./FeaturedOn";

import Features from "./Features";
import Testimonials from "./Testimonials";
import Pricing from "./Pricing";
import Footer from "./Footer";
import FAQ from "./FAQ";
import Benefits from "./Benefits";
import LandingPageWrapper from "./LandingPageWrapper";

const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");

  /*Things to be added:
  - Testimonials, maybe quotes from users: "this app has transformed my reading habits!", with visuals of photos of users or avatars with their testimonials.
  -How it works, step by step guide:
 sign up -> log your reading -> join groups -> share and discuss maybe simple graphics or videos for each steps
  - Benefits section: Improve Reading Habits: "Track your progress and stay motivated."
Community Engagement: "Join discussions and share insights."
Discover New Books: "Get recommendations from your friends' TBR lists."
  - FAQ
  Common questions user might have about the app, such as "is it free" or "how do i join reading group?"
  - add call to action in the footer
  
  */

  return (
    <LandingPageWrapper>
      <>
        {isNonMobileScreens ? (
          <Container>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 9,
                  my: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 3,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <FavoriteIcon color="primary" />
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontSize: "14px",
                        color: theme.palette.neutral.mediumLight,
                        fontWeight: 500,
                      }}
                    >
                      by readers
                    </Typography>
                  </Box>
                  <Box sx={{ maxWidth: "70%" }}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 900,
                        color: theme.palette.neutral.dark,
                        textAlign: "center",
                      }}
                    >
                      Track your books with friends
                    </Typography>
                  </Box>
                  <Box sx={{ maxWidth: "60%" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontWeight: 400,
                        color: theme.palette.neutral.medium,
                        textAlign: "center",
                      }}
                    >
                      Create your own book club or join one, curate your TBR,
                      and gain more insights through reading discussion, share
                      your reading journey.
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ p: 0.5 }}>
                  <Button
                    size="Large"
                    sx={{
                      py: 2,
                      px: 3,
                      boxShadow: 6,
                      backgroundColor: theme.palette.neutral.mediumDark,
                      color: "white",
                      "&:hover": {
                        boxShadow: 0,
                        backgroundColor: theme.palette.neutral.mediumDark,
                      },
                    }}
                    onClick={() => navigate("/signup")}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      Join for free
                    </Typography>
                  </Button>
                </Box>
                <FeaturedOn />
                <Card
                  sx={{
                    backgroundColor: theme.palette.neutral.light,
                    p: 0,
                    m: 0,
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assets/dashboard-screenshot.webp"
                      alt="a screenshot of the dashboard"
                      width="900px"
                      style={{ borderRadius: "6px", padding: "0px" }}
                    />
                  </Box>
                </Card>
              </Box>
              <Box>
                <section id="features">
                  {" "}
                  <Features />
                </section>
                <section id="testimonials">
                  <Testimonials />
                </section>
                <section id="benefits">
                  <Benefits />
                </section>
                {/* <section id="pricing">
                  <Pricing />
                </section> */}
                <section id="faq">
                  <FAQ />
                </section>
              </Box>
              <Footer />
            </Box>
          </Container>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: isSuperSmallScreen ? 4 : 6,
                my: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: isSuperSmallScreen ? 2 : 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <FavoriteIcon fontSize="small" color="primary" />
                  <Typography
                    variant="body3"
                    sx={{
                      // fontSize: "20px",
                      color: theme.palette.neutral.mediumLight,
                      fontWeight: 500,
                    }}
                  >
                    by readers
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: isSuperSmallScreen ? "90%" : "80%" }}>
                  <Typography
                    variant={isSuperSmallScreen ? "h5" : "h4"}
                    sx={{
                      fontSize: isSuperSmallScreen && "32px",
                      fontWeight: 900,
                      color: theme.palette.neutral.dark,
                      textAlign: "center",
                    }}
                  >
                    Track your books with friends and create your own book club.
                  </Typography>
                </Box>
                <Box sx={{ maxWidth: isSuperSmallScreen ? "80%" : "60%" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 400,
                      color: theme.palette.neutral.mainDark,
                      textAlign: "center",
                    }}
                  >
                    C club. club or join one, curate your TBR, and gain more
                    insights. through reading discussion,share your reading
                    journey.
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ p: 0.5 }}>
                <Button
                  size="large"
                  sx={{
                    py: 1.5,
                    px: 3,
                    boxShadow: 6,
                    backgroundColor: theme.palette.neutral.mediumDark,
                    color: "white",
                    "&:hover": {
                      boxShadow: 0,
                      backgroundColor: theme.palette.neutral.mediumDark,
                    },
                  }}
                  onClick={() => navigate("/signup")}
                >
                  <Typography variant="h6" sx={{ fontWeight: "600" }}>
                    Join for free
                  </Typography>
                </Button>
              </Box>
              <FeaturedOn mobile />
              <Card
                sx={{
                  backgroundColor: theme.palette.neutral.light,
                  p: 0,
                  m: 0,
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="assets/dashboard-screenshot.webp"
                    alt="a screenshot of the dashboard"
                    style={{
                      borderRadius: "6px",
                      padding: "0px",
                      width: "100%",
                      maxWidth: "900px",
                    }}
                  />
                </Box>
              </Card>
              <Box>
                <section id="features">
                  <Features />
                </section>
                <section id="testimonials">
                  <Testimonials />
                </section>
                <section id="benefits">
                  <Benefits />
                </section>
                {/* <section id="pricing">
                  <Pricing />
                </section> */}
                <section id="faq">
                  <FAQ />
                </section>
              </Box>
            </Box>
            <Footer />
          </Box>
        )}
      </>
    </LandingPageWrapper>
  );
};

export default LandingPage;
