import { Box, useTheme } from "@mui/material";

const RadialProgressChart = ({ progress = "0.75", size = "65" }) => {
  // Access the MUI theme
  const theme = useTheme();

  // Get the primary color and general disabled color from the theme
  const primaryColor = theme.palette.primary.main;
  const greyColor = theme.palette.neutral.medium;

  // Calculate the angle based on progress
  const angle = progress * 360;

  return (
    <Box
      sx={{
        background: `radial-gradient(${
          theme.palette.mode === "dark"
            ? `${theme.palette.neutral.mediumLight} 52%, transparent 54%`
            : "white 53%, transparent 55%"
        }), conic-gradient(transparent 0deg ${angle}deg, ${greyColor} ${angle}deg 360deg), ${primaryColor}`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    />
  );
};

export default RadialProgressChart;
