import React, { lazy, Suspense, useMemo } from "react";
import {
  Box,
  Card,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import LandingPageWrapper from "./LandingPageWrapper";

// Optimized imports using dynamic imports with prefetch for critical components
const FeaturedOn = lazy(() =>
  import(/* webpackPrefetch: true */ "./FeaturedOn")
);
const Features = lazy(() => import(/* webpackPrefetch: true */ "./Features"));
const Testimonials = lazy(() => import("./Testimonials"));
const Benefits = lazy(() => import("./Benefits"));
const FAQ = lazy(() => import("./FAQ"));
const Footer = lazy(() => import("./Footer"));

// Optimized loading spinner using CSS
const LoadingSpinner = () => (
  <div className="loading-spinner" aria-label="Loading content"></div>
);

// Optimized image component with lazy loading and WebP support
const OptimizedImage = ({ src, alt, width, height, priority = false }) => {
  const webpSrc = src.replace(/\.(png|jpg|jpeg)$/, ".webp");
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        paddingTop: `${(height / width) * 100}%`,
      }}
    >
      <picture>
        <source srcSet={webpSrc} type="image/webp" />
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          loading={priority ? "eager" : "lazy"}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "contain",
            borderRadius: "6px",
          }}
        />
      </picture>
    </div>
  );
};

const LandingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");

  const heroContent = useMemo(
    () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: isNonMobileScreens ? 9 : isSuperSmallScreen ? 4 : 6,
          my: isNonMobileScreens ? 3 : 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxWidth: isNonMobileScreens
                ? "70%"
                : isSuperSmallScreen
                ? "90%"
                : "80%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: !isSuperSmallScreen ? "center" : "start-flex",
                gap: 1,
                mb: 2,
              }}
            >
              <FavoriteIcon
                color="primary"
                fontSize={isSuperSmallScreen ? "small" : "medium"}
              />
              <Typography
                variant={isSuperSmallScreen ? "body2" : "subtitle1"}
                sx={{
                  color: theme.palette.neutral.medium,
                  fontWeight: 500,
                }}
              >
                by readers
              </Typography>
            </Box>
            <Typography
              variant={
                isNonMobileScreens ? "h2" : isSuperSmallScreen ? "h5" : "h4"
              }
              sx={{
                fontWeight: 900,
                color: theme.palette.neutral.dark,
                textAlign: !isSuperSmallScreen && "center",
                fontSize: isSuperSmallScreen ? "28px" : undefined,
              }}
            >
              {/* Build reading habits, journal books, join book clubs */}
              Track your books
            </Typography>
            <Typography
              variant={
                isNonMobileScreens ? "h2" : isSuperSmallScreen ? "h5" : "h4"
              }
              sx={{
                fontWeight: 900,
                color: theme.palette.neutral.dark,
                textAlign: !isSuperSmallScreen && "center",
                fontSize: isSuperSmallScreen ? "28px" : undefined,
              }}
            >
              {/* Build reading habits, journal books, join book clubs */}
              with friends or solo
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 400,
                fontSize: isSuperSmallScreen && "11px",
                color: theme.palette.neutral.dark,
                position: "absolute",
                bottom: "-6px",
                right: !isSuperSmallScreen ? "-90px" : "-45px",
                transform: "rotate(-5deg)", // Adjust angle as desired
                transformOrigin: "right", // Sets the rotation point
                fontFamily: "Gloria Hallelujah",
              }}
            >
              Your choice though 😏
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: isNonMobileScreens
                ? "60%"
                : isSuperSmallScreen
                ? "80%"
                : "60%",
            }}
          >
            <Typography
              variant={
                isNonMobileScreens
                  ? "h6"
                  : !isSuperSmallScreen
                  ? "body1"
                  : "body2"
              }
              sx={{
                fontWeight: 400,
                color: theme.palette.neutral.medium,
                textAlign: !isSuperSmallScreen ? "center" : "flex-start",
              }}
            >
              Build your reading habit or track a library of books. Share your
              thoughts and connect with other readers, as social or solo as you
              like.
            </Typography>
          </Box>
        </Box>
        <Button
          size={isNonMobileScreens ? "large" : "medium"}
          sx={{
            py: isNonMobileScreens ? 2 : 1,
            px: isNonMobileScreens ? 3 : 1.5,
            boxShadow: 6,
            backgroundColor: theme.palette.neutral.mediumDark,
            color: "white",
            "&:hover": {
              boxShadow: 0,
              backgroundColor: theme.palette.neutral.mediumDark,
            },
          }}
          onClick={() => navigate("/signup")}
        >
          <Typography
            variant={isNonMobileScreens ? "h6" : "body1"}
            sx={{ fontWeight: "600" }}
          >
            Join for free
          </Typography>
        </Button>
      </Box>
    ),
    [isNonMobileScreens, isSuperSmallScreen, theme, navigate]
  );

  const dashboardScreenshot = useMemo(
    () => (
      <Card sx={{ backgroundColor: theme.palette.neutral.light, p: 0, m: 0 }}>
        <Box sx={{ p: 1 }}>
          <OptimizedImage
            src="/assets/dashboard-screenshot.webp"
            alt="Dashboard screenshot"
            width={900}
            height={415}
            priority={true}
          />
        </Box>
      </Card>
    ),
    [theme.palette.neutral.light]
  );

  return (
    <LandingPageWrapper>
      <Container maxWidth={isNonMobileScreens ? "lg" : "sm"} sx={{ p: 0 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: !isNonMobileScreens && 3,
          }}
        >
          {heroContent}
          <Suspense fallback={<LoadingSpinner />}>
            <FeaturedOn mobile={!isNonMobileScreens} />
          </Suspense>
          {dashboardScreenshot}
        </Box>
      </Container>
      <Suspense fallback={<LoadingSpinner />}>
        <Features />
        <Testimonials />
        <Benefits />
        <FAQ />
        <Footer />
      </Suspense>
    </LandingPageWrapper>
  );
};

export default React.memo(LandingPage);
