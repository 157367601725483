// UserContext.js
import React, { createContext, useState, useEffect } from "react";
// import { getUserProfileData } from "../api/userApi";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState({
    _id: "",
    provider: "",
    name: "",
    email: "",
    avatar: "",
    location: "",
    registrationDate: "",
    totalPagesRead: 0,
    annualGoal: 0,
    books: [],
    userAmtOfBooks: 0,
    currentAnnualBooksFinished: 0,
    lastYearBooksFinished: 0,
    lastMonthPagesRead: 0,
    currentReadingStreak: 0,
    bestReadingStreak: 0,
    amountOfBookRated: 0,
    cancellationDate: "",
    isPaidUser: false,
    uniqueTag: "",
    rooms: [],
    subscriptionStatus: "",
    subscriptionStartDate: "",
    subscriptionEndDate: "",
    currentPlan: "",
    isAdmin: false,
    receiveMarketingEmails: true,
    readingPreferences: [],
    isAnnouncementShowed: false,
    lifetimeAccess: false,
    // Add other user profile data here
  });

  const updateUserProfile = (userProfileData) => {
    const {
      _id,
      provider,
      name,
      email,
      avatar,
      location,
      registrationDate,
      totalPagesRead,
      annualGoal,
      books,
      currentAnnualBooksFinished,
      annualBooksFinished,
      rooms,
      monthlyPagesRead,
      currentReadingStreak,
      bestReadingStreak,
      amountOfBookRated,
      isPaidUser,
      cancellationDate,
      subscriptionStatus,
      subscriptionStartDate,
      subscriptionEndDate,
      currentPlan,
      isAdmin,
      receiveMarketingEmails,
      readingPreferences,
      uniqueTag,
      isAnnouncementShowed,
      lifetimeAccess,
    } = userProfileData;
    const userAmtOfBooks = books ? books.length : 0;
    const parsedRegistrationDate = registrationDate
      ? new Date(registrationDate)
      : null;
    const formattedRegistrationDate = parsedRegistrationDate
      ? new Intl.DateTimeFormat("en-US", {
          month: "short",
          year: "numeric",
        }).format(parsedRegistrationDate)
      : "";
    /* last year books finished */
    const lastYearBooksFinished = annualBooksFinished
      ? annualBooksFinished.length > 0
        ? annualBooksFinished[annualBooksFinished.length - 1].booksFinished
        : 0
      : 0;

    /* last month pages read */
    const lastMonthPagesRead = monthlyPagesRead
      ? monthlyPagesRead.length > 0
        ? monthlyPagesRead[monthlyPagesRead.length - 1].pagesRead
        : 0
      : 0;

    setUserProfile({
      _id,
      provider,
      name,
      email,
      avatar,
      location,
      registrationDate: formattedRegistrationDate,
      totalPagesRead,
      annualGoal,
      userAmtOfBooks,
      currentAnnualBooksFinished,
      rooms,
      uniqueTag,
      lastYearBooksFinished,
      lastMonthPagesRead,
      currentReadingStreak,
      bestReadingStreak,
      amountOfBookRated,
      isAnnouncementShowed,
      //not covered yet in the FE
      isPaidUser,
      //not covered yet in the FE
      isAdmin,
      // not covered yet in the FE
      receiveMarketingEmails,
      //not covered yet in the FE
      readingPreferences,
      //not covered yet in the FE
      //if inactive, inform user to update payment method
      cancellationDate,
      subscriptionStatus,
      subscriptionStartDate,
      subscriptionEndDate,
      //not covered yet in the FE
      //used for authorization
      currentPlan,
      lifetimeAccess,
    });
  };
  console.log("check room in context", userProfile.rooms, userProfile.name);

  return (
    <UserContext.Provider value={{ userProfile, updateUserProfile }}>
      {children}
    </UserContext.Provider>
  );
};
