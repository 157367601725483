import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  useTheme,
  FormControl,
  TextField,
  Rating,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import AddRating from "../../components/AddRating";
import CardContentPadding from "../../components/CardContentPadding";
import { getBook } from "../../api/bookApi";
import ReadOnlyRating from "../../components/ReadOnlyRating";
import { postBookReview } from "../../api/reviewApi";
import { getUserRatingOnBook } from "../../api/reviewApi";
import { useNavigate } from "react-router-dom";
import languageCodeMap from "../../utility/languageCodeMap";
import { UserContext } from "../../context/UserContext";
import BookStatusSelect from "../../components/BookStatusSelect";

const RatingDialog = ({ open, onClose, bookId, initialRatingValue = 0 }) => {
  const theme = useTheme();
  const [comment, setComment] = useState("");
  const [ratingValue, setRatingValue] = useState(initialRatingValue);
  const navigate = useNavigate();
  const { updateUserProfile } = useContext(UserContext);

  const handleRatingChange = (newValue) => {
    setRatingValue(newValue);
  };
  const handleCommentChange = (e) => {
    const newValue = e.target.value;
    setComment(newValue);
  };
  const handleSaveReview = async () => {
    const result = await postBookReview(comment, ratingValue, bookId);
    if (result.success === true) {
      updateUserProfile(result.user);
      localStorage.setItem("userData", JSON.stringify(result.user));
    }
    onClose();
    navigate(`/marketplace/` + bookId);
  };

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>Rate This Book</DialogTitle>
      <DialogContent>
        <DialogContentText
          variant="body2"
          gutterBottom
          sx={{ color: theme.palette.neutral.main, fontWeight: 500 }}
        >
          Change Rating :
        </DialogContentText>
        <AddRating
          onUpdateRating={handleRatingChange}
          initialValue={initialRatingValue}
        />
        {/* <DialogContentText>
          Enter your new yearly book goal below:
        </DialogContentText> */}
        <FormControl fullWidth>
          <TextField
            autoFocus
            margin="dense"
            name="comment"
            label="What do you think about the book?"
            fullWidth
            value={comment}
            onChange={(e) => handleCommentChange(e)}
            multiline
            variant="filled"
            rows={6}
            sx={{ display: "flex" }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSaveReview}
          color="primary"
          disabled={!ratingValue}
        >
          Post
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BookProfileWidget = ({
  bookId,
  bookProfile,
  bookProfileStatus,
  bookTotalPages = 256,
}) => {
  const [status, setStatus] = useState(bookProfileStatus);
  const [book, setBook] = useState(bookProfile);
  const [userRating, setUserRating] = useState(0);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const theme = useTheme();

  useEffect(() => {
    const fetchUserRating = async () => {
      try {
        const result = await getUserRatingOnBook(bookId);
        setUserRating(result.data);
      } catch (error) {
        console.error("Error fetching user's review on book", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserRating();
  }, [bookId]);

  const handleStatusChange = (newStatus) => setStatus(newStatus);
  const handleOpenDialog = () => setDialogOpen(true);
  const handleCloseDialog = () => setDialogOpen(false);

  // const fetchBookData = async () => {
  //   try {
  //     const result = await getBook(bookId);
  //     setBook(result.data);
  //   } catch (error) {
  //     console.error("Error fetching books:", error);
  //   }
  // };

  // const handleStatusChange = (newStatus) => {
  //   setBookStatus(newStatus);
  //   // Update user profile if necessary
  //   // updateUserProfile(result.user);
  //   // localStorage.setItem("userData", JSON.stringify(result.user));
  // };

  return (
    <Box>
      <Card>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <>
            {isNonMobileScreens ? (
              <CardContentPadding>
                <Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box
                      sx={{
                        boxShadow: 3,
                        width: 160,
                        minWidth: 120,
                        height: "100%",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={
                          book.cover?.large ||
                          book.cover?.medium ||
                          book.cover?.small ||
                          "/assets/book-sample.jpg"
                        }
                        alt={`Book Cover of ${book.title}`}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                          {book.title || `The Psychology of Money`}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: theme.palette.neutral.medium,
                            fontWeight: 600,
                          }}
                        >
                          By{" "}
                          {book.authors.length > 1
                            ? book.authors.join(", ")
                            : book.authors[0]}
                        </Typography>
                      </Box>
                      <BookStatusSelect
                        bookId={bookId}
                        initialStatus={status}
                        onStatusChange={handleStatusChange}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body3">Your Rating :</Typography>
                        <Box>
                          <IconButton
                            onClick={handleOpenDialog}
                            sx={{ borderRadius: 1, p: "0px" }}
                          >
                            <ReadOnlyRating ratingValue={userRating} />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {book.genre &&
                          book.genre.map((genreTag) => (
                            <Chip
                              label={genreTag}
                              size="small"
                              color="default"
                              sx={{ height: 20, fontSize: "0.7rem" }}
                            />
                          ))}

                        {/* <BookTag label="finance" color="warning" size="small" /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        <Typography variant="body2">
                          <span style={{ fontWeight: "bold" }}>ISBN-10: </span>
                          {book.isbn10}
                        </Typography>
                        <Typography variant="body2">
                          <span style={{ fontWeight: "bold" }}>ISBN-13: </span>
                          {book.isbn13}
                        </Typography>
                        {book.language && (
                          <Typography variant="body2">
                            <span style={{ fontWeight: "bold" }}>
                              Language:{" "}
                            </span>
                            {languageCodeMap[book.language]}
                          </Typography>
                        )}
                      </Box>
                      <Typography variant="caption">
                        {book.pages || 1} PAGES
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <RatingDialog
                  open={isDialogOpen}
                  onClose={handleCloseDialog}
                  bookId={bookId}
                  initialRatingValue={userRating}
                  // onSave={handleSaveReview}
                  // initialValue={userProfile.annualGoal}
                />
              </CardContentPadding>
            ) : (
              <CardContentPadding>
                <Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box
                      sx={{
                        boxShadow: 3,
                        width: 120,
                        minWidth: 120,
                        height: "100%",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={
                          book.cover?.large ||
                          book.cover?.medium ||
                          book.cover?.small ||
                          "/assets/book-sample.jpg"
                        }
                        alt={`Book Cover of ${book.title}`}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        {" "}
                        <Typography
                          variant="h6"
                          sx={{ fontSize: "14px", fontWeight: "bold" }}
                        >
                          {book.title || `The Psychology of Money`}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            const: theme.palette.neutral.medium,
                            fontSize: "12px",
                            fontWeight: 600,
                          }}
                        >
                          By{" "}
                          {book.authors.length > 1
                            ? book.authors.join(", ")
                            : book.authors[0]}
                        </Typography>
                      </Box>
                      <BookStatusSelect
                        bookId={bookId}
                        initialStatus={status}
                        onStatusChange={handleStatusChange}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant="caption">Your Rating :</Typography>
                        <Box>
                          <IconButton
                            onClick={handleOpenDialog}
                            sx={{ borderRadius: 1, p: "0px" }}
                          >
                            <ReadOnlyRating ratingValue={userRating} />
                          </IconButton>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {book.genre &&
                          book.genre.map((genreTag) => (
                            <Chip
                              key={genreTag}
                              label={genreTag}
                              size="small"
                              color="default"
                              sx={{
                                height: 20,
                                fontSize: "0.7rem",
                              }}
                            />
                          ))}

                        {/* <BookTag label="finance" color="warning" size="small" /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                        }}
                      >
                        {book.language && (
                          <Typography variant="body2">
                            <span style={{ fontWeight: "bold" }}>
                              Language:{" "}
                            </span>
                            {languageCodeMap[book.language]}
                          </Typography>
                        )}
                      </Box>
                      <Typography variant="caption">
                        {book.pages || 1} PAGES
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                <RatingDialog
                  open={isDialogOpen}
                  onClose={handleCloseDialog}
                  bookId={bookId}
                  initialRatingValue={userRating}
                  // onSave={handleSaveReview}
                  // initialValue={userProfile.annualGoal}
                />
              </CardContentPadding>
            )}
          </>
        )}
      </Card>
    </Box>
  );
};

export default BookProfileWidget;
