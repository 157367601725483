import { useState } from "react";
import { Typography, useTheme } from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import NotesIcon from "@mui/icons-material/Notes";
import ListAltIcon from "@mui/icons-material/ListAlt";

export default function ToggleLogsNotes({ value, onChange }) {
  const theme = useTheme();

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      onChange(event, newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleChange}
      aria-label="Log or Notes view"
      size="small"
      sx={{
        backgroundColor: theme.palette.background.paper,
        "& .MuiToggleButtonGroup-grouped": {
          border: `1px solid ${theme.palette.divider}`,
          "&:not(:first-of-type)": {
            borderLeft: `1px solid ${theme.palette.divider}`,
          },
        },
      }}
    >
      <ToggleButton
        value="notes"
        aria-label="notes"
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          },
        }}
      >
        <NotesIcon sx={{ mr: 1 }} />
        <Typography variant="button">Notes</Typography>
      </ToggleButton>
      <ToggleButton
        value="logs"
        aria-label="logs"
        sx={{
          "&.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            "&:hover": {
              backgroundColor: theme.palette.primary.dark,
            },
          },
        }}
      >
        <ListAltIcon sx={{ mr: 1 }} />
        <Typography variant="button">Logs</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
