import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  Button,
  useTheme,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BookSearchBar from "./BookSearchBar";
import CardContentDoublePadding from "./CardContentDoublePadding";

const BookSearchCard = ({
  onBookSelect,
  currentBook,
  resetSearch = false,
  onClose,
}) => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (resetSearch) {
      setSearchResults([]);
      setSelectedBook(null);
    }
  }, [resetSearch]);

  const handleBookSearchResult = (results) => {
    setSearchResults(results);
    setSelectedBook(null);
  };

  const handleBookChoice = (book) => {
    setSelectedBook(book);
  };

  const confirmBookSelection = () => {
    if (selectedBook) {
      onBookSelect(selectedBook);
      setSearchResults([]);
      setSelectedBook(null);
    }
  };

  const triggerLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const defaultCover = "/assets/book-sample.jpg";

  return (
    <Card>
      <CardContentDoublePadding>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "600" }}>
            {currentBook ? "Switch Book" : "Book Search"}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
        <BookSearchBar
          bookSearchResult={handleBookSearchResult}
          smallLabel
          triggerLoading={triggerLoading}
        />
        {searchResults.length > 0 && !selectedBook && (
          <List sx={{ maxHeight: 300, overflow: "auto", mt: 2 }}>
            {searchResults.map((book) => (
              <ListItem
                key={book.id}
                alignItems="flex-start"
                button
                onClick={() => handleBookChoice(book)}
              >
                <ListItemAvatar sx={{ mr: 1 }}>
                  <img
                    src={
                      book?.cover?.large ||
                      book?.cover?.medium ||
                      book?.cover?.small ||
                      defaultCover
                    }
                    alt={book.title}
                    style={{ height: 92, width: 68, objectFit: "contain" }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={book.title}
                  secondary={book.authors?.join(", ")}
                />
              </ListItem>
            ))}
          </List>
        )}
        {selectedBook && (
          <Box sx={{ mt: 2, textAlign: "center" }}>
            <Typography variant="subtitle1">
              {currentBook ? "Switch to:" : "Selected:"} {selectedBook.title}
            </Typography>
            <Button
              onClick={confirmBookSelection}
              variant="contained"
              color="primary"
              sx={{ mt: 1 }}
            >
              {currentBook ? "Confirm Book Switch" : "Confirm Book Selection"}
            </Button>
          </Box>
        )}
      </CardContentDoublePadding>
    </Card>
  );
};

export default BookSearchCard;
