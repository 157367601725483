import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  Card,
  CardContent,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Chip,
} from "@mui/material";
import { Formik } from "formik";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as yup from "yup";
import { logApi } from "../../../api";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import UpdateJournalDialog from "./UpdateJournalDialog";

const ReadingLogMode = ({
  fetchRecentLog,
  bookTotalPages,
  bookFinished,
  OnUpdateRecentLog,
}) => {
  const [lastPageRead, setLastPageRead] = useState(0);
  const { bookId } = useParams();
  const theme = useTheme();
  const { updateUserProfile } = useContext(UserContext);
  const [openClearDialog, setOpenClearDialog] = useState(false);
  const [openLogDialog, setOpenLogDialog] = useState(false);
  const isMobileScreen = useMediaQuery("(max-width:600px)");

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchRecentLog();
        setLastPageRead(result);
      } catch (error) {
        console.error("Error fetching recent log:", error);
      }
    };

    fetchData();
  }, [fetchRecentLog]);

  const handleOpenClearDialog = () => {
    setOpenClearDialog(true);
  };

  const handleCloseClearDialog = () => {
    setOpenClearDialog(false);
  };

  const handleOpenLogDialog = () => {
    setOpenLogDialog(true);
  };

  const handleCloseLogDialog = () => {
    setOpenLogDialog(false);
  };

  const handleLogSubmit = async (values) => {
    const userTimezone = dayjs.tz.guess();
    const theLastPage = values.lastPageRead;
    //if you want to limit the minimum page that can be added by user
    // the changes made because some user want to re-read their old books
    // const theLastPage = Math.max(values.lastPageRead, lastPageRead);
    const isBookFinished = theLastPage >= bookTotalPages;

    const updatedValues = {
      ...values,
      postDate: dayjs(values.postDate).tz("UTC").format(),
      userTimezone: userTimezone,
      startPageRead: lastPageRead,
      lastPageRead: theLastPage,
    };

    const result = await logApi.postReadingLog(
      updatedValues,
      bookId,
      isBookFinished
    );
    OnUpdateRecentLog(result.data.readingLog);
    updateUserProfile(result.data.user);
    localStorage.setItem("userData", JSON.stringify(result.data.user));

    setLastPageRead(theLastPage);
    handleCloseLogDialog();
  };

  const handleClearLog = () => {
    setLastPageRead(0);
    handleCloseClearDialog();
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="large"
        fullWidth
        onClick={handleOpenLogDialog}
      >
        Update Journal
      </Button>
      <UpdateJournalDialog
        open={openLogDialog}
        onClose={handleCloseLogDialog}
        onSubmit={handleLogSubmit}
        lastPageRead={lastPageRead}
        bookTotalPages={bookTotalPages}
        bookId={bookId}
      />

      <Dialog
        open={openClearDialog}
        onClose={handleCloseClearDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Clear reading log?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action will clear your current reading progress for this book.
            Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseClearDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClearLog} color="error" autoFocus>
            Clear
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReadingLogMode;
