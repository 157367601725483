import { Box, Button } from "@mui/material";
import React from "react";
import DateInput from "../../../components/DateInput";
import NumberInput from "../../../components/NumberInput";
import Stopwatch from "./Stopwatch";

const LiveSessionForm = () => {
  return (
    <Box>
      <Box>
        Date, Start Time, End Time, Reading Duration <br />
        Start Page, End Page, Total Pages Read, current Page (for ongoing
        books), Notes,
      </Box>
      <Box>
        Stopwatch
        <Stopwatch />
        Insert Date
        <DateInput />
        <br />
        {/* if book progress = 0%, means just starting,
        remove insert starting page field */}
        Insert Starting Page
        <NumberInput />
        <br />
        Insert Current Ending Page
        <NumberInput />
        <br />
      </Box>
      <Box>
        <Button variant="contained">Save Session</Button>
      </Box>
    </Box>
  );
};

export default LiveSessionForm;
