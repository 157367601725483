import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";
import LineChart from "../../components/LineChart";
import ToggleTwoChoices from "../../components/ToggleTwoChoices";
import FlexBetween from "../../components/FlexBetween";

const LineChartWidget = ({ onAlignmentChange, lineData, noCard = false }) => {
  const [alignment, setAlignment] = useState("Weekly");
  const [isLoading, setIsLoading] = useState(true);
  const [adjustedLineData, setAdjustedLineData] = useState([]);
  const theme = useTheme();

  const MIN_Y_SCALE = 10; // Minimum Y-axis scale

  useEffect(() => {
    if (
      lineData &&
      lineData.length > 0 &&
      lineData[0].data &&
      lineData[0].data.length > 0
    ) {
      setIsLoading(false);
      adjustLineData();
    } else {
      setIsLoading(true);
    }
  }, [lineData]);

  const adjustLineData = () => {
    if (!lineData || lineData.length === 0) return;

    const maxValue = Math.max(...lineData[0].data.map((point) => point.y));
    const yScale = Math.max(maxValue, MIN_Y_SCALE);

    const adjusted = lineData.map((series) => ({
      ...series,
      data: series.data.map((point) => ({
        ...point,
        y: Math.round(point.y), // Round to nearest integer
      })),
    }));

    setAdjustedLineData(adjusted);
  };

  const handleAlignmentChange = (newAlignment) => {
    setAlignment(newAlignment);
    onAlignmentChange(newAlignment);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    if (
      !adjustedLineData ||
      adjustedLineData.length === 0 ||
      !adjustedLineData[0].data ||
      adjustedLineData[0].data.length === 0
    ) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <Typography>No data available</Typography>
        </Box>
      );
    }
    return (
      <LineChart
        lineData={adjustedLineData}
        yScale={{
          type: "linear",
          min: 0,
          max: Math.max(
            MIN_Y_SCALE,
            Math.max(...adjustedLineData[0].data.map((point) => point.y))
          ),
          tickSize: 1,
        }}
      />
    );
  };

  const chartContent = (
    <Box>
      <FlexBetween>
        <Typography variant="body1" sx={{ fontWeight: 600 }}>
          Pages per day
        </Typography>
        <ToggleTwoChoices
          toggle1="Weekly"
          toggle2="Monthly"
          value={alignment}
          onAlignmentChange={handleAlignmentChange}
        />
      </FlexBetween>
      <Typography
        sx={{ color: theme.palette.neutral.medium, mb: 3 }}
        variant="body3"
      >
        {alignment}
      </Typography>
      {renderContent()}
    </Box>
  );

  if (noCard) {
    return chartContent;
  }

  return (
    <Card>
      <CardContent>{chartContent}</CardContent>
    </Card>
  );
};

export default LineChartWidget;
