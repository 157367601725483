import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Grid,
  Box,
  Button,
  Stack,
  useTheme,
  Dialog,
  DialogTitle,
  List,
  Select,
  ListItem,
  Card,
  Divider,
  CardContent,
  IconButton,
  ListItemButton,
  ListItemText,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addBookToUserLibrary,
  postGoogleBookToDBAndUser,
} from "../../api/userApi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  removeBookFromUserCollection,
  updateBookMetadata,
} from "../../api/bookApi";
import { UserContext } from "../../context/UserContext";
import { useLoading } from "../../context/LoadingContext";
import { addBookToTBRCollection } from "../../api/tbrCollectionApi";
import EditBookDataDialog from "../../components/EditBookDataDialog";
import { updateBookStatusInUserLibrary } from "../../api/userApi";
import CloseIcon from "@mui/icons-material/Close";
import { useSubscription } from "../../hooks/useSubscription";
import UpgradeDialog from "../../components/UpgradeDialog";
import CardContentPadding from "../../components/CardContentPadding";

const BookSettingDialog = ({
  onUpdateUserBooks,
  open,
  onClose,
  selectedValue,
  userBookData,
  bookStatus,

  bookId,
  onStatusChange,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [bookData, setBookData] = useState(userBookData);
  const { updateUserProfile } = useContext(UserContext);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [status, setStatus] = useState(userBookData.status || "tbr_unbought"); // Add state for status
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    setStatus(bookStatus);
    // setBookData(userBookData);
  }, [userBookData]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleBookClick = (e) => {
    navigate(`/library/session/` + bookId);
    onClose();
  };

  const handleRemoveBookClick = async (e) => {
    const result = await removeBookFromUserCollection(bookId);
    if (result.success === true) {
      onUpdateUserBooks(bookId);
      updateUserProfile(result.user);
      localStorage.setItem("userData", JSON.stringify(result.user));
    }
    onClose();
  };

  const defaultTbrStatus = "pre-ordered";
  const handleSaveBookToTBR = async () => {
    try {
      const result = await addBookToTBRCollection(
        year,
        bookId,
        defaultTbrStatus
      );
      if (result.success === true) {
      }
      onClose();
    } catch (error) {
      console.error("Error adding book to TBR:", error);
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    try {
      const result = await updateBookStatusInUserLibrary(bookId, newStatus);
      if (result.success) {
        updateUserProfile(result.user);
        localStorage.setItem("userData", JSON.stringify(result.user));

        onStatusChange(bookId, newStatus);
      } else {
        console.error("Failed to update book status:", result.message);
      }
    } catch (error) {
      console.error("Error updating book status:", error);
    }
  };

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleSaveEditedBook = async (editedBook) => {
    setIsLoading(true);
    try {
      const result = await updateBookMetadata(bookId, editedBook);
      if (result.success) {
        onUpdateUserBooks(result.book);
        onClose();
      } else {
        throw new Error(result.message || "Failed to update book data");
      }
    } catch (error) {
      console.error("Failed to update book data", error);
    } finally {
      setIsEditDialogOpen(false);
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          color="white"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "20px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "700" }}>
            Book Settings
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon
              color="white"
              sx={{ color: "white", fontColor: "white" }}
            />
          </IconButton>
        </DialogTitle>

        <List sx={{ pt: 0 }}>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{ p: "16px" }}
              onClick={() => handleBookClick()}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Open Book Journal"
              />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                p: "16px",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "600", mb: 0.5 }}>
                Select Subcategory:
              </Typography>
              <Select
                value={status}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="tbr_unbought">To Be Read (Not owned)</MenuItem>
                <MenuItem value="tbr_owned">To Be Read (Owned)</MenuItem>
                <MenuItem value="in_progress">Currently Reading</MenuItem>
                <MenuItem value="finished">Finished Reading</MenuItem>
              </Select>
            </Box>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{ p: "16px" }}
              onClick={handleOpenEditDialog}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Edit Book Data"
              />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{
                p: "16px",
                "&:hover": {
                  backgroundColor: "rgba(213, 64, 65, 0.2)",
                },
              }}
              onClick={() => handleRemoveBookClick()}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Remove Book and Book Data From Library"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <EditBookDataDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        book={userBookData}
        onSave={handleSaveEditedBook}
      />
    </>
  );
};

const MarketplaceBookCard = ({
  isMarketplace = false,
  bookProvider,
  bookId,
  bookOwned,
  googleBook,
  onBookUpdate,
  userBookData,
  bookStatus,
  onUpdateUserBooksParent,
  noShadow,
  onStatusChange,
  noBorderCurve,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [book, setBook] = useState({});
  const [userBook, setUserBook] = useState({});
  const [removedBookId, setRemovedBookId] = useState("");
  const { updateUserProfile } = useContext(UserContext);
  const { setIsLoading } = useLoading();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const { canAddBook, getCurrentPlan, getBookLimit } = useSubscription();

  useEffect(() => {
    setBook(googleBook);
  }, [googleBook]);

  useEffect(() => {
    setUserBook(userBookData);
  }, [userBookData]);

  const handleOpenEditDialog = (e) => {
    e.preventDefault();
    if (book && Object.keys(book).length > 0) {
      if (canAddBook()) {
        setIsEditDialogOpen(true);
      } else {
        setIsUpgradeDialogOpen(true);
      }
    } else {
      console.error("Book data is not available");
    }
  };

  const handleCloseUpgradeDialog = () => {
    setIsUpgradeDialogOpen(false);
  };

  const handleUpgrade = () => {
    navigate("/pricing");
  };

  const handleGoToLibrary = () => {
    navigate("/library");
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // const handleUserBooksChange = (removedIdValue) => {
  //   setRemovedBookId(removedIdValue);
  // };
  const handleSaveEditedBook = async (editedBook) => {
    setIsLoading(true);
    try {
      let result;
      if (bookProvider === "google") {
        result = await postGoogleBookToDBAndUser(editedBook);
      } else {
        result = await addBookToUserLibrary(editedBook._id, {
          title: editedBook.title,
          authors: editedBook.authors,
          pages: editedBook.pages,
          genre: editedBook.genre,
          description: editedBook.description,
        });
      }

      if (!result.success) {
        console.error(result.message);
      } else {
        updateUserProfile(result.user);
        localStorage.setItem("userData", JSON.stringify(result.user));

        const newBookData = {
          _id: result.savedBook?._id || editedBook._id,
          id: result.savedBook?.id || editedBook.id,
          title: result.savedBook?.title || editedBook.title,
          authors: result.savedBook?.authors || editedBook.authors,
          pages: result.savedBook?.pages || editedBook.pages,
          genre: result.savedBook?.genre || editedBook.genre,
          description: result.savedBook?.description || editedBook.description,
          cover: result.savedBook?.cover || editedBook.cover,
        };

        if (onBookUpdate) {
          onBookUpdate(newBookData);
        }

        handleCloseEditDialog();

        // Navigate to the library page and force a reload
        navigate("/library", { replace: true });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error saving edited book:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // can use When book db is working
  // const handleBookClick = (e) => {
  //   e.preventDefault();
  //   isMarketplace
  //     ? navigate(`/marketplace/` + bookId)
  //     : navigate(`/library/session/` + bookId);
  // };
  const handleBookClick = (e) => {
    e.preventDefault();
    navigate(`/library/session/` + bookId);
  };

  const defaultCover = "/assets/book-sample.jpg";

  const maxGenres = 3;
  const remainingGenres = book.genre?.length - maxGenres;
  const remainingGenresList = book.genre?.slice(maxGenres).join(", ");

  return (
    <Grid item xs={12} md={isNonMobileScreens ? 6 : 12}>
      <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <CardContentPadding
          sx={{
            p: "24px",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { sm: "row" },
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: { xs: "114px", sm: "140px" },
                height: { xs: "171px", sm: "220px" },
                alignSelf: "center",
              }}
            >
              <img
                src={
                  userBook?.cover?.large ||
                  userBook?.cover?.medium ||
                  userBook?.cover?.small ||
                  book?.cover?.large ||
                  book?.cover?.medium ||
                  book?.cover?.small ||
                  defaultCover
                }
                alt={`Cover for ${book?.title || "Book Title"}`}
                style={{
                  width: "100%",
                  height: "100%",
                  //   objectFit: "cover",
                  //   borderRadius: noBorderCurve ? "0%" : "6%",
                }}
              />
            </Box>

            <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Tooltip title={book.title} arrow>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {book.title}
                      </Typography>
                    </Tooltip>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                      <Tooltip
                        title={`By ${
                          book.authors && book.authors.length > 0
                            ? book.authors.join(", ")
                            : "Unknown Author"
                        }`}
                        arrow
                      >
                        <Typography variant="subtitle2" sx={{ mb: 1 }}>
                          {`By ${
                            book.authors && book.authors.length > 0
                              ? book.authors.join(",  ")
                              : "Unknown Author"
                          }`}
                          <Typography noWrap variant="caption" sx={{ ml: 0.5 }}>
                            · {book.pages} Pages
                          </Typography>
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                  {book.genre && (
                    <Box
                      fullWidth
                      sx={{
                        display: "flex",
                        mb: 1,
                        gap: 0.5,
                        flexWrap: "wrap",
                      }}
                    >
                      {book.genre.slice(0, maxGenres).map((tag) => (
                        <Chip
                          key={tag}
                          label={tag}
                          size="small"
                          color="default"
                          sx={{ height: 20, fontSize: "0.7rem" }}
                        />
                      ))}
                      {remainingGenres > 0 && (
                        <Tooltip title={remainingGenresList}>
                          <Chip
                            label={`+${remainingGenres} more`}
                            size="small"
                            color="default"
                            sx={{
                              height: 20,
                              fontSize: "0.7rem",
                              cursor: "pointer",
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  )}
                  <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                    <Tooltip title={book.description} placement="top">
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "13px",
                          flexGrow: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: { xs: 2, sm: 3 },
                          maxWidth: "100%", // Ensures the element's width is constrained
                          maxHeight: "4.5em", // Assuming 1.5em per line for 3 lines
                          whiteSpace: "normal", // Prevents text from staying on one line
                        }}
                      >
                        {book.description}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: 1,
                    mt: { xs: 2 },
                  }}
                >
                  {book.bookOwned ? (
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      disabled
                    >
                      Book Owned
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="primary"
                      variant="outlined"
                      onClick={handleOpenEditDialog}
                    >
                      Move to Library
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </CardContentPadding>
      </Card>

      <BookSettingDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        bookId={bookId}
        onUpdateUserBooks={onUpdateUserBooksParent}
        userBookData={userBookData || []}
        bookStatus={bookStatus}
        onStatusChange={onStatusChange}
      />

      <UpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={() => setIsUpgradeDialogOpen(false)}
        title="Upgrade to Add More Books"
        currentPlan={getCurrentPlan()}
        limit={getBookLimit()}
        featureName="books"
      />
      <EditBookDataDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        book={book || {}}
        onSave={handleSaveEditedBook}
      />
    </Grid>
  );
};

export default MarketplaceBookCard;
