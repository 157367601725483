import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const adminLogin = async (password) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/admin/login`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ password }),
    });
    return response.data;
  } catch (error) {
    console.error("Admin Login Error:", error);
    throw error;
  }
};

export const forceLogoutAllUsers = async () => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/admin/trigger-maintenance-logout`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Force Logout Error:", error);
    throw error;
  }
};

export const getUsers = async () => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/admin/users`,
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Get Users Error:", error);
    throw error;
  }
};

export const giveLifetimeAccess = async (userId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/admin/give-lifetime-access`,
      method: "PATCH",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ userId }),
    });
    return response.data;
  } catch (error) {
    console.error("Give Lifetime Access Error:", error);
    throw error;
  }
};
