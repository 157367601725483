import React from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookIcon from "@mui/icons-material/Book";
import GroupIcon from "@mui/icons-material/Group";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const guideContent = [
  {
    title: "How to Start Tracking Books and Journaling",
    content: (
      <>
        <Typography paragraph>
          Tracking your reading progress and journaling your thoughts is easy
          with ReadersGuild. Follow these steps:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="1. Open 'marketplace' tab, search for the book, click the 'move to library' button, and click 'save' right away if you don't want to do any edits." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText primary="2. You will be directed to the library, and you can start the reading session by clicking on the book." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText primary="3. Update your progress by entering the number of pages read" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText primary="4. Add journal entries (optional). Share your thoughts, favorite quotes, or reflections. Then click the 'save log' button" />
          </ListItem>
        </List>
        <Typography>
          Remember, consistency is key. Try to update your progress regularly to
          get the most out of the tracking features.
        </Typography>
      </>
    ),
  },
  {
    title: "Creating and Managing Guilds",
    content: (
      <>
        <Typography paragraph>
          Guilds are a great way to connect with other readers. Here's how to
          create and manage your guild:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="1. click the 'Guilds' tab and click 'Create New Guild'/'Create Guild' button." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="2. Name your guild, setup your guild through filling the form, and select the initial book that you want to read for the guild." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="3. To invite members, you give your invitees the room tag number beside the name of your guild on top of the screen; it looks like this for example: #93156. Then your the invitees can click the 'Join Guild' button, and fill in the room tag number (and password if you set a password for the room)." />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="4. Manage your guild by changing books and starting discussion." />
          </ListItem>
        </List>
        <Typography>
          Guilds are more fun with active members, so encourage participation
          and regular check-ins!
        </Typography>
      </>
    ),
  },
  {
    title: "Building a Reading Habit",
    content: (
      <>
        <Typography paragraph>
          Developing a consistent reading habit is key to enjoying more books.
          Here are some tips:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText
              primary="1. Set a daily reading goal"
              secondary="Start small, even 10-15 minutes a day can make a big difference."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText
              primary="2. Choose books you're genuinely interested in"
              secondary="If you're planning to start a reading habit, the reading itself should be enjoyable, not a chore."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText
              primary="3. Track your progress"
              secondary="Use the app's tracking features (analytics, and streak) to see your improvement over time."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText
              primary="4. Be consistent"
              secondary="Try to schedule your reading session each day, or even better if you can read at the same time each day to form a habit."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primary="5. Join a guild or create a guild with friends"
              secondary="Social motivation and being accountable to someone can help you stay on track."
            />
          </ListItem>
        </List>
        <Typography paragraph>
          Remember, it's better to read a little each day than to try reading
          for hours and then skip days. Consistency is key to building a lasting
          habit.
        </Typography>
        <Typography>
          Use the app's charts and progress tracking to visualize your reading
          habit. Seeing your progress can be a great motivator to keep going!
        </Typography>
      </>
    ),
  },
  {
    title: "Using ReadersGuild Features to Level-Up Your Reading",
    content: (
      <>
        <Typography paragraph>
          ReadersGuild offers several features to enhance your reading
          experience:
        </Typography>
        <List>
          <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText
              primary="1. Progress Tracking"
              secondary="Update your progress after each reading session to see how you're doing over time."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
            <ListItemText
              primary="2. Reading Journals"
              secondary="Use the notes feature to jot down thoughts, questions, or favorite quotes as you read. You will memorize the book better, and easily skim through the notes (instead of the whole book) to remind yourself from the keypoints that you got from the book."
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText
              primary="3. Guild Discussions"
              secondary="Engage in book discussions with your guild members to gain new perspectives and memorize about the book better."
            />
          </ListItem>
          {/* <ListItem>
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
            <ListItemText
              primary="4. Reading Challenges"
              secondary="Join or create reading challenges to push yourself and discover new books."
            />
          </ListItem> */}
          <ListItem>
            <ListItemIcon>
              <AccessTimeIcon />
            </ListItemIcon>
            <ListItemText
              primary="4. Reading Streak"
              secondary="Try to maintain a daily reading streak for extra motivation."
            />
          </ListItem>
        </List>
        <Typography>
          Explore these features and find what works best for you. Remember, the
          goal is to enjoy your reading while growing as a reader!
        </Typography>
      </>
    ),
  },
];

const Guides = () => {
  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        ReadersGuild Guides
      </Typography>
      {guideContent.map((guide, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{guide.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>{guide.content}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default Guides;
