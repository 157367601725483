import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  useTheme,
  alpha,
} from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useNavigate } from "react-router-dom";

const PremiumSubscriptionWidget = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Card
      sx={{
        background: "linear-gradient(#188460, #2BA581, #62C99F)",
        color: theme.palette.primary.contrastText,
      }}
    >
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <AutoAwesomeIcon sx={{ fontSize: 40 }} />
          <Typography variant="h5" component="div" align="center">
            Upgrade to Premium
          </Typography>
          <Typography variant="body1" align="center">
            Unlock unlimited book tracking, advanced analytics, and more!
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => window.open("/pricing", "_blank")}
            sx={{
              color: "white",
              background: theme.palette.primary.darkMain,
              backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
              boxShadow: `inset 0 1px ${alpha(
                theme.palette.primary.mediumMain,
                0.4
              )}`,
              outline: `1px solid ${theme.palette.primary.lighterExtraDark}`,
              "&:hover": {
                background: theme.palette.primary.main,
                backgroundImage: "none",
                boxShadow: `0 0 0 1px  ${alpha(
                  theme.palette.primary.mediumMain,
                  0.5
                )}`,
              },
            }}
          >
            Get Premium Now
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PremiumSubscriptionWidget;
