import { useContext } from "react";
import { UserContext } from "../context/UserContext";

export const useSubscription = () => {
  const { userProfile } = useContext(UserContext);

  const hasLifetimeAccess = userProfile.lifetimeAccess;
  const isFreePlan = userProfile.currentPlan === "free";
  const isPremiumOrAbove =
    userProfile.currentPlan === "premium" || userProfile.currentPlan === "pro";
  const bookLimit = isFreePlan ? 10 : Infinity;
  const guildLimit = isFreePlan ? 2 : Infinity;

  const canAccessPremiumFeature = () => hasLifetimeAccess || isPremiumOrAbove;
  const canAccessProFeature = () =>
    hasLifetimeAccess || userProfile.currentPlan === "pro";

  return {
    isPremiumSubscriber: () => canAccessPremiumFeature(),
    canAddBook: () =>
      canAccessPremiumFeature() || userProfile.userAmtOfBooks < bookLimit,
    getCurrentPlan: () =>
      hasLifetimeAccess ? "premium" : userProfile.currentPlan,
    canJoinUnlimitedGroups: () => canAccessPremiumFeature(),
    canCreateOrJoinGroup: () =>
      canAccessPremiumFeature() ||
      (userProfile.rooms?.length || 0) < guildLimit,
    canCreateLargeGuild: () => canAccessProFeature(),
    canCreateMediumGuild: () => canAccessPremiumFeature(),
    isSubscriptionActive: () =>
      hasLifetimeAccess || userProfile.subscriptionStatus === "active",
    getBookLimit: () => (canAccessPremiumFeature() ? Infinity : bookLimit),
    getGuildLimit: () => (canAccessPremiumFeature() ? Infinity : guildLimit),
    getRemainingBooks: () =>
      canAccessPremiumFeature()
        ? Infinity
        : Math.max(0, bookLimit - userProfile.userAmtOfBooks),
    getRemainingGuilds: () =>
      canAccessPremiumFeature()
        ? Infinity
        : Math.max(0, guildLimit - (userProfile.rooms?.length || 0)),
    hasLifetimeAccess: () => hasLifetimeAccess,
  };
};
