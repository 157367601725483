import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { getBlogPostBySlug, deleteBlogPost } from "../../api/blogApi";
import parse from "html-react-parser";
import dayjs from "dayjs";
import DOMPurify from "dompurify";
import {
  Box,
  Container,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Divider,
  Chip,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import LandingPageWrapper from "../LandingPage/LandingPageWrapper";
import { UserContext } from "../../context/UserContext";

// Link Preview Component
const LinkPreviewComponent = ({ url, title, description, image }) => (
  <div className="link-preview">
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="link-preview-content"
    >
      <div className="link-preview-text">
        <h4 className="link-preview-title">{title}</h4>
        <p className="link-preview-description">{description}</p>
        <span className="link-preview-domain">{new URL(url).hostname}</span>
      </div>
      {image && (
        <div className="link-preview-image-container">
          <img src={image} alt={title} className="link-preview-image" />
        </div>
      )}
    </a>
  </div>
);

const BlogPost = ({ isAuthenticated }) => {
  const [post, setPost] = useState(null);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const navigate = useNavigate();
  const { userProfile } = useContext(UserContext);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const data = await getBlogPostBySlug(slug);
        setPost(data);
      } catch (err) {
        setError("Failed to fetch blog post. Please try again later.");
      }
    };
    fetchPost();
  }, [slug]);

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this blog post?")) {
      try {
        await deleteBlogPost(post._id);
        navigate("/blog");
      } catch (err) {
        setError("Failed to delete blog post. Please try again.");
      }
    }
  };

  const renderContent = (content) => {
    const cleanHtml = DOMPurify.sanitize(content);
    return parse(cleanHtml, {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "link-preview") {
          const {
            "data-url": url,
            "data-title": title,
            "data-description": description,
            "data-image": image,
          } = domNode.attribs;
          return (
            <LinkPreviewComponent
              url={url}
              title={title}
              description={description}
              image={image}
            />
          );
        }
      },
    });
  };

  if (error)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );

  if (!post)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );

  const canEdit =
    isAuthenticated &&
    userProfile &&
    (userProfile.isAdmin || userProfile._id === post.author._id);

  return (
    // Box is supposed to be LandingPageWrapper
    <LandingPageWrapper>
      <Helmet>
        <title>{post.title} | ReadersGuild Blog</title>
        <meta name="description" content={post.excerpt} />
        <meta property="og:title" content={post.title} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:image" content={post.featuredImage} />
        <meta
          property="og:url"
          content={`https://www.readers-guild.com/blog/${post.slug}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <link
          rel="canonical"
          href={`https://www.readers-guild.com/blog/${post.slug}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            headline: post.title,
            image: post.featuredImage,
            author: {
              "@type": "Person",
              name: post.author.name,
            },
            datePublished: post.publishDate,
            dateModified: post.lastModified,
            mainEntityOfPage: {
              "@type": "WebPage",
              "@id": `https://www.readers-guild.com/blog/${post.slug}`,
            },
            publisher: {
              "@type": "Organization",
              name: "ReadersGuild",
              logo: {
                "@type": "ImageObject",
                url: "https://www.readers-guild.com/logo.png",
              },
            },
          })}
        </script>
        <style>{`
          .blog-content {
            line-height: 2;
          }
          .blog-content p {
            margin-bottom: 0.5em;
          }
          .link-preview {
          border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin: 10px 0;
          }
          .link-preview-content {
           display: flex;
  text-decoration: none;
  color: inherit;
          }
          .link-preview-text {
         flex: 1;
  padding: 12px;
  overflow: hidden;
          }
          .link-preview-title {
           font-weight: bold;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
          }
          .link-preview-description {
           font-size: 0.9em;
  color: #636363;
  margin: 0 0 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.6em;
  max-height: 3.6em;
          }
          .link-preview-domain {
              font-size: 0.8em;
  color: #757575;
          }
          .link-preview-image-container {
             width: 120px;
  height: 120px;
  flex-shrink: 0;
          }
          .link-preview-image {
           width: 100%;
  height: 100%;
  object-fit: cover;
          }
        `}</style>
      </Helmet>
      {/*           backgroundImage: "url('/assets/library-background.webp')",
       */}

      <Container maxWidth="md">
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "80vh", // Adjust this value as needed
            marginBottom: theme.spacing(4),
            overflow: "hidden", // Ensures the image doesn't spill out
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: "url('/assets/library-background.webp')",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.4)", // Adjust the last value (0.4) to control the darkness of the tint
              },
            }}
          />
          <Box
            sx={{
              position: "relative",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              zIndex: 1,
              padding: theme.spacing(2),
              gap: 6,
            }}
          >
            <Typography
              variant="h5"
              sx={{ fontWeight: 700, textAlign: "center" }}
            >
              BLOG
            </Typography>
            <Typography
              variant="h1"
              sx={{ textAlign: "center", marginBottom: theme.spacing(2) }}
            >
              {post.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                By{" "}
                {post.isGuestAuthor ? post.guestAuthorName : post.author.name}{" "}
              </Typography>
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                {dayjs(post.publishDate).format("dddd, MMMM D, YYYY")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Card sx={{ mt: 4, mb: 4, px: 3, pt: 1.5, boxShadow: 3 }}>
          {post.featuredImage && (
            <CardMedia
              component="img"
              height="300"
              image={post.featuredImage}
              alt={post.title}
              sx={{ objectFit: "cover" }}
            />
          )}
          <CardContent>
            <Typography
              variant="h2"
              component="h1"
              gutterBottom
              sx={{ fontWeight: 600, color: theme.palette.neutral.dark }}
            >
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              By {post.isGuestAuthor ? post.guestAuthorName : post.author.name}{" "}
              on {dayjs(post.publishDate).format("dddd, MMMM D, YYYY")}
            </Typography>
            {canEdit && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Button
                  component={Link}
                  to={`/blog/edit/${post.slug}`}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  Edit Post
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete Post
                </Button>
              </Box>
            )}
            <Divider sx={{ my: 2 }} />
            <Box
              className="blog-content"
              sx={{
                mt: 2,
                color: theme.palette.neutral.main,
                "& > p": { marginBottom: theme.spacing(1) },
                "& img": {
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                  // margin: "1rem auto",
                },
              }}
            >
              {renderContent(post.content)}
            </Box>
            {post.tags && post.tags.length > 0 && (
              <Box sx={{ mt: 4 }}>
                {post.tags.map((tag, index) => (
                  <Chip key={index} label={tag} sx={{ mr: 1, mb: 1 }} />
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
        <Box
          fullWidth
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "linear-gradient(#188460, #2BA581, #62C99F)",
            color: theme.palette.primary.contrastText,
            padding: theme.spacing(6, 2),
            borderRadius: "8px",
            justifyContent: "center",
            textAlign: "center",
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          }}
        >
          <Box
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "center",
              maxWidth: "600px",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              component="h3"
              gutterBottom
              fontSize="28px"
              fontWeight="600"
            >
              Join ReadersGuild
            </Typography>
            <Typography variant="body1">
              Join our community of book lovers and receive exclusive content,
              reading recommendations, exclusive content and special features.
            </Typography>
          </Box>

          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              size="small"
              // id="email-signup"
              label="Your Email"
              // variant="contained"
              // fullWidth
              sx={{
                p: "0",
                backgroundColor: "white",
                borderRadius: "6px",
                //   "& .MuiOutlinedInput-root": {
                //     "& fieldset": {
                //       borderColor: "transparent",
                //     },
                //     "&:hover fieldset": {
                //       borderColor: "transparent",
                //     },
                //     "&.Mui-focused fieldset": {
                //       borderColor: "transparent",
                //     },
                //   },
              }}
            />
            <Button
              variant="contained"
              size="large"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.primary.contrastText,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box>
      </Container>
    </LandingPageWrapper>
  );
};

export default BlogPost;
