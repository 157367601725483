import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Switch,
  FormControlLabel,
  Select,
  MenuItem,
  Typography,
  Avatar,
  Box,
  Backdrop,
  Paper,
  Link,
  IconButton,
} from "@mui/material";
import Dropzone from "react-dropzone";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import EditBookDataDialog from "../../components/EditBookDataDialog";
import PostDiscussionDialog from "./PostDiscussionDialog";
import DialogEditNote from "../../components/DialogEditNote";
import JoinRoomDialog from "./JoinRoomDialog";
import BookSearchCard from "../../components/BookSearchCard";
import { useSubscription } from "../../hooks/useSubscription";
import {
  addBookToUserLibrary,
  postGoogleBookToDBAndUser,
} from "../../api/userApi";
import FlexBetween from "../../components/FlexBetween";
import CustomQuillEditor from "../../components/CustomQuillEditor";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const RoundedSquareAvatar = styled(Avatar)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: theme.shape.borderRadius * 1,
}));

const RoomDialogs = ({
  dialogStates,
  setDialogStates,
  onCreateRoom,
  onJoinRoom,
  onRemoveRoom,
  onPostDiscussion,
  onEditDiscussion,
  onRemoveDiscussion,
  onRemoveMember,
  currentRoom,
  userProfile,
  isNonMobileScreens,
  rooms,
  onOpenUpgradeDialog,
}) => {
  const [roomName, setRoomName] = useState("");
  const [isPublic, setIsPublic] = useState(false);
  const [roomPassword, setRoomPassword] = useState("");
  const [selectedBook, setSelectedBook] = useState(null);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [isEditBookDialogOpen, setIsEditBookDialogOpen] = useState(false);
  const [showBookSearch, setShowBookSearch] = useState(false);
  const [guildDescription, setGuildDescription] = useState("");
  const [guildAbout, setGuildAbout] = useState("");
  const {
    canCreateOrJoinGroup,
    getCurrentPlan,
    canCreateLargeGuild,
    canCreateMediumGuild,
  } = useSubscription();
  const [guildImage, setGuildImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [showBackdrop, setShowBackdrop] = useState(rooms.length === 0);
  const [resetBookSearch, setResetBookSearch] = useState(false);
  const [showImageUpload, setShowImageUpload] = useState(false);

  const getMaxMembers = () => {
    if (canCreateLargeGuild()) {
      return { size: 250, type: "large" };
    } else if (canCreateMediumGuild()) {
      return { size: 50, type: "medium" };
    } else {
      return { size: 8, type: "small" };
    }
  };

  const quareAvatar = styled(Avatar)(({ theme }) => ({
    width: 64,
    height: 64,
    borderRadius: theme.shape.borderRadius * 1,
  }));

  const handleImageUpload = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      setGuildImage(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const { size: maxMembers, type: guildType } = getMaxMembers();

  useEffect(() => {
    setShowBackdrop(rooms.length === 0);
  }, [rooms.length]);

  useEffect(() => {
    if (resetBookSearch) {
      setResetBookSearch(false);
    }
  }, [resetBookSearch]);

  const handleClose = (dialogName) => {
    setDialogStates((prev) => ({
      ...prev,
      [dialogName]: false,
    }));
    if (dialogName === "backdrop") {
      setShowBackdrop(false);
    }
    // Reset form fields
    setRoomName("");
    setIsPublic(false);
    setRoomPassword("");
    setSelectedBook(null);
    setSelectedBookId(null);
    setShowBookSearch(false);
    setGuildDescription("");
    setGuildAbout("");
    setGuildImage(null);
    setImagePreview(null);
    setResetBookSearch(true);
  };

  const handleCreateRoomSubmit = () => {
    if (!canCreateOrJoinGroup()) {
      onOpenUpgradeDialog();
      return;
    }
    onCreateRoom({
      name: roomName,
      isPublic,
      roomPassword: isPublic ? undefined : roomPassword,
      maxMembers,
      currentBook: selectedBookId || undefined,
      description: guildDescription || undefined,
      about: guildAbout || undefined,
      profileImage: guildImage || undefined,
    });
    handleClose("createRoom");
    setShowBookSearch(false);
    setResetBookSearch(true);
    setSelectedBook(null);
    setSelectedBookId(null);
    setGuildDescription("");
    setGuildAbout("");
  };

  const handleSaveEditedBook = async (editedBook) => {
    try {
      let result;
      if (!editedBook._id) {
        // Book is from Google Books API and not in our DB yet
        result = await postGoogleBookToDBAndUser(editedBook);
      } else {
        // Book is already in our DB
        result = await addBookToUserLibrary(editedBook._id, {
          title: editedBook.title,
          authors: editedBook.authors,
          pages: editedBook.pages,
          genre: editedBook.genre,
          description: editedBook.description,
        });
      }

      if (result.success) {
        setSelectedBook(result.book);
        setSelectedBookId(result.book._id);
        setIsEditBookDialogOpen(false);
      } else {
        throw new Error(result.message || "Failed to save book");
      }
    } catch (error) {
      console.error("Error saving edited book:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleUpgradeClick = (event) => {
    event.preventDefault();
    window.open("/pricing", "_blank");
  };

  const handleJoinRoomSubmit = (roomTag, roomPassword) => {
    if (!canCreateOrJoinGroup()) {
      onOpenUpgradeDialog();
      return;
    }
    onJoinRoom(roomTag, roomPassword);
    handleClose("joinRoom");
  };

  const handleBookSelect = (book) => {
    setSelectedBook(book);
    setSelectedBookId(book._id);
    setIsEditBookDialogOpen(true);
  };

  const maxMemberOptions =
    getCurrentPlan() === "free"
      ? [5, 6, 7, 8]
      : getCurrentPlan() === "premium"
      ? [10, 20, 30, 40, 50]
      : [50, 100, 150, 200, 250];

  return (
    <>
      {/* Create Room Dialog */}
      <Dialog
        open={dialogStates.createRoom}
        onClose={() => handleClose("createRoom")}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Create a New Guild</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Guild Name"
            type="text"
            fullWidth
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
          />

          {/* Guild Profile Image Upload */}
          <Box sx={{ mt: 2, mb: 2 }}>
            {!showImageUpload && !imagePreview ? (
              <Button
                startIcon={<AddIcon />}
                onClick={() => setShowImageUpload(true)}
              >
                Add Guild Profile Image
              </Button>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Dropzone
                  accept={{
                    "image/*": [".jpeg", ".png", ".jpg"],
                  }}
                  multiple={false}
                  onDrop={handleImageUpload}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Box
                      {...getRootProps()}
                      sx={{
                        width: 64,
                        height: 64,
                        borderRadius: (theme) =>
                          `${theme.shape.borderRadius}px`,
                        border: (theme) =>
                          `2px dashed ${theme.palette.divider}`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <input {...getInputProps()} />
                      {imagePreview ? (
                        <RoundedSquareAvatar
                          src={imagePreview}
                          variant="square"
                          sx={{
                            width: "100%",
                            height: "100%",
                            borderRadius: (theme) =>
                              `${theme.shape.borderRadius}px`,
                          }}
                        />
                      ) : (
                        <AddPhotoAlternateIcon sx={{ fontSize: 24 }} />
                      )}
                      <Box
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          backgroundColor: "rgba(0,0,0,0.3)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          opacity: 0,
                          transition: "opacity 0.3s",
                          "&:hover": { opacity: 1 },
                        }}
                      >
                        <EditOutlinedIcon
                          sx={{ color: "white", fontSize: 20 }}
                        />
                      </Box>
                    </Box>
                  )}
                </Dropzone>
                {imagePreview && (
                  <Typography variant="body2">
                    Guild Profile Image Added
                  </Typography>
                )}
              </Box>
            )}
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={isPublic}
                onChange={(e) => setIsPublic(e.target.checked)}
              />
            }
            label="Public Guild"
          />
          {!isPublic && (
            <TextField
              margin="dense"
              label="Guild Password"
              type="password"
              fullWidth
              value={roomPassword}
              onChange={(e) => setRoomPassword(e.target.value)}
            />
          )}
          <Box sx={{ mt: 2, mb: 6 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Guild Description
            </Typography>
            <CustomQuillEditor
              value={guildDescription}
              onChange={setGuildDescription}
              placeholder="Enter guild description..."
            />
          </Box>
          <Box sx={{ mt: { xs: 16, md: 8 }, mb: { xs: 16, md: 6 } }}>
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              About the Guild
            </Typography>
            <CustomQuillEditor
              value={guildAbout}
              onChange={setGuildAbout}
              placeholder="Enter information about the guild..."
            />
          </Box>

          <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
            Maximum Members: {maxMembers}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {guildType === "large" &&
              "You can create large guilds with up to 250 members."}
            {guildType === "medium" && (
              <>
                You can create medium guilds with up to 50 members.{" "}
                {getCurrentPlan() !== "pro" && (
                  <Link href="#" onClick={handleUpgradeClick}>
                    Upgrade to Pro for larger guilds.
                  </Link>
                )}
              </>
            )}
            {guildType === "small" && (
              <>
                {getCurrentPlan() === "free" ? (
                  <>
                    <Link href="#" onClick={handleUpgradeClick}>
                      Upgrade
                    </Link>{" "}
                    to Premium or Pro to have more members.
                  </>
                ) : (
                  <Link href="#" onClick={handleUpgradeClick}>
                    Upgrade to Pro for large guilds
                  </Link>
                )}
              </>
            )}
          </Typography>
          {!showBookSearch && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setShowBookSearch(true)}
              sx={{ mt: 2 }}
            >
              Add Book Now
            </Button>
          )}
          {showBookSearch && (
            <>
              <BookSearchCard
                onBookSelect={handleBookSelect}
                resetSearch={resetBookSearch}
              />
              {selectedBook && (
                <Typography color="success.main" sx={{ mt: 2 }}>
                  Selected book: {selectedBook.title}
                </Typography>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("createRoom")}>Cancel</Button>
          <Button onClick={handleCreateRoomSubmit} disabled={!roomName}>
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Join Room Dialog */}
      <JoinRoomDialog
        open={dialogStates.joinRoom}
        onClose={() => handleClose("joinRoom")}
        onJoin={handleJoinRoomSubmit}
      />

      {/* Remove Room Dialog */}
      <Dialog
        open={dialogStates.removeRoom}
        onClose={() => handleClose("removeRoom")}
      >
        <DialogTitle>Are you sure you want to remove this room?</DialogTitle>
        <DialogContent>
          <Typography>
            This action cannot be undone. All data associated with this room
            will be permanently deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("removeRoom")}>Cancel</Button>
          <Button onClick={onRemoveRoom} color="error">
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* ssion Dialog */}
      <PostDiscussionDialog
        open={dialogStates.postDiscussion}
        onClose={() => handleClose("postDiscussion")}
        onSubmit={onPostDiscussion}
        lastPageRead={
          currentRoom.memberProgress?.find(
            (progress) => progress.user._id === userProfile._id
          )?.lastPageRead || 0
        }
        bookTotalPages={currentRoom.currentBook?.pages || 0}
      />

      {/* Edit Discussion Dialog */}
      <DialogEditNote
        open={dialogStates.editDiscussion}
        onClose={() => handleClose("editDiscussion")}
        onSave={onEditDiscussion}
        initialNote={dialogStates.currentDiscussion?.readingNotes || ""}
        bookTitle={currentRoom.currentBook?.title || ""}
        logDate={dialogStates.currentDiscussion?.postDate || ""}
        logId={dialogStates.currentDiscussion?._id || ""}
      />

      {/* Remove Discussion Dialog */}
      <Dialog
        open={dialogStates.removeDiscussion}
        onClose={() => handleClose("removeDiscussion")}
      >
        <DialogTitle>
          Are you sure you want to remove this discussion?
        </DialogTitle>
        <DialogContent>
          <Typography>
            This action cannot be undone. The discussion will be permanently
            deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("removeDiscussion")}>
            Cancel
          </Button>
          <Button
            onClick={() => onRemoveDiscussion(dialogStates.currentDiscussion)}
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove Member Dialog */}
      <Dialog
        open={dialogStates.removeMember}
        onClose={() => handleClose("removeMember")}
      >
        <DialogTitle>Are you sure you want to remove this member?</DialogTitle>
        <DialogContent>
          <Typography>
            This action will remove the member from the room. They will no
            longer have access to this room's content.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("removeMember")}>Cancel</Button>
          <Button
            onClick={() => onRemoveMember(dialogStates.memberToRemove)}
            color="error"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Book Dialog */}
      <EditBookDataDialog
        open={dialogStates.editBook}
        onClose={() => handleClose("editBook")}
        book={dialogStates.selectedBook || {}}
        onSave={(editedBook) => {
          // Handle saving edited book
          handleClose("editBook");
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <Paper sx={{ padding: 3, maxWidth: 400 }}>
          <FlexBetween>
            <Typography variant="h6" gutterBottom>
              Ready to dive into book chat?
            </Typography>
            <IconButton
              onClick={() => handleClose("backdrop")}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </FlexBetween>
          <Typography paragraph>
            Create your own reading squad or join an existing one. It's time to
            share your bookish thoughts!
          </Typography>
          <Button
            onClick={() =>
              setDialogStates((prev) => ({ ...prev, createRoom: true }))
            }
            variant="contained"
            sx={{ mr: 2 }}
          >
            Start a Guild
          </Button>
          <Button
            onClick={() =>
              setDialogStates((prev) => ({ ...prev, joinRoom: true }))
            }
            variant="outlined"
          >
            Find a Guild
          </Button>
        </Paper>
      </Backdrop>
      {/* Edit Book Dialog */}
      <EditBookDataDialog
        open={isEditBookDialogOpen}
        onClose={() => setIsEditBookDialogOpen(false)}
        book={selectedBook || {}}
        onSave={handleSaveEditedBook}
      />
    </>
  );
};

export default RoomDialogs;
