import { useState, React } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "../../context/SnackbarContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PricingCard = ({
  planName,
  price,
  periodic,
  features,
  userEmail,
  link,
  duration,
  buttonLabel,
  bordered,
  disabled,
  onCancelSubscription,
}) => {
  const theme = useTheme();
  const snackbar = useSnackbar();
  const handleSubscribe = () => {
    const subscriptionLink = `${link}?prefilled_email=${encodeURIComponent(
      userEmail
    )}`;
    window.open(subscriptionLink, "_blank", "noopener,noreferrer");
  };

  const handleCancelSubscription = async () => {
    try {
      const result = await onCancelSubscription();
      const message = `Your subscription has been successfully downgraded to the Free plan. Please note that your premium feature is still accessible until the end of your billing cycle (${result}). Thank you for using our service!`;
      snackbar(message); // Show the message from the response
    } catch (error) {
      console.error(error); // Show a generic error message
    }
  };
  return (
    <>
      <Card
        sx={{
          border: bordered ? 1 : 0,
          borderColor: theme.palette.neutral.darkMain,
          width: 330,
          margin: "auto",
          mt: 5,
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: theme.palette.neutral.mediumMain,
            }}
          >
            {planName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              mb: !periodic ? 4.5 : 0,
            }}
          >
            {/* renewed */}
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {!isNaN(price) && "$"}
              {price}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ pt: 1.5, color: theme.palette.neutral.main }}
            >
              {duration}
            </Typography>
          </Box>
          <Typography variant="subtitle2" color="text.secondary">
            {periodic}
          </Typography>
          <Box mt={2}>
            {features.map((feature, index) => (
              <Box display="flex" alignItems="center" mb={1} key={index}>
                <CheckCircleIcon sx={{ color: theme.palette.neutral.main }} />
                <Typography
                  variant="subtitle1"
                  ml={1}
                  sx={{
                    fontWeight: 600,
                    color: theme.palette.neutral.darkMain,
                  }}
                >
                  {feature}
                </Typography>
              </Box>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 3 }}
            onClick={
              String(price).toLowerCase() === "free"
                ? handleCancelSubscription
                : handleSubscribe
            }
            disabled={disabled}
          >
            {buttonLabel}
          </Button>
        </CardContent>
      </Card>
      {/* find user email
      target blank is to open a new window */}
      {/* <a
        target="_blank"
        href={
          plan.link + "?prefilled_email=" + "{replace with user email here}"
        }
        rel="noreferrer"
      >
        Subscribe
      </a> */}
    </>
  );
};

export default PricingCard;
