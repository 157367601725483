import React from "react";
import { Box, Typography, Divider } from "@mui/material";

const RoomStatistics = ({ currentRoom }) => {
  // Use the cumulative statistics from the room data
  const totalPagesRead = currentRoom.totalPagesRead || 0;
  const totalDiscussions = currentRoom.totalDiscussions || 0;
  const totalBooksRead = currentRoom.totalBooksRead || 0;

  const daysActive = currentRoom.createdAt
    ? Math.max(
        1,
        Math.floor(
          (new Date() - new Date(currentRoom.createdAt)) / (1000 * 60 * 60 * 24)
        ) + 1
      )
    : 0;

  const averageReadingSpeed = currentRoom.members?.length
    ? (totalPagesRead / currentRoom.members.length).toFixed(2)
    : 0;

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Guild Statistics
      </Typography>
      <Divider sx={{ mb: 2 }} />
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2">Total Pages Read:</Typography>
        <Typography variant="body2" fontWeight="bold">
          {totalPagesRead}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2">Total Discussions:</Typography>
        <Typography variant="body2" fontWeight="bold">
          {totalDiscussions}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2">Total Books Read:</Typography>
        <Typography variant="body2" fontWeight="bold">
          {totalBooksRead}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2">Avg. Pages per Member:</Typography>
        <Typography variant="body2" fontWeight="bold">
          {averageReadingSpeed}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2">Days Active:</Typography>
        <Typography variant="body2" fontWeight="bold">
          {daysActive}
        </Typography>
      </Box>
    </Box>
  );
};

export default RoomStatistics;
