import React, { useState, useCallback } from "react";
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommentIcon from "@mui/icons-material/Comment";
import parse from "html-react-parser";
import DialogEditNote from "../../components/DialogEditNote";
import FlexBetween from "../../components/FlexBetween";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CommentSection from "../../components/CommentSection";

const defaultCover = "/assets/book-sample.webp";

const CommunityCard = ({
  log,
  currentUserId,
  onDelete,
  onUpdateNote,
  isMobileScreen,
  isSmallScreen,
  onLike,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState(log.readingNotes || "");
  const isSmallerScreen = useMediaQuery("(max-width:500px)");
  const [showFullNotes, setShowFullNotes] = useState(false);
  const [localLikes, setLocalLikes] = useState(log.likes || []);
  const [showComments, setShowComments] = useState(false);
  const [commentCount, setCommentCount] = useState(log.comments?.length || 0);

  const isLiked = localLikes.includes(currentUserId);
  const open = Boolean(anchorEl);

  const handleLike = useCallback(() => {
    // Existing like functionality
    setLocalLikes((prevLikes) =>
      isLiked
        ? prevLikes.filter((id) => id !== currentUserId)
        : [...prevLikes, currentUserId]
    );

    onLike(log._id).catch(() => {
      setLocalLikes((prevLikes) =>
        isLiked
          ? [...prevLikes, currentUserId]
          : prevLikes.filter((id) => id !== currentUserId)
      );
    });
  }, [log._id, currentUserId, isLiked, onLike]);

  // Existing functions remain unchanged
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteLog = async (logId) => {
    try {
      await onDelete(logId);
      handleClose();
    } catch (error) {
      console.error("Error removing reading log:", error);
      handleClose();
    }
  };

  const editNote = async (editedNote, privateSwitch) => {
    try {
      await onUpdateNote(log._id, editedNote, privateSwitch);
      setCurrentNotes(editedNote);
      handleClose();
    } catch (error) {
      console.error("Error updating post:", error);
      handleClose();
    }
  };

  const truncateNotes = (notes) => {
    const maxLength = 330;
    if (notes.length <= maxLength) return notes;
    return notes.slice(0, maxLength) + "...";
  };

  return (
    <Card>
      <CardContentDoublePadding
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 2,
          gap: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "background.paper",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "nowrap",
            }}
          >
            <Avatar
              src={log.user?.avatar}
              alt={log.user?.name || "Anonymous User"}
              sx={{ fontSize: "15px", width: 40, height: 40, mr: 2 }}
            />
            <Typography
              variant="body1"
              fontWeight="bold"
              sx={{ fontSize: "15px", mr: 1 }}
            >
              {log.user?.name || "Anonymous User"}
            </Typography>
            {!isSmallerScreen && (
              <>
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: theme.palette.neutral.medium,
                    mr: 1,
                  }}
                >
                  •
                </Typography>

                <Typography
                  sx={{ fontSize: "15px", color: theme.palette.neutral.medium }}
                >
                  {new Date(log.postDate).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </Typography>
              </>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {currentUserId === log.user?._id && (
              <IconButton
                onClick={handleClick}
                sx={{ pt: isSmallerScreen ? 0 : "8px" }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1.5,
            px: isSmallScreen ? 0.5 : 0,
          }}
        >
          <Tooltip
            title={`From "${log.book?.title || "Untitled Book"}" by "${
              log.book?.authors?.join(", ") || "Unknown Author"
            }"`}
            arrow
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
                //   fontSize: "15px",
                color: theme.palette.neutral.medium,
                maxWidth: { xs: "100%" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              From "{log.book?.title || "Untitled Book"}" by "
              {log.book?.authors?.join(", ") || "Unknown Author"}"
            </Typography>
          </Tooltip>
          {!isSmallerScreen && (
            <>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "15px",
                  color: theme.palette.neutral.medium,
                }}
              >
                •
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "500",
                  //   fontSize: "15px",
                  color: theme.palette.neutral.medium,
                  maxWidth: { xs: "100%" },
                }}
              >
                Page{" "}
                {log.lastPageRead - log.startPageRead > 0
                  ? log.startPageRead + " - " + log.lastPageRead
                  : log.lastPageRead}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {!isSmallScreen && (
            <CardMedia
              component="img"
              sx={{
                width: 100,
                height: 150,
                objectFit: "cover",
                mr: 2,
                mb: 0,
              }}
              image={
                log.book?.cover?.large ||
                log.book?.cover?.medium ||
                log.book?.cover?.small ||
                log.book?.cover ||
                defaultCover
              }
              alt={log.book?.title || "Book cover"}
            />
          )}
          <Box sx={{ flex: 1, p: 0 }}>
            <Box
              sx={{
                backgroundColor: theme.palette.background.default,
                borderRadius: 1,
                py: 1,
                px: 2,
                position: "relative",
              }}
            >
              {isSmallScreen && (
                <FlexBetween>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "start",
                      fontSize: 13,
                      color: theme.palette.neutral.mediumLight,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    Page{" "}
                    {log.lastPageRead - log.startPageRead > 0
                      ? log.startPageRead + " - " + log.lastPageRead
                      : log.lastPageRead}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: "start",
                      fontSize: 13,
                      color: theme.palette.neutral.mediumLight,
                      fontWeight: 500,
                    }}
                    gutterBottom
                  >
                    {new Date(log.postDate).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                </FlexBetween>
              )}

              {log.readingNotes ? (
                <>
                  <Typography
                    variant="body2"
                    sx={{ "& p": { marginBottom: "6px", marginTop: "6px" } }}
                  >
                    <span>
                      {showFullNotes
                        ? parse(currentNotes)
                        : parse(truncateNotes(currentNotes))}

                      {/* Conditional rendering of the "More" button */}
                      {log.readingNotes.length > 200 && !showFullNotes && (
                        <Typography
                          component="span"
                          variant="subtitle1"
                          sx={{
                            color: theme.palette.primary.main,
                            cursor: "pointer",
                            "&:hover": { textDecoration: "underline" },
                            display: "inline",
                          }}
                          onClick={() => setShowFullNotes(true)}
                        >
                          more
                        </Typography>
                      )}
                    </span>
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  No notes available.
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box>
              <IconButton
                onClick={handleLike}
                color={isLiked ? "primary" : "default"}
              >
                {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
              {localLikes.length > 0 && (
                <Typography variant="subtitle2" component="span">
                  {localLikes.length}
                </Typography>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton
                color={showComments ? "primary" : "default"}
                onClick={() => setShowComments(!showComments)}
              >
                <CommentIcon />
              </IconButton>
              <Typography variant="subtitle2" component="span">
                {commentCount}
              </Typography>
            </Box>
          </Box>
        </Box>
        {showComments && (
          <CommentSection
            discussionId={log._id}
            currentUserId={currentUserId}
            onCommentCountChange={setCommentCount}
          />
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <MenuItem onClick={() => setIsEditDialogOpen(true)}>
            <EditIcon fontSize="small" sx={{ mr: 1 }} />
            Edit
          </MenuItem>
          <MenuItem onClick={() => deleteLog(log._id)}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
            Remove
          </MenuItem>
        </Menu>
        <DialogEditNote
          open={isEditDialogOpen}
          onClose={() => setIsEditDialogOpen(false)}
          onSave={editNote}
          switchData={log.privateSwitch}
          initialNote={log.readingNotes}
          bookTitle={log.book?.title}
          logId={log._id}
        />
      </CardContentDoublePadding>
    </Card>
  );
};

export default CommunityCard;
