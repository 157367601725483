import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Paper,
  Tooltip,
  Divider,
  Stack,
  Link,
  Snackbar,
} from "@mui/material";
import { getBiWeeklyBestSellerAPI } from "../../api/bookApi";
import { postGoogleBookToDBAndUser } from "../../api/userApi";
import EditBookDataDialog from "../../components/EditBookDataDialog";
import { UserContext } from "../../context/UserContext";
import { useLoading } from "../../context/LoadingContext";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../hooks/useSubscription";
import UpgradeDialog from "../../components/UpgradeDialog";
import FlexBetween from "../../components/FlexBetween";

const CACHE_KEY = "bestsellerBooksLocalCache";
const CACHE_EXPIRY = 2 * 24 * 60 * 60 * 1000; // 2 days in milliseconds

const BestSellerBooks = () => {
  const [bestsellerBooks, setBestsellerBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { updateUserProfile } = useContext(UserContext);
  const { setIsLoading: setGlobalLoading } = useLoading();
  const navigate = useNavigate();
  const { canAddBook, getCurrentPlan, getBookLimit } = useSubscription();

  useEffect(() => {
    fetchBestsellerBooks();
  }, []);

  const fetchBestsellerBooks = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const localCacheData = getLocalCacheData();
      if (localCacheData) {
        setBestsellerBooks(localCacheData);
        setIsLoading(false);
        return;
      }

      const response = await getBiWeeklyBestSellerAPI();
      if (response && response.data) {
        const books = Array.isArray(response.data)
          ? response.data
          : response.data.data;
        setBestsellerBooks(books);
        cacheData(books);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (error) {
      console.error("Error fetching bestseller books:", error);
      setError(`Failed to load bestseller books: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const getLocalCacheData = () => {
    const cachedItem = localStorage.getItem(CACHE_KEY);
    if (!cachedItem) return null;

    const { data, expiry } = JSON.parse(cachedItem);
    if (Date.now() > expiry) {
      localStorage.removeItem(CACHE_KEY);
      return null;
    }

    return data;
  };

  const cacheData = (data) => {
    const cacheItem = {
      data,
      expiry: Date.now() + CACHE_EXPIRY,
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(cacheItem));
  };

  const handleOpenEditDialog = (book) => {
    if (canAddBook()) {
      setSelectedBook(book);
      setIsEditDialogOpen(true);
    } else {
      setIsUpgradeDialogOpen(true);
    }
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
    setSelectedBook(null);
  };

  const handleCloseUpgradeDialog = () => {
    setIsUpgradeDialogOpen(false);
  };

  const handleSaveEditedBook = async (editedBook) => {
    setGlobalLoading(true);
    try {
      const result = await postGoogleBookToDBAndUser(editedBook);
      if (!result.success) {
        throw new Error(result.message || "Failed to add book to library");
      }
      updateUserProfile(result.user);
      localStorage.setItem("userData", JSON.stringify(result.user));
      setSnackbar({
        open: true,
        message: "Book added to your library successfully",
      });
      handleCloseEditDialog();
      navigate("/library", { replace: true });
      window.location.reload();
    } catch (error) {
      console.error("Error adding book to library:", error);
      setSnackbar({
        open: true,
        message: "Failed to add book to library. Please try again.",
      });
    } finally {
      setGlobalLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="200px"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (bestsellerBooks.length === 0) {
    return (
      <Typography>No bestseller books available at the moment.</Typography>
    );
  }

  return (
    <Box sx={{ px: isMobile ? 1 : 1, mt: 1 }}>
      {!isMobile ? (
        <>
          {" "}
          <FlexBetween sx={{ pb: 3 }}>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                variant={"h4"}
                sx={{
                  lineHeight: "1.0",
                  fontWeight: "bold",
                  fontSize: "28px",
                  fontFamily: "Georgia, serif",
                }}
              >
                Bi-Weekly Popular Picks
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontStyle: "italic",
                  color: theme.palette.neutral.medium,
                }}
              >
                Updated bi-weekly
              </Typography>
            </Box>
            <Link
              href="https://developer.nytimes.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://developer.nytimes.com/files/poweredby_nytimes_200a.png?v=1583354208344"
                alt="Data provided by The New York Times"
                style={{ width: isMobile ? "150px" : "200px" }}
              />
            </Link>
          </FlexBetween>
        </>
      ) : (
        <>
          <Box sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  lineHeight: "1.0",
                  fontWeight: "bold",
                  fontSize: "24px",
                  fontFamily: "Georgia, serif",
                }}
              >
                Bi-Weekly Popular Picks
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontStyle: "italic",
                  color: theme.palette.neutral.medium,
                }}
              >
                Updated bi-weekly
              </Typography>
            </Box>
          </Box>
        </>
      )}

      <Grid container spacing={isMobile ? 2 : 3} sx={{ mb: 3 }}>
        {bestsellerBooks.map((book, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={book.isbn13 || index}>
            <Paper
              elevation={3}
              sx={{
                p: 2,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box sx={{ display: "flex", alignItems: "flex-start", mb: 2 }}>
                  <Typography
                    variant={isMobile ? "h4" : "h3"}
                    sx={{
                      fontWeight: "bold",
                      mr: 2,
                      color: theme.palette.primary.main,
                    }}
                  >
                    {index + 1}
                  </Typography>
                  <Box>
                    <Tooltip title={book.title}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: "bold",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitLineClamp: 1,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {book.title}
                      </Typography>
                    </Tooltip>
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      by {book.authors.join(", ")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <img
                    src={
                      book.cover?.large ||
                      book.cover?.medium ||
                      book.cover?.small ||
                      "/path/to/default/cover.jpg"
                    }
                    alt={`Cover of ${book.title}`}
                    style={{
                      height: "200px",
                      width: "132px",
                    }}
                  />
                </Box>
                <Tooltip title={book.description} placement="top">
                  <Typography
                    variant="body2"
                    sx={{
                      mb: 2,
                      flexGrow: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 3,
                      maxWidth: "100%", // Ensures the element's width is constrained
                      maxHeight: "4.5em", // Assuming 1.5em per line for 3 lines
                      whiteSpace: "normal", // Prevents text from staying on one line
                    }}
                  >
                    {book.description}
                  </Typography>
                </Tooltip>
              </Box>
              <Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ mt: 2, mb: 1 }}>
                  {/* <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    Weeks on the list: {book?.nytWeeksOnList || "N/A"}
                  </Typography> */}
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    spacing={1}
                    justifyContent="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      fullWidth={isMobile}
                      onClick={() => handleOpenEditDialog(book)}
                    >
                      Move to Library
                    </Button>
                    {book?.amazonProductUrl && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        fullWidth={isMobile}
                        href={book.amazonProductUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Buy at Amazon
                      </Button>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {/* NYT Attribution */}
      {isMobile && (
        <Box sx={{ mt: 4, textAlign: "center" }}>
          <Link
            href="https://developer.nytimes.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://developer.nytimes.com/files/poweredby_nytimes_200a.png?v=1583354208344"
              alt="Data provided by The New York Times"
              style={{ width: isMobile ? "150px" : "200px" }}
            />
          </Link>
          {/* <Typography variant="caption" display="block" sx={{ mt: 1 }}>
          Data provided by The New York Times
        </Typography> */}
        </Box>
      )}
      <EditBookDataDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        book={selectedBook || {}}
        onSave={handleSaveEditedBook}
      />
      <UpgradeDialog
        open={isUpgradeDialogOpen}
        onClose={handleCloseUpgradeDialog}
        title="Upgrade to Add More Books"
        currentPlan={getCurrentPlan()}
        limit={getBookLimit()}
        featureName="books"
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default BestSellerBooks;
