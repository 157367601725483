import React from "react";
import {
  Card,
  CardContent,
  Box,
  Avatar,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CardContentDoublePadding from "../../../components/CardContentDoublePadding";
import parse from "html-react-parser";

const RoundedSquareAvatar = styled(Avatar)(({ theme }) => ({
  width: 64,
  height: 64,
  borderRadius: theme.shape.borderRadius * 1,
}));

const ExtraSlimAccordion = styled(Accordion)(({ theme }) => ({
  "&.MuiAccordion-root": {
    margin: 0,
    "&:before": {
      display: "none",
    },
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const ExtraSlimAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  minHeight: "unset",
  "&.MuiAccordionSummary-root": {
    padding: theme.spacing(0, 1),
  },
  "& .MuiAccordionSummary-content": {
    margin: theme.spacing(0.5, 0),
  },
}));

const ExtraSlimAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const GuildAccordionHeading = styled(Typography)(({ theme }) => ({
  fontWeight: "600",
}));

const GuildProfile = ({ room }) => {
  const totalPagesRead = room.totalPagesRead || 0;
  const totalDiscussions = room.totalDiscussions || 0;
  const totalBooksRead = room.totalBooksRead || 0;
  const daysActive = room.createdAt
    ? Math.max(
        1,
        Math.floor(
          (new Date() - new Date(room.createdAt)) / (1000 * 60 * 60 * 24)
        ) + 1
      )
    : 0;
  const averageReadingSpeed = room.members?.length
    ? (totalPagesRead / room.members.length).toFixed(2)
    : 0;

  return (
    <Card>
      <CardContentDoublePadding>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <RoundedSquareAvatar alt={room.name} src={room.profileImage} />
          <Typography variant="h6" sx={{ ml: 2, fontWeight: "bold" }}>
            {room.name}
          </Typography>
        </Box>
        {room?.description && (
          <ExtraSlimAccordion>
            <ExtraSlimAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="description-content"
              id="description-header"
            >
              <GuildAccordionHeading variant="body3">
                Description
              </GuildAccordionHeading>
            </ExtraSlimAccordionSummary>
            <ExtraSlimAccordionDetails>
              <Box color="text.secondary">{parse(room.description)}</Box>
            </ExtraSlimAccordionDetails>
          </ExtraSlimAccordion>
        )}
        {room?.about && (
          <ExtraSlimAccordion>
            <ExtraSlimAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="about-content"
              id="about-header"
            >
              <GuildAccordionHeading variant="body3">
                About
              </GuildAccordionHeading>
            </ExtraSlimAccordionSummary>
            <ExtraSlimAccordionDetails>
              <Box color="text.secondary">{parse(room.about)}</Box>
            </ExtraSlimAccordionDetails>
          </ExtraSlimAccordion>
        )}
        <ExtraSlimAccordion>
          <ExtraSlimAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="statistics-content"
            id="statistics-header"
          >
            <GuildAccordionHeading variant="body3">
              Guild Statistics
            </GuildAccordionHeading>
          </ExtraSlimAccordionSummary>
          <ExtraSlimAccordionDetails>
            <Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2">Total Pages Read:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {totalPagesRead}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2">Total Discussions:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {totalDiscussions}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2">Total Books Read:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {totalBooksRead}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2">Avg. Pages per Member:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {averageReadingSpeed}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
              >
                <Typography variant="body2">Days Active:</Typography>
                <Typography variant="body2" fontWeight="bold">
                  {daysActive}
                </Typography>
              </Box>
            </Box>
          </ExtraSlimAccordionDetails>
        </ExtraSlimAccordion>
      </CardContentDoublePadding>
    </Card>
  );
};

export default GuildProfile;
