import { Box } from "@mui/material";
import { styled } from "@mui/system";

const ContentGrid = styled(Box)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "repeat(4,1fr)",
  gridTemplateRows: "auto",
});

export default ContentGrid;
