import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Chip,
  IconButton,
} from "@mui/material";
import LinearProgressBar from "../../components/LinearProgressBar";
import CloseIcon from "@mui/icons-material/Close";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";

const MembersCard = ({
  members,
  creator,
  memberProgress,
  isRoomCreator,
  onRemoveMember,
}) => {
  return (
    <Card>
      <CardContentDoublePadding>
        <Typography variant="body1" sx={{ fontWeight: "700", mb: 2 }}>
          Members
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
          {members?.map((member, index) => (
            <Box
              key={member._id || index}
              sx={{ display: "flex", flexDirection: "row", gap: 1 }}
            >
              <Avatar
                src={member.avatar || undefined}
                alt={member.name || undefined}
                sx={{ width: 50, height: 50 }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body3" sx={{ fontWeight: "600" }}>
                    {member.name}
                  </Typography>
                  {member._id === creator?._id && (
                    <Chip
                      label="Guild Master"
                      size="small"
                      color="default"
                      sx={{ height: 20, fontSize: "0.7rem" }}
                    />
                  )}
                  {isRoomCreator && member._id !== creator?._id && (
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => onRemoveMember(member)}
                      aria-label="remove member"
                      sx={{ p: 0 }}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 1,
                  }}
                >
                  <Typography variant="body2">Progress:</Typography>
                  <LinearProgressBar
                    percentage={
                      memberProgress?.find(
                        (progress) => progress.user._id === member._id
                      )?.progress || 0
                    }
                  />
                  <Typography variant="body2" sx={{ fontWeight: "600" }}>
                    {Math.round(
                      memberProgress?.find(
                        (progress) => progress.user._id === member._id
                      )?.progress || 0
                    )}
                    %
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </CardContentDoublePadding>
    </Card>
  );
};

export default MembersCard;
