import { Box } from "@mui/material";
import React from "react";
import ProfileWidget from "../../pages/widgets/ProfileWidget";
import GenreChartWidget from "../../pages/widgets/GenreChartWidget";
import StreakCard from "../../pages/widgets/StreakCard";
const LeftProfile = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <ProfileWidget />
      <StreakCard />
      <GenreChartWidget />
    </Box>
  );
};

export default LeftProfile;
