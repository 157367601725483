import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Button,
  useTheme,
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { magicLinkLogin, signInWithGoogle } from "../../../api/authApi";
import { useSocket } from "../../../context/SocketContext";
import { useAuth } from "../../../context/AuthContext";
import Cookies from "js-cookie";
import { getAuth } from "firebase/auth";
import { getUserProfileData } from "../../../api/userApi";
import { UserContext } from "../../../context/UserContext";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isWaitingForVerification, setIsWaitingForVerification] =
    useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const { updateUserProfile } = useContext(UserContext);

  const theme = useTheme();
  const navigate = useNavigate();
  const socket = useSocket();
  const { checkLogin } = useAuth();

  // Email validation regex
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const validateEmail = (email) => {
    if (!email) {
      return "Email is required";
    }
    if (!emailRegex.test(email)) {
      return "Please enter a valid email address";
    }
    return "";
  };
  useEffect(() => {
    if (socket && isWaitingForVerification) {
      const handleUserLoggedIn = ({ userId, token }) => {
        Cookies.set("x-auth-cookie", token, { expires: 90 });
        // checkLogin();
        navigate("/home");
      };

      const handleVerificationResponse = (response) => {
        if (response.mode === "registration") {
          setIsVerified(true);
          setSuccessMessage(
            "Email verified successfully! You can now proceed with the onboarding process in the new tab."
          );
          setIsWaitingForVerification(false);
        } else if (response.mode === "login") {
          handleUserLoggedIn({
            userId: response.userId,
            token: response.token,
          });
        }
      };

      socket.on("userLoggedIn", handleUserLoggedIn);
      socket.on("verificationResponse", handleVerificationResponse);

      return () => {
        socket.off("userLoggedIn", handleUserLoggedIn);
        socket.off("verificationResponse", handleVerificationResponse);
      };
    }
  }, [socket, isWaitingForVerification, checkLogin, navigate, rememberMe]);

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (user) => {
  //     if (user) {
  //       console.log("User is signed in:", user);
  //       checkLogin();
  //       navigate("/home");
  //     } else {
  //       console.log("No user is signed in");
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [auth, checkLogin, navigate]);

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    try {
      const result = await signInWithGoogle();
      if (result.data.success) {
        if (result.data.type === "registration") {
          localStorage.setItem(
            "onBoarding",
            JSON.stringify({
              fullName: result.data.name,
              avatar: result.data?.picture,
            })
          );
          navigate(`/verify-registration?token=${result.data.token}`);
        } else {
          navigate(`/verify?token=${result.data.token}`);
        }
      } else {
        setErrorMessage(result.error || "Failed to sign in with Google");
      }
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      setErrorMessage("Failed to sign in with Google. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  // const handleGoogleSignIn = async () => {
  //   setIsLoading(true);
  //   setSuccessMessage("");
  //   setErrorMessage("");
  //   try {
  //     const result = await signInWithGoogle();
  //     if (result.success) {
  //       if (result.newUser) {
  //         navigate("/onboarding", { state: { userData: result.user } });
  //       } else {
  //         axios.defaults.headers.common[
  //           "Authorization"
  //         ] = `Bearer ${result.token}`;
  //         await fetchUserData();
  //         localStorage.setItem("showMessage", "login");
  //         setSuccessMessage("Successfully signed in with Google!");
  //         checkLogin();
  //         navigate("/home");
  //       }
  //     } else {
  //       setErrorMessage(result.error || "Failed to sign in with Google");
  //     }
  //   } catch (error) {
  //     console.error("Google Sign-In Error:", error);
  //     setErrorMessage("Failed to sign in with Google. Please try again.");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const fetchUserData = async () => {
    try {
      const result = await getUserProfileData();
      updateUserProfile(result.data);
      localStorage.setItem("userData", JSON.stringify(result.data));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Handle email input change
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Remove the live validation error message
    if (emailError) {
      setEmailError("");
    }
  };
  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };
  // Handle continue button click and validate email
  const handlePostEmail = async (e) => {
    e.preventDefault();

    const emailValidationError = validateEmail(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }

    // If validation passes, proceed with the email submission process
    setIsLoading(true);
    try {
      const result = await magicLinkLogin(email, rememberMe);
      if (result.data.success) {
        setSuccessMessage(
          "Verification link sent to your email! Check your inbox (and spam folder, just in case)."
        );
        setIsWaitingForVerification(true);
      } else {
        console.error(result.data.message);
        setErrorMessage(
          result.data.message || "An error occurred. Please try again."
        );
      }
    } catch (error) {
      console.error("Login Error:", error);
      setErrorMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isVerified) {
    return (
      <Box sx={{ textAlign: "center", maxWidth: "600px" }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          Email Verified Successfully!
        </Typography>
        <Typography variant="body1">
          Your email has been verified. You can now continue with the onboarding
          process in the new tab that has opened.
        </Typography>
        <Typography
          variant="body2"
          sx={{ mt: 2, color: theme.palette.text.secondary }}
        >
          If you don't see the new tab, please check your browser's pop-up
          settings and try again.
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "600px",
        gap: 1.5,
      }}
    >
      <TextField
        label="Type your email..."
        value={email}
        size="small"
        type="email"
        name="email"
        onChange={handleEmailChange} // Just update the state without showing error
        disabled={isLoading || isWaitingForVerification}
        error={!!emailError} // Only show error when it's not empty
        helperText={emailError} // Display error message here if validation fails
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={handleRememberMeChange}
            color="primary"
            disabled={
              isLoading || isWaitingForVerification || !!emailError || !email
            }
          />
        }
        label="Remember this device"
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handlePostEmail}
        disabled={isLoading || isWaitingForVerification}
      >
        {isLoading
          ? "Sending..."
          : isWaitingForVerification
          ? "Waiting for verification..."
          : "Continue"}
      </Button>
      {errorMessage && (
        <Typography
          color="error"
          variant="body2"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      )}
      <Typography variant="body2" align="center">
        Or
      </Typography>
      <Button
        variant="outlined"
        onClick={handleGoogleSignIn}
        disabled={isLoading || isWaitingForVerification}
        startIcon={
          <img
            src="https://developers.google.com/identity/images/g-logo.png"
            alt="Google"
            width="20"
            height="20"
          />
        }
      >
        {isLoading ? "Signing in..." : "Continue with Google"}
      </Button>

      {/* <Button
        variant="outlined"
        onClick={handleTwitterSignIn}
        disabled={isLoading || isWaitingForVerification}
        startIcon={
          <img
            src="/assets/twitter-icon.png"
            alt="Twitter"
            width="20"
            height="20"
          />
        }
      >
        Continue with Twitter
      </Button> */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "12px", color: theme.palette.neutral.main }}
        >
          {isWaitingForVerification
            ? "Please verify your email to continue."
            : "If you already have an account, we'll log you in"}
        </Typography>
      </Box>

      {(isLoading || isWaitingForVerification) && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {errorMessage && (
        <Typography
          color="error"
          variant="body2"
          sx={{ mt: 2, textAlign: "center" }}
        >
          {errorMessage}
        </Typography>
      )}

      {successMessage && (
        <Box fullWidth>
          <Box
            fullWidth
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                fontWeight: "500",
                color: "neutral",
              }}
            >
              {successMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SignUpForm;
