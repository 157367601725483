import {
  Box,
  Card,
  CardContent,
  Typography,
  Avatar,
  Button,
  Divider,
  Stack,
  IconButton,
} from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import React, { useEffect, useState } from "react";
import DisplayRating from "../../components/DisplayRating";

const BookCommentDisplayWidget = ({ bookReviewData }) => {
  const [bookReview, setBookReview] = useState({});
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    processBookReview();
  }, [bookReviewData]);

  const processBookReview = () => {
    const date = new Date(bookReviewData.createdAt);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    setBookReview({
      name: bookReviewData.author.name,
      rating: bookReviewData.rating,
      comment: bookReviewData.body,
      formattedDate: formattedDate,
      avatar: bookReviewData.author.avatar,
    });
  };

  // const handleLike = () => {
  //   setIsLiked(!isLiked);
  // };
  return (
    <Box>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "grid",
              width: "100%",
              gridTemplateColumns: "repeat(6,1fr)",
              gap: 3,
            }}
          >
            <Box sx={{ display: "inline-block" }}>
              <Stack
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt={bookReview.name}
                  src={bookReview.avatar}
                  sx={{ width: 56, height: 56 }}
                />
                <Typography>{bookReview.name}</Typography>
                {/* <Button variant="contained" size="small">
                  follow
                </Button> */}
              </Stack>
            </Box>
            <Box sx={{ gridColumn: "span 5" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <DisplayRating ratingValue={bookReview.rating} />
                <Typography variant="overline">
                  {bookReview.formattedDate}
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography variant="body1">{bookReview.comment}</Typography>
              </Box>
              {/* <Box sx={{ display: "flex", mt: 3, alignItems: "center" }}>
                <Box sx={{ mr: 0.5 }}>
                  <Typography variant="subtitle2">45 likes n/a</Typography>
                </Box>
                <Box>
                  <IconButton onClick={handleLike}>
                    {isLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                  </IconButton>
                </Box>
              </Box> */}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default BookCommentDisplayWidget;
