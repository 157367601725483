import { useContext } from "react";
import { Box, Card, Typography, useTheme } from "@mui/material";
import CardContentPadding from "../../components/CardContentPadding";
import BoltIcon from "@mui/icons-material/Bolt";
import { UserContext } from "../../context/UserContext";

const StreakCard = () => {
  const theme = useTheme();
  const { userProfile } = useContext(UserContext);
  return (
    <>
      <Card>
        <CardContentPadding>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              //renewed
              variant="h6"
              sx={{
                fontWeight: "300",
                color: theme.palette.neutral.medium,
              }}
            >
              Streak:
            </Typography>{" "}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                //renewed
                variant="h6"
              >
                {userProfile.currentReadingStreak}{" "}
                {userProfile.currentReadingStreak > 1 ? "days" : "day"}
              </Typography>
              <BoltIcon fontSize="large" color="primary" />
            </Box>
          </Box>
        </CardContentPadding>
      </Card>
    </>
  );
};

export default StreakCard;
