import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { inviteFriends } from "../api/userApi";

const InviteFriendsDialog = ({ open, onClose }) => {
  const [emails, setEmails] = useState([""]);

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const handleInvite = async () => {
    const validEmails = emails.filter((email) => email.trim() !== "");
    try {
      const response = await inviteFriends(validEmails);
      if (response.success) {
        onClose();
      } else {
        console.error("Failed to send invitations:", response.message);
      }
    } catch (error) {
      console.error("Error inviting friends:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Invite Friends to ReadersGuild</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Enter email addresses of friends you'd like to invite:
        </Typography>
        {emails.map((email, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", mb: 2 }}
          >
            <TextField
              fullWidth
              variant="outlined"
              value={email}
              onChange={(e) => handleEmailChange(index, e.target.value)}
              placeholder="friend@example.com"
              margin="dense"
            />
            {index > 0 && (
              <IconButton
                onClick={() => handleRemoveEmail(index)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        ))}
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddEmail}
          variant="outlined"
          color="primary"
          sx={{ mt: 1 }}
        >
          Add Another Email
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleInvite} variant="contained" color="primary">
          Send Invitations
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteFriendsDialog;
